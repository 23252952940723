import { create } from 'zustand'
import {Topic} from "../../models/Topic";
import {Channel} from "../../models/Channel";

type State = {
    topics: Topic[]
    loadingTopicIds: string[]
}

type Action = {
    setTopics: (newTopics: State['topics']) => void
    updateTopic: (newTopic: Topic) => void
    addTopicToLoading: (topicId: string) => void
    removeTopicFromLoading: (topicId: string) => void
    addTopicChannels: (topicId: string, channelsCount: number) => void
    deleteTopicChannel: (topicId: string, channelId: string) => void
}

export const useTopicsStore = create<State & Action>((set) => ({
    topics: [],
    loadingTopicIds: [],
    setTopics: (newTopics: Topic[]) => set(() => ({topics: newTopics})),
    updateTopic: (newTopic: Topic) => set((state) => {
        const indexOfUpdatedTopic = state.topics.indexOf(newTopic);
        const filteredTopics = state.topics.filter((el) => el.id != newTopic.id);
        return {topics: [...filteredTopics.slice(0, indexOfUpdatedTopic), newTopic, ...filteredTopics.slice(indexOfUpdatedTopic)]};
    }),
    addTopicToLoading: (topicId: string) => set((state) => {
        return {loadingTopicIds: [...state.loadingTopicIds, topicId]};
    }),
    removeTopicFromLoading: (topicId: string) => set((state) => {
        return {loadingTopicIds: [...state.loadingTopicIds.filter((id) => id !== topicId)]};
    }),
    addTopicChannels: (topicId: string, channelsCount: number) => set((state) => {
        const topic = state.topics.find((el) => el.id === topicId);
        if (!topic) return {topics: [...state.topics]};
        const indexOfUpdatedTopic = state.topics.indexOf(topic);

        if (!topic.channels) {
            topic.channels = [];
        }

        if (channelsCount !== 0) {
            state.addTopicToLoading(topic.id);
        }

        for (let i = 0; i < channelsCount; i++) {
            topic.channels.unshift({} as Channel);
        }

        const filteredTopics = state.topics.filter((el) => el.id != topic.id);
        return {topics: [...filteredTopics.slice(0, indexOfUpdatedTopic), topic, ...filteredTopics.slice(indexOfUpdatedTopic)]};
    }),
    deleteTopicChannel: (topicId: string, channelId: string) => set((state) => {
        const topic = state.topics.find((el) => el.id === topicId);
        if (!topic) return {topics: [...state.topics]};
        topic!.channels = topic!.channels.filter((el) => el.id !== channelId);
        const filteredTopics = state.topics.filter((el) => el.id != topic.id);
        return {topics: [...filteredTopics, topic].sort((a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime())};
    }),
}))