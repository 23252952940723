import React, {useEffect, useRef, useState} from 'react';
import {Channel} from "../models/Channel";
import Card from "../ui/Card";
import {Change} from "../models/Change";
import {channelChanges, topicChanges} from "../http/changes";
import {Icon} from "@tremor/react";
import {ExclamationTriangleIcon, NoSymbolIcon, XCircleIcon} from "@heroicons/react/20/solid";
import ChangeCard from "./ChangeCard";
import {useTranslation} from "react-i18next";
import {Topic} from "../models/Topic";
import Status from "../ui/Status";

type ChangesProps = {
    channel?: Channel
    topic?: Topic
    shorts: boolean
}

function Changes({channel, topic, shorts}: ChangesProps) {
    const {t} = useTranslation();

    const shouldFetch = useRef(true);
    const observerTarget = useRef(null);

    const [end, setEnd] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState<Change[]>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchData = async (page: number) => {
        let results;

        if (channel) {
            results  = await channelChanges(channel.id, page, shorts);
        } else if (topic) {
            results  = await topicChanges(topic.id, page, shorts);
        }

        if (!results) {
            setLoading(false);
            setError(true);
            return;
        }

        if (results.data.length < 15) {
            setEnd(true);
        }

        if (page === 1) {
            setChanges(results.data);
        } else {
            setChanges([...changes, ...results.data]);
        }

        setLoading(false);
        setCurrentPage(page + 1);
    };

    useEffect(() => {
        setLoading(true);
        fetchData(1).catch((e) => {
            setLoading(false);
            setError(true);
        });
    }, [channel, topic]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && !end) {
                    setLoading(true);
                    fetchData(currentPage).catch((e) => {
                        setLoading(false);
                        setError(true);
                    });
                }
            },
            { threshold: 1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, end, currentPage]);

    return (
        <Card>
            <div
                style={(!changes && !loading || error)
                    ? {height: "100%", display: "flex", justifyContent: "center", flexDirection: "column"} : {}
                }>
                {(!changes || !changes.length) && !loading && !error &&
                    <Status
                        icon={<Icon icon={NoSymbolIcon} color="slate" size="xl"></Icon>}
                        headline={t("video_page.no_changes")}
                        text={channel ? t("channel_page.no_changes_for_this_channel") : t("topic_page.no_changes_for_this_topic")}
                    />
                }
                {error && <Status
                    icon={<Icon icon={XCircleIcon} color="red" size="xl"></Icon>}
                    headline={t("video_page.something_went_wrong")}
                    text={t("video_page.something_went_wrong_text")}
                />}
            </div>
            <>
                {(changes && changes.length != 0) && <div className={"overflow-y-scroll h-60 changes"}>
                    {changes?.map((el, i) => {
                        return <ChangeCard key={i} change={el} />
                    })}
                    <div ref={observerTarget} className={"py-4 loadable " + (loading ? "loadable_active": "")}></div>
                </div>}
            </>
        </Card>
    );
}

export default Changes;