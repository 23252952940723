import React, {useMemo} from 'react';
import {Channel} from "../../../models/Channel";
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../utils/numbers";
import {Callout, Icon} from "@tremor/react";
import {ArchiveBoxXMarkIcon, ExclamationCircleIcon, NoSymbolIcon} from "@heroicons/react/20/solid";

type ChannelInfoProps = {
    channel: Channel
};

function ChannelInfo({channel}: ChannelInfoProps) {
    const {t} = useTranslation();

    const banner = useMemo(() => {
        if (channel.deleted || channel.blocked) {
            return <Callout
                title={t("channel_page." + (channel.deleted ? "channel_deleted" : "channel_blocked"))}
                icon={ExclamationCircleIcon}
                color="red"
                className="mb-8"
            >
                {t("channel_page." + (channel.deleted ? "channel_deleted_text" : "channel_blocked_text"))}
            </Callout>;
        }

        if (!!channel.banner) {
            return <img src={channel.banner} alt="banner" className="block rounded-md mb-8 w-full"/>;
        }

        return <></>;
    }, [channel]);

    const avatar =  useMemo(() => {
        if (channel.deleted || channel.blocked) {
            return <>
                <div className="w-32 h-32 rounded-full mr-8 bg-red-100 flex justify-center items-center">
                    <Icon icon={channel.blocked ? NoSymbolIcon : ArchiveBoxXMarkIcon} color="red" className="text-red-700" size="xl" />
                </div>
            </>;
        }

        if (!!channel.avatar) {
            return <img src={channel.avatar} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/person.svg";
            }}  alt="avatar" className="block w-32 shrink-0 rounded-full mr-8"/>;
        }

        return <></>;
    }, [channel]);

    return (
        <WideCard>
            <>
                {banner}
            </>
            <Card>
                <div className="flex items-center">
                    {avatar}
                    <div>
                        <div className="font-bold mb-2 text-base">{channel.title}</div>
                        <div className="text-slate-400 mb-1">{t("channel_page.start_of_observation")}: {new Date(channel.created_at).toLocaleDateString()}</div>
                        <div className="text-slate-400 mb-1">{t("channel_page.videos_observing", {count: channel.current_videos})}</div>
                        <div className="text-slate-400 mb-1">{formatNumber(channel.subscriptions)} {t("channel_page.subscribers")}</div>
                        <a className="text-blue-500" href={"https://www.youtube.com/channel/" + channel.youtube_id} target="_blank">{t("channel_page.go_to_youtube")}</a>
                    </div>
                </div>
            </Card>
        </WideCard>
    );
}

export default ChannelInfo;