import React, {useState} from 'react';
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {User} from "../../../models/User";
import {stringToDateString} from "../../../utils/date";
import {Button} from "@tremor/react";
import Modal from "../../../ui/Modal";
import ChangePassword from "./ChangePassword";

function AccountSettings() {
    const {t} = useTranslation();
    const [modalShown, setModalShown] = useState(false);

    if (!localStorage.getItem("user")) {
        return <></>;
    }

    const user = JSON.parse(localStorage.getItem("user")!) as User;

    return (
        <WideCard className="mb-8">
            <div className="text-lg font-bold text-center mb-8">{t("settings_page.account.heading")}</div>
            <Card>
                <div className="grid grid-cols-2">
                    <div className="">
                        <div className="font-bold mb-2">{t("settings_page.account.regdata")}</div>
                        <div className="text-sm text-slate-500 mb-2">{t("settings_page.account.id")}: {user.id}</div>
                        <div className="text-sm text-slate-500 mb-2">{t("settings_page.account.email")}: {user.email}</div>
                        <div className="text-sm text-slate-500">{t("settings_page.account.registration_date")}: {stringToDateString(user.created_at!)}</div>
                    </div>
                    <div className="flex items-center justify-center">
                        <Button onClick={() => {setModalShown(true);}}>{t("settings_page.change_password")}</Button>
                    </div>
                </div>
            </Card>
            <Modal shown={modalShown} widthClass="sm:max-w-2xl" close={() => {setModalShown(false);}}>
                <ChangePassword/>
            </Modal>
        </WideCard>
    );
}

export default AccountSettings;