import React, {useMemo, useState} from 'react';
import Card from "../ui/Card";
import WideCard from "../ui/WideCard";
import {useTranslation} from "react-i18next";
import {useShortsStore} from "../store/shorts";
import {Tab, TabGroup, TabList} from "@tremor/react";

function ToggleShorts() {
    const {t} = useTranslation();

    const {shorts, setShorts} = useShortsStore();

    const [tooltipShown, setTooltipShown] = useState(false);

    // const canToggle = useMemo(() => {
    //     const storageUser = JSON.parse(localStorage.getItem("user")!) as User;
    //     const storagePlan = JSON.parse(localStorage.getItem("plan")!) as Plan;
    //
    //     if (storageUser && storageUser.role > 0) return true;
    //     if (storagePlan) {
    //         return storagePlan.channel_count == 50 || storagePlan.channel_count == 100;
    //     }
    //
    //     return false;
    // }, []);

    const canToggle = true;

    function handleIndexChange(value: number) {
        if (value == 0) {
            setShorts(false);
        } else {
            setShorts(true);
        }
    }

    return (
        <WideCard>
            <Card className="overflow-visible">
                <TabGroup
                    color="zinc"
                    index={shorts ? 1 : 0}
                    defaultIndex={shorts ? 1 : 0}
                    onIndexChange={handleIndexChange}
                    className="relative overflow-visible"
                    style={{overflow: "visible"}}
                >
                    <TabList variant="solid" style={{overflow: "visible"}}>
                        <Tab>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.94 122.88" style={{width: 16, height: 16, marginRight: 4}}>
                                    <svg viewBox="0 0 28.57 20" preserveAspectRatio="xMidYMid meet">
                                        <g>
                                            <path d="M27.9727 3.12324C27.6435 1.89323 26.6768 0.926623 25.4468 0.597366C23.2197 2.24288e-07 14.285 0 14.285 0C14.285 0 5.35042 2.24288e-07 3.12323 0.597366C1.89323 0.926623 0.926623 1.89323 0.597366 3.12324C2.24288e-07 5.35042 0 10 0 10C0 10 2.24288e-07 14.6496 0.597366 16.8768C0.926623 18.1068 1.89323 19.0734 3.12323 19.4026C5.35042 20 14.285 20 14.285 20C14.285 20 23.2197 20 25.4468 19.4026C26.6768 19.0734 27.6435 18.1068 27.9727 16.8768C28.5701 14.6496 28.5701 10 28.5701 10C28.5701 10 28.5677 5.35042 27.9727 3.12324Z" fill="#FF0000"/>
                                            <path d="M11.4253 14.2854L18.8477 10.0004L11.4253 5.71533V14.2854Z" fill="white"/>
                                        </g>
                                    </svg>
                                </svg>
                                {t('topic_page.long_videos')}
                            </div>
                        </Tab>
                        <Tab
                            disabled={!canToggle}
                            className="relative"
                            style={{overflow: "visible"}}
                        >
                            {!canToggle && <div
                                className={"absolute border border-slate-200 text-xs whitespace-normal drop-shadow-md text-center transition-all px-4 w-96 py-1 text-sm rounded-md bg-slate-100 -translate-x-1/2 " + (tooltipShown ? "" : "opacity-0 invisible")}
                                style={{left: "50%", bottom: "130%"}}
                            >
                                {t("topic_page.shorts_tooltip")}
                            </div>}
                            <div
                                className="flex items-center"
                                onMouseLeave={() => {setTooltipShown(false);}}
                                onMouseOver={() => {setTooltipShown(true)}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.94 122.88"
                                     style={{width: 12, height: 12, marginRight: 4}}>
                                    <path
                                        d="M63.49 2.71c11.59-6.04 25.94-1.64 32.04 9.83 6.1 11.47 1.65 25.66-9.94 31.7l-9.53 5.01c8.21.3 16.04 4.81 20.14 12.52 6.1 11.47 1.66 25.66-9.94 31.7l-50.82 26.7c-11.59 6.04-25.94 1.64-32.04-9.83-6.1-11.47-1.65-25.66 9.94-31.7l9.53-5.01c-8.21-.3-16.04-4.81-20.14-12.52-6.1-11.47-1.65-25.66 9.94-31.7l50.82-26.7zM36.06 42.53l30.76 18.99-30.76 18.9V42.53z" fill="#f40407"/>
                                    <path d="M36.06,42.53 V 80.42 L 66.82,61.52Z" fill="#fff"/>
                                </svg>
                                {t('topic_page.shorts')}
                            </div>
                        </Tab>
                    </TabList>
                </TabGroup>
            </Card>
        </WideCard>
    );
}

export default ToggleShorts;