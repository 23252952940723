import React, {useState} from 'react';
import Modal from "../../../ui/Modal";
import {Topic} from "../../../models/Topic";
import {Link} from "react-router-dom";
import {Icon} from "@tremor/react";
import {TrashIcon} from "@heroicons/react/20/solid";
import {deleteChannelFromTopic} from "../../topic/http";
import {useTranslation} from "react-i18next";
import Card from "../../../ui/Card";
import {Channel} from "../../../models/Channel";
import DeleteChannel from "../../topic/components/DeleteChannel";

type DeleteTopicChannelsProps = {
    shown: boolean
    close: Function
    topic: Topic
    setTopic: Function
    topics: Topic[]
    setTopics: Function
}

function DeleteTopicChannels({shown, close, topic}: DeleteTopicChannelsProps) {
    const {t} = useTranslation();
    const [modalShown, setModalShown] = useState(false);
    const [channelToDelete, setChannelToDelete] = useState<Channel>();
    function handleModalClose() {
        setModalShown(false);
        setChannelToDelete(undefined);
    }


    return (
        <Modal shown={shown} close={close} widthClass="sm:max-w-6xl" zIndex={10010}>
            <div className="text-center text-lg font-bold mb-8 text-black">{t("subscription_page.delete_channels", {topic: topic.title, count: !!topic.channels ? topic.channels.length : 0})}</div>
            <Card>
                <>
                    {(topic?.channels && topic?.channels.length !== 0) &&
                        <div className="mt-8 flex flex-wrap gap-y-12 gap-x-5">
                            {topic?.channels!.map((channel, i) => {
                                if (!channel.title) {
                                    return <></>;
                                }

                                return <div key={i} className="block w-60 shrink-0 flex flex-col items-center text-center cursor-pointer">
                                    <div className="rounded-full w-32 relative" onClick={async (event) => {
                                        event.preventDefault();
                                        setChannelToDelete(channel);
                                        setModalShown(true);
                                    }}>
                                        <div className="absolute bg w-full h-full rounded-full bg-gray-950/50 flex items-center justify-center opacity-0 transition-all hover:opacity-100">
                                            <Icon icon={TrashIcon} size="xl" className="text-white" />
                                        </div>
                                        <img src={channel.avatar} alt="avatar" className="block w-32 rounded-full"/>
                                    </div>
                                    <div className="text-sm mt-2 text-slate-700">{channel.title}</div>
                                </div>;
                            })}
                        </div>
                    }
                </>
                <Modal shown={modalShown} close={handleModalClose} zIndex={10011} widthClass="sm:max-w-4xl">
                    <div className="text-center text-lg font-bold mb-8">{channelToDelete?.title}: {t("main_page.delete_channel")}</div>
                    {
                        channelToDelete ? <DeleteChannel channel={channelToDelete} topic={topic!} close={handleModalClose} /> : <></>
                    }
                </Modal>
            </Card>
        </Modal>
    );
}

export default DeleteTopicChannels;