import React, {useMemo, useState} from 'react';
import {ChannelVideosRating} from "../models/ChannelVideosRating";
import SnapshotCard from "./SnapshotCard";
import {formatNumber} from "../utils/numbers";
import Card from "../ui/Card";
import {useTranslation} from "react-i18next";
import VideosTable from "./VideosTable";
import Modal from "../ui/Modal";
import {Icon} from "@tremor/react";
import {ClockIcon, LockClosedIcon} from "@heroicons/react/20/solid";
import Status from "../ui/Status";
import {useModalStore} from "../store/modal";

type PeriodVideoRatingListProps = {
    rating: ChannelVideosRating,
    period: number,
    field: "views" | "vs" | "erv" | "score"
    loading: boolean
    blocked?: boolean
}

function PeriodVideoRatingList({rating, period, field, loading, blocked}: PeriodVideoRatingListProps) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    const daily = useMemo(() => {
        return period % 24 == 0 && period != 0;
    }, [period])

    return (
        <Card className={"mt-8 loadable " + (loading ? "loadable_active" : "")}>
            <div className="font-bold mb-8">
                {t("channel_page.video_rating.heading_" + field)} &nbsp;
                {(period === 100 || period === 24 || period === 48)
                    ? t("channel_page.video_rating." + period.toString()).toLowerCase()
                    : t("channel_page.video_rating.period_" + (daily ? "day" : "hour"),
                        {count: (daily ? period / 24 : period), serial: (daily ? period / 24 : period)})
                }
            </div>
            <div className="flex gap-4">
                {!rating[field].length &&
                    <Status
                        icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                        headline={t("video_page.no_data")}
                        text={t("video_page.need_more_data")}
                    />
                }
                {rating[field].slice(0, 5).map((snap, i) => {
                    return <SnapshotCard
                        key={i}
                        snap={snap}
                        blocked={blocked && i !== 0}
                        bottomText={
                            (!blocked || i === 0 || field === "views") ? (t("video_page.stats.table." + field) + " " + formatNumber(snap[field], field === "vs" || field === "erv"))
                                : <div className="flex items-center">{t("video_page.stats.table." + field)} <Icon className="p-0 ml-1" icon={LockClosedIcon} color="gray" size="xs"/></div>
                        }
                        onClick={() => {setModalShown(true)}}
                    />;
                })}
            </div>

            {rating[field].length ?
                <div className="text-blue-500 text-sm text-center mt-8 cursor-pointer" onClick={() => {setModalShown(true)}}>
                    {t("channel_page.video_rating.view_all")}
                </div>
                : <></>
            }

            <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                <div className="text-center text-lg mb-8 font-bold">
                    {t("channel_page.video_rating.heading_" + field)} &nbsp;
                    {(period === 100 || period === 24 || period === 48)
                        ? t("channel_page.video_rating." + period.toString()).toLowerCase()
                        : t("channel_page.video_rating.period_" + ((period % 24 == 0 && period != 0) ? "day" : "hour"),
                            {count: ((period % 24 == 0 && period != 0) ? period / 24 : period), serial: (period % 24 == 0 ? period / 24 : period)})
                    }
                </div>
                {modalShown ?
                    <VideosTable
                        blocked={blocked}
                        snapshots={rating[field]}
                        initialSortingKey={field}
                        showViews={false}
                        onClick={(blocked) ? () => {setSubscribeModalOpen(true, {limit: false, trial: true})} : undefined}
                        tableColumns={["preview", "title", "views", "vs", "erv"]}
                    /> : <></>
                }

            </Modal>
        </Card>
    );
}

export default PeriodVideoRatingList;