import React, {useState} from 'react';
import Status from "../ui/Status";
import {CreditCardIcon, HandRaisedIcon} from "@heroicons/react/20/solid";
import {Button, Icon} from "@tremor/react";
import {useTranslation} from "react-i18next";
import {SubscribeModal} from "../modules/subscription";

function ExpiredPage() {
    const {t} = useTranslation();
    const [modalShown, setModalShown] = useState(false);
    return (
        <div className="flex flex-col min-h-full justify-center items-center">
            <Status
                className="flex flex-col items-center max-w-sm mb-4"
                icon={
                    <div className="rounded-full bg-red-500 mb-4">
                        <Icon icon={HandRaisedIcon} size="xl" color={"white" as "purple"} />
                    </div>
                }
                headline={t("header.notifications.expired_heading")}
                text={t("header.notifications.expired_text")}
            />
            <Button icon={CreditCardIcon} onClick={() => {setModalShown(true)}}>{t("header.notifications.renew_subscription")}</Button>
            <SubscribeModal shown={modalShown} close={() => {setModalShown(false)}}/>
        </div>
    );
}

export default ExpiredPage;