import {redirect} from "react-router-dom";
import {userTopics} from "../modules/topic/http";
import {subscriptionInfo} from "../modules/subscription";
import {User} from "../models/User";

export async function mainLoader() {
    if (!localStorage.getItem("access_token")) {
        return redirect("/auth/login");
    }

    try {
        const subscription = await subscriptionInfo();
        localStorage.setItem("subscription", JSON.stringify(subscription!.data.subscription))
        localStorage.setItem("plan", JSON.stringify(subscription!.data.plan))
        localStorage.setItem("channels_used", JSON.stringify(subscription!.data.channels_used))

        const topics = await userTopics();

        if (!!localStorage.getItem("user")) {
            let user = JSON.parse(localStorage.getItem("user")!) as User;

            user.email_verified = true;

            localStorage.setItem("user", JSON.stringify(user));
        }

        return topics.data
    } catch (e: any) {
        if (e.response.status === 423) return [];
        // if (e.response.status === 402) return redirect("/expired");
        throw new Response("something_went_wrong", { status: 500 });
    }
}