import React, {MouseEventHandler, useState} from 'react';
import {Channel} from "../../../models/Channel";
import {Icon} from "@tremor/react";
import {CheckCircleIcon, EyeSlashIcon, PlusCircleIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {addChannelToTopic, deleteChannelFromTopic} from "../http";
import {useModalStore} from "../../../store/modal";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../utils/numbers";
import * as Sentry from "@sentry/react";

type ChannelAddCardProps = {
    channel: Channel
    topicId: string
    onCrossClick: MouseEventHandler<HTMLDivElement>
    onClick?: MouseEventHandler<HTMLDivElement>
    hideCross?: boolean
    hideLink?: boolean
}

function ChannelAddCard({channel, hideCross, topicId, onCrossClick, onClick, hideLink}: ChannelAddCardProps) {
    const {t} = useTranslation();
    const [channelAdded, setChannelAdded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mouseOverAvatar, setMouseOverAvatar] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    if (!channel.title) {
        return (
            <></>
        );
    }

    async function handleAddChannel() {
        setLoading(true);

        if (channelAdded) {
            try {
                const res = await deleteChannelFromTopic(topicId, channel.id);
                if (!!res && res.status === 200) {
                    setChannelAdded(false);
                }// @ts-ignore
            } catch (e: AxiosError) {
                Sentry.captureException(e);
            }
        } else {
            try {
                const res = await addChannelToTopic(topicId, channel.id);
                if (!!res && res.status === 200) {
                    setChannelAdded(true);
                }// @ts-ignore
            } catch (e: AxiosError) {
                if (!!e.response && e.response.status === 409) {
                    setSubscribeModalOpen(true, {limit: true, trial: false});
                } else if (!!e.response && e.response.status === 402) {
                    setSubscribeModalOpen(true, {limit: false, trial: false, replacementLimit: true});
                }
            }
        }

        setLoading(false);
    }

    return (
        <div className="block relative w-60 shrink-0 flex flex-col items-center text-center group">
            {!hideCross && <div className="absolute right-6 top-0 cursor-pointer transition-all opacity-0 group-hover:opacity-100" onClick={onCrossClick}>
                <Icon icon={EyeSlashIcon} color="slate" className="p-0"/>
            </div>}
            <div className="rounded-full w-32 relative cursor-pointer" onClick={onClick ?? handleAddChannel}>
                <div
                    onMouseEnter={() => {setMouseOverAvatar(true)}}
                    onMouseLeave={() => {setMouseOverAvatar(false)}}
                    className={"absolute bg w-full h-full rounded-full bg-gray-950/50 flex items-center justify-center transition-all " + (channelAdded ? "opacity-100" : "opacity-0 group-hover:opacity-100")}>
                    {loading ?
                        <div className="loadable loadable_active"></div>
                        : (channelAdded ?
                            <div>
                                <Icon icon={mouseOverAvatar ? XCircleIcon : CheckCircleIcon} style={{padding: 0}} size="xl" className="text-white" />
                                {mouseOverAvatar ? <div className="text-sm text-white text-center">{t('topic_page.cancel')}</div> :<></>}
                            </div>
                            : <div>
                                <Icon icon={PlusCircleIcon} size="xl" className="text-white" style={{padding: 0}} />
                                <div className="text-sm text-white text-center">{t('topic_page.add_channel')}</div>
                            </div>
                           )
                    }
                </div>
                <img src={channel.avatar} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/person.svg";
                }}  alt="avatar" className="block w-32 rounded-full"/>
            </div>
            <div className="text-sm mt-2 text-slate-700">{channel.title}</div>
            <div className="text-sm mb-1 text-slate-500">{formatNumber(channel.subscriptions)} {t("channel_page.subscribers")}</div>
            {hideLink ? <></> : <a className="text-sm text-blue-500" href={"https://www.youtube.com/channel/" + channel.youtube_id} target="_blank">{t("channel_page.go_to_youtube")}</a>}
        </div>
    );
}

export default ChannelAddCard;