import { create } from 'zustand'

type ModalOptions = {
    trial: boolean
    limit: boolean
    replacementLimit?: boolean
}

type State = {
    subscribeModalOpen: boolean
    options: ModalOptions
}

type Action = {
    setSubscribeModalOpen:  (open: boolean, options: ModalOptions) => void
}

export const useModalStore = create<State & Action>((set) => ({
    subscribeModalOpen: false,
    options: {trial: false, limit: false, replacementLimit: false},
    setSubscribeModalOpen: (open: boolean, options: ModalOptions) => set(() => ({subscribeModalOpen: open, options: {...options}})),
}))