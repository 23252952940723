import React, {FormEvent, useMemo, useRef, useState} from 'react';
import {
    Button,
    Card, DateRangePicker, DateRangePickerItem,
    DateRangePickerValue, Icon, Select, SelectItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    TextInput
} from "@tremor/react";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {EnvelopeIcon} from "@heroicons/react/24/solid";
import {GetUserSubscriptionsResponse} from "../../../models/GetUserSubscriptionsResponse";
import {emailValid} from "../../../utils/regexp";
import {getUserSubscriptions, giveSubscription, resetSubscription} from "../http";
import {AxiosError} from "axios";
import {stringToDateString} from "../../../utils/date";
import {
    ArrowPathIcon,
    ClockIcon, CurrencyDollarIcon,
    PlusIcon
} from "@heroicons/react/20/solid";
import {formatNumber} from "../../../utils/numbers";
import Modal from "../../../ui/Modal";
import {ru} from "date-fns/locale";
import Status from "../../../ui/Status";
import TopicCopy from "./TopicCopy";
import {Subscription} from "../../../models/Subscription";
import {Plan} from "../../../models/Plan";

function SubscriptionManager() {
    const {t} = useTranslation();

    const searchInput = useRef<HTMLInputElement>(null);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(false);
    const [loadingReset, setLoadingReset] = useState(false);
    const [subscriptionResponse, setSubscriptionResponse] = useState<GetUserSubscriptionsResponse>();
    const [modalShown, setModalShown] = useState(false);
    const [subscriptionReset, setSubscriptionReset] = useState(false);
    const [dateValue, setDateValue] = useState<DateRangePickerValue>({
        from: undefined,
        to: undefined,
    });
    const [price, setPrice] = useState<number>(0);
    const [planID, setPlanID] = useState<string>();
    const [subscriptionError, setSubscriptionError] = useState('');

    const activeSubscription = useMemo<Subscription | null>(() => {
        if (!subscriptionResponse || !subscriptionResponse.subscriptions || !subscriptionResponse.subscriptions.length) {
            return null;
        }

        let sortedSubscriptions = subscriptionResponse.subscriptions.sort((a, b) => (+new Date(b.expires_at)) - (+new Date(a.expires_at)));

        if ((+new Date() - +new Date(sortedSubscriptions[0].expires_at)) < 0) {
            return sortedSubscriptions[0];
        }

        return null;
    }, [subscriptionResponse])

    const activePlan = useMemo<Plan | null>(() => {
        if (!activeSubscription || !subscriptionResponse) return null;

        let plan = subscriptionResponse.plans.find((el) => el.id == activeSubscription.plan_id);
        if (!plan) {
            return {
                id:  (Math.random() + 1).toString(36).substring(7),
                title: 'Пробный',
                description: "Пробный тариф",
                price: 0,
                price_usd: 0,
                channel_count: 25,
                created_at: (new Date()).toString(),
                visible: true,
                is_trial: true,
            }
        }

        return plan;
    }, [activeSubscription, subscriptionResponse])

    function setNullsOnSubscriptionState() {
        setPlanID(undefined);
        setSubscriptionError('');
        setDateValue({from: undefined, to: undefined});
        setModalShown(false);
        setLoadingSubscription(false);
        setSubscriptionReset(false);
        setPrice(0);
    }

    function submitEmail(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setNullsOnSubscriptionState();

        setLoading(true);
        const fetchData = async () => {
            const response = await getUserSubscriptions(email);
            setSubscriptionResponse(response.data);
            setLoading(false);
            setEmail('');
        }

        fetchData().catch((e: AxiosError) => {
            if (e.response && e.response.status == 404) {
                setEmailError(t("auth.errors.user_not_found").toString());
            }

            setLoading(false);
        });

        if (searchInput.current) {
            searchInput.current.blur();
        }
    }

    function submitSubscription() {
        if (!planID || !dateValue.from || !dateValue.to || !subscriptionResponse) {
            setSubscriptionError(t("subscription_manager_page.fill_all_fields").toString());
            return;
        } else {
            setSubscriptionError('');
        }

        setLoadingSubscription(true);

        let startDate = `${dateValue.from.getFullYear().toString()}-${('0' + (dateValue.from.getMonth()+1)).slice(-2)}-${('0' + dateValue.from.getDate()).slice(-2)}`;
        let endDate = `${dateValue.to.getFullYear().toString()}-${('0' + (dateValue.to.getMonth()+1)).slice(-2)}-${('0' + dateValue.to.getDate()).slice(-2)}`;

        const fetchData = async () => {
            const result = await giveSubscription(subscriptionResponse?.user.id!, planID, startDate, endDate, price);
            if (result.status == 200) {
                setModalShown(false);
                if (subscriptionResponse.subscriptions) {
                    setSubscriptionResponse({
                        user: subscriptionResponse.user,
                        subscriptions: [{
                            id: (Math.random() + 1).toString(36).substring(7),
                            paid_at: dateValue.from!.toISOString(),
                            expires_at: dateValue.to!.toISOString(),
                            plan_id: planID,
                            user_id: subscriptionResponse.user.id,
                            paid: true,
                            price: price,
                            inv_id: 0,
                            channel_count: 0,
                            created_at: (new Date()).toISOString(),
                        }, ...subscriptionResponse.subscriptions],
                        plans: subscriptionResponse.plans,
                        topics: subscriptionResponse.topics,
                    });
                } else {
                    setSubscriptionResponse({
                        user: subscriptionResponse.user,
                        subscriptions: [{
                            id: (Math.random() + 1).toString(36).substring(7),
                            paid_at: dateValue.from!.toISOString(),
                            expires_at: dateValue.to!.toISOString(),
                            plan_id: planID,
                            user_id: subscriptionResponse.user.id,
                            paid: true,
                            price: price,
                            inv_id: 0,
                            channel_count: 0,
                            created_at: (new Date()).toISOString(),
                        }],
                        plans: subscriptionResponse.plans,
                        topics: subscriptionResponse.topics,
                    });
                }

                setNullsOnSubscriptionState();
            } else {
                setSubscriptionError(t("errors.something_went_wrong").toString());
            }

            setLoadingSubscription(false);
        }

        fetchData().catch(() => {
            setSubscriptionError(t("errors.something_went_wrong").toString());
            setLoadingSubscription(false);
        })
    }

    function resetLimit() {
        if (!subscriptionResponse || !subscriptionResponse.user
            || !subscriptionResponse.subscriptions || !subscriptionResponse.subscriptions.length) return;
        setLoadingReset(true);

        const reset = async () => {
            await resetSubscription(subscriptionResponse.user.id);
            setSubscriptionReset(true);
            setLoadingReset(false);

            let responseCopy = {...subscriptionResponse};
            let subscriptionCopy = {...subscriptionResponse.subscriptions![0]};

            subscriptionCopy.channel_count = 0;

            responseCopy.subscriptions![0] = subscriptionCopy;

            setSubscriptionResponse({...responseCopy});
        }

        reset().catch((e) => {
            setLoadingReset(false);
        })
    }

    if (subscriptionResponse) {
        return (
            <WideCard className="wide-card_tiny">
                <div className="text-lg font-bold text-center mb-8">{t("subscription_manager_page.heading")}</div>
                <Card className="mb-8">
                    <form className="flex max-w-md mx-auto items-start" onSubmit={submitEmail}>
                        <div className="mr-4 grow">
                            <TextInput ref={searchInput} onChange={(event) => {
                                setEmail(event.target.value.trim());
                            }} icon={EnvelopeIcon} value={email} placeholder={t("subscription_manager_page.user_email").toString()}
                                       error={emailError != ''} errorMessage={emailError} className="w-full"/>
                        </div>
                        <Button type="submit" loading={loading}>{t("subscription_manager_page.done")}</Button>
                    </form>
                </Card>
                <Card>
                    <div className="grid grid-cols-2">
                        <div className="">
                            <div className="font-bold mb-2">{t("settings_page.account.regdata")}</div>
                            <div className="text-sm text-slate-500 mb-2">{t("settings_page.account.id")}: {subscriptionResponse.user.id}</div>
                            <div className="text-sm text-slate-500 mb-2">{t("settings_page.account.email")}: {subscriptionResponse.user.email}</div>
                            <div className="text-sm text-slate-500">{t("settings_page.account.registration_date")}: {stringToDateString(subscriptionResponse.user.created_at!)}</div>
                        </div>
                    </div>
                </Card>

                <>
                    {(subscriptionResponse.topics && subscriptionResponse.topics.length) && <Card className="mt-8">
                        <div className="font-bold mb-4">{t("subscription_manager_page.topics")}</div>
                        <div className="grid grid-cols-2 gap-4 gap-x-16">
                            {subscriptionResponse.topics.map((el, i) => {
                                return el.youtube_urls != '' ? <TopicCopy topic={el} key={i} /> : <></>
                            })}
                        </div>
                    </Card>}
                </>

                <>
                    {(!!activeSubscription && !!activePlan) && <Card className="mt-8">
                        <div className="grid grid-cols-2">
                            <div>
                                <div className="font-bold mb-2">
                                    {t("settings_page.subscription_channels", {count: activePlan.channel_count})}
                                </div>
                                <div className="text-sm text-slate-500 mb-2">
                                    {t("settings_page.subscription_expires")} <span className="font-bold">{stringToDateString(activeSubscription.expires_at)}</span>
                                </div>
                                <div className="text-sm text-slate-500">
                                    {t("settings_page.subscription_channels_limit", {count: activeSubscription.channel_count, all: activePlan.channel_count * 3})}
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 items-stretch justify-center max-w-[50%] mx-auto">
                                <Button onClick={() => {setModalShown(true)}} icon={PlusIcon}>{t("subscription_manager_page.add_subscription")}</Button>
                                <Button onClick={resetLimit} icon={ArrowPathIcon} loading={loadingReset} disabled={subscriptionReset}>{subscriptionReset ? t("subscription_manager_page.limit_reset") : t("subscription_manager_page.reset_limit")}</Button>
                            </div>
                        </div>
                    </Card>}
                </>
                <>
                    {(!activeSubscription || !activePlan) && <Card className="mt-8">
                        <div className="flex gap-4 items-center justify-center">
                            <Button onClick={() => {setModalShown(true)}} icon={PlusIcon}>{t("subscription_manager_page.add_subscription")}</Button>
                        </div>
                    </Card>}
                </>

                <Card className="mt-8">
                    <div className="font-bold">{t("subscription_manager_page.subscriptions")}</div>
                    {(subscriptionResponse.subscriptions && subscriptionResponse.subscriptions.length)
                        ?
                        <Table className="mt-5" style={{marginLeft: -16, marginRight: -16}}>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell className="text-slate-600">{t("subscription_manager_page.plan")}</TableHeaderCell>
                                    <TableHeaderCell className="text-slate-600">{t("subscription_manager_page.start")}</TableHeaderCell>
                                    <TableHeaderCell className="text-slate-600">{t("subscription_manager_page.end")}</TableHeaderCell>
                                    <TableHeaderCell className="text-slate-600">{t("subscription_manager_page.price")}</TableHeaderCell>
                                    <TableHeaderCell className="text-slate-600">{t("subscription_manager_page.limit")}</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                        {subscriptionResponse.subscriptions.map((sub) => (
                                            <TableRow key={sub.id}>
                                                <TableCell>{subscriptionResponse.plans.find((el) => el.id == sub.plan_id) ?
                                                    subscriptionResponse.plans.find((el) => el.id == sub.plan_id)!.title
                                                    : t("subscription_manager_page.trial")
                                                }</TableCell>
                                                <TableCell>{stringToDateString(sub.paid_at)}</TableCell>
                                                <TableCell>{stringToDateString(sub.expires_at)}</TableCell>
                                                <TableCell>{formatNumber(sub.price)} {sub.price < 500 ? "€" : "₽"}</TableCell>
                                                <TableCell>{sub.channel_count}/{subscriptionResponse.plans.find((el) => el.id == sub.plan_id) ?
                                                    subscriptionResponse.plans.find((el) => el.id == sub.plan_id)!.channel_count * 3
                                                    : 75
                                                }</TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                        : <Status
                            icon={<Icon icon={ClockIcon} size="xl" color="slate"/>}
                            headline={t("subscription_manager_page.no_subscriptions")}
                            text={t("subscription_manager_page.no_subscriptions_text")}
                            className="mt-8"
                        />
                    }
                </Card>
                <Modal shown={modalShown} close={() => {setModalShown(false)}} className="overflow-visible" widthClass="max-w-xl">
                    <div className="text-lg font-bold text-center mb-8">{t("subscription_manager_page.add_subscription")}</div>
                    <div className="flex flex-col items-center">
                        <DateRangePicker
                            className="max-w-full w-full"
                            onValueChange={setDateValue}
                            locale={ru}
                            value={dateValue}
                            placeholder={t("subscription_manager_page.choose_dates").toString()}
                            enableSelect={true}
                            selectPlaceholder={t("subscription_manager_page.choose").toString()}
                        >
                            <DateRangePickerItem
                                key="month"
                                value="month"
                                from={new Date()}
                                to={new Date((new Date()).setMonth( (new Date()).getMonth()+1))}
                            >
                                {t("subscription_manager_page.month")}
                            </DateRangePickerItem>
                        </DateRangePicker>
                        <Select
                            onValueChange={(value) => {
                                setPlanID(value);
                                let plan = subscriptionResponse.plans.find((el) => el.id == value);
                                if (plan) setPrice(plan.price);
                            }}
                            placeholder={t("subscription_manager_page.plan").toString()}
                            className="mt-4"
                        >
                            {subscriptionResponse.plans.map((el) => {
                                return <SelectItem value={el.id} key={el.id}>{el.title}</SelectItem>;
                            })}
                        </Select>
                        <TextInput value={price.toString()} onChange={(event) => setPrice(Number.isNaN(parseFloat(event.target.value)) ? 0 : parseFloat(event.target.value))} icon={CurrencyDollarIcon} placeholder={t("subscription_manager_page.price").toString()}
                                   className="mt-4"/>
                        {subscriptionError != '' && <div className="text-red-500 mt-4 text-sm">{subscriptionError}</div>}
                        <Button className="mt-4" onClick={submitSubscription} loading={loadingSubscription}>{t("subscription_manager_page.done")}</Button>
                    </div>
                </Modal>
            </WideCard>
        );
    }

    return (
        <WideCard className="wide-card_tiny">
            <div className="text-lg font-bold text-center mb-8">{t("subscription_manager_page.heading")}</div>
            <Card>
                <form className="flex max-w-md mx-auto items-start" onSubmit={submitEmail}>
                    <div className="mr-4 grow">
                        <TextInput onChange={(event) => {
                            setEmail(event.target.value.trim());
                        }} icon={EnvelopeIcon} value={email} placeholder={t("subscription_manager_page.user_email").toString()}
                                   error={emailError != ''} errorMessage={emailError} className="w-full"/>
                    </div>
                    <Button type="submit" loading={loading}>{t("subscription_manager_page.done")}</Button>
                </form>
            </Card>
        </WideCard>
    );
}

export default SubscriptionManager;