import React from 'react';
import WideCard from "../ui/WideCard";
import Card from "../ui/Card";

function UserAgreement() {
    return (
        <div className="container py-8">
            <WideCard>
                <Card>
                    <div className="text-md">
                        <h1>Условия использования сайта wisewatcher.ru</h1>

                        <h2>Пользовательское соглашение</h2>

                        <p><strong>1. Общие положения</strong><br />
                            1.1. Администрация сайта (далее &mdash; &laquo;Администрация&raquo;) в лице индивидуального предпринимателя Коновалова Дениса Юрьевича, ОГРНИП 316435000050879, предлагает пользователю сети Интернет (далее &mdash; Пользователь) &mdash; использовать сайт wisewatcher.ru и его сервисы, включая одноименную программу, (далее &mdash; &laquo;Сайт&raquo;) на условиях, изложенных в настоящем Пользовательском соглашении (далее &mdash; &laquo;Соглашение&raquo;, &laquo;ПС&raquo;). Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.3 Соглашения.<br />
                            1.2. Использование Сайта регулируется настоящим Соглашением и Политикой конфиденциальности https://app.wisewatcher.ru/privacy-policy. Соглашение может быть изменено Администрацией без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция ПС всегда находится на странице по адресу https://wisewatcher.ru/user-agreement.<br />
                            1.3. Начиная использовать Сайт, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не в праве использовать Сайт. В случае если Администрацией были внесены какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.2 Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование Сайта.</p>

                        <p><strong>2. Учетная запись Пользователя.</strong><br />
                            Согласие на обработку персональных данных.<br />
                            2.1.В целях получения права использования основной функциональности Сайта Посетителю необходимо пройти Авторизацию на Сайте.<br />
                            2.2. Авторизация Посетителя на Сайте осуществляется:<br />
                            2.2.1. с помощью подключенных социальных сетей, на базе протокола OAuth и не предполагает передачу Администрации Сайта никакой информации, кроме общедоступной информации, согласно добровольно установленным Посетителем настойкам приватности в избранной им для прохождения процедуры авторизации на Сайте социальной сети. Данная информация может в том числе включать в себя: электронный адрес (email), имя, фамилия, отчество, фото профиля, возраст либо возрастной диапазон, пол, язык, город, страна.<br />
                            2.2.2. с помощью формы регистрации, предоставляя Администрации Сайта необходимую информацию для формирования Аккаунта, включая уникальные для каждого Посетителя адрес электронной почты (email) и пароль доступа к Сайту, имя пользователя в Telegram (или номер телефона, привязанный к аккаунту Telegram для получения оповещений от Telegram-бота), а также фамилию и имя.<br />
                            Установленные Посетителем логин и пароль являются необходимой и достаточной информацией для доступа Посетителя на Сайт.<br />
                            2.3. Обработка полученных при авторизации в минимально необходимом объеме персональных данных Посетителя осуществляется в соответствии с законодательством Российской Федерации. Администрация Сайта обрабатывает персональные данные Посетителя в целях предоставления Посетителю доступа Сайту, что также включает в себя:<br />
                            Дополнительная функциональность включает в себя возможности пользования Сайтом, которые Посетитель получает после прохождения регистрации/авторизации либо за плату, либо по решению Администрации:<br />
                            &ndash; доступ к персональной аналитике.<br />
                            &ndash; доступ к подписке.<br />
                            &ndash; отправку Посетителю информационных сообщений, уведомлений и новостных рассылок в Аккаунте и/или на указанный при регистрации email, Telegram-аккаунт.<br />
                            &ndash; проверку, исследование и анализ данных, позволяющих поддерживать, улучшать Сайт, а также разрабатывать новые функции и разделы Сайта.<br />
                            Администрация Сайта принимает все необходимые меры для защиты персональных данных Посетителя от неправомерного доступа, изменения, раскрытия или уничтожения. Раскрытие предоставленной Посетителем информации может быть произведено лишь в соответствии с действующим законодательством Российской Федерации по требованию суда, правоохранительных органов, а равно в иных предусмотренных законодательством Российской Федерации случаях.<br />
                            Поскольку Администрация Сайта осуществляет обработку персональных данных Посетителя исключительно в целях исполнения настоящих Правил, в силу положений законодательства Российской Федерации о персональных данных согласие Посетителя на обработку его персональных данных не требуется.<br />
                            2.4. Посетитель имеет право прекратить использование Сайта и отказаться от созданного им Аккаунта, направив запрос на удаление Аккаунта на адрес электронной почты support@wisewatcher.ru, со своего адреса электронной почты, указанного при регистрации на Сайте. Администрация удаляет Аккаунт Посетителя в течение 30 (тридцати) дней после получения его письменного мотивированного запроса.</p>

                        <p><strong>3. Условия использования Сайта.</strong><br />
                            3.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Сайта, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сайта.<br />
                            3.2. При использовании Сайта Пользователь не вправе:<br />
                            3.2.1. Cкриншотить, загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, который является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ или иного оружия;<br />
                            3.2.2. нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;<br />
                            3.2.3. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;<br />
                            3.2.4. загружать, посылать, передавать или любым другим способом размещать и/или распространять не разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих адресов электронной почты, схемы &laquo;пирамид&raquo;, многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка и e-mail-бизнесов, &laquo;письма счастья&raquo;;<br />
                            3.2.5. загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную информацию;<br />
                            3.2.6. несанкционированно собирать и хранить персональные данные других лиц;<br />
                            3.2.7. нарушать нормальную работу Сайта;<br />
                            3.2.9. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;<br />
                            3.2.10. другим образом нарушать нормы законодательства, в том числе нормы международного права.<br />
                            3.2.11 Заказчик не вправе:<br />
                            3.2.11.1. Обходить технические ограничения, установленные на Платформе или Сайте.<br />
                            3.2.11.2. Изучать технологию, декомпилировать или дизассемблировать Сайт, Интеллектуальную собственность, любые материалы, доступ к которым получает Заказчик в связи с исполнением Соглашения.<br />
                            3.2.11.3. Создавать копии Сайта, Интеллектуальной собственности, любых материалов, доступ к которым получает Заказчик в связи с исполнением Соглашения, а также копировать их внешнее оформление (дизайн).<br />
                            3.2.11.4. Изменять Сайт, совершать действия, направленные на изменение функционирования и работоспособности Сайта. 3.2.11.5 Предоставлять третьим лицам доступ к Интеллектуальной собственности Администрации сайта, личному кабинету (в том числе чату онлайн-мессенджера , созданного Исполнителем) и любым материалам, доступ к которым получает Пользователь в связи с исполнением Договора в отсутствие прямого письменного согласия на такой доступ со стороны Администрации.<br />
                            3.2.11.6. Создавать производные и аналогичные обучающие курсы, распространять, передавать третьим лицам или иным образом использовать частично или полностью материалы и содержание Сайта, Обучающих материалов.<br />
                            3.2.11.7. Продавать, уступать право требования к Администрации.</p>

                        <p><strong>4. Исключительные права на содержание Сайта и контент.</strong><br />
                            4.1. Все объекты, доступные при помощи Сайта, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее &mdash; содержание Сайта), а также любой контент, размещенный на Сайте, являются объектами исключительных прав Администрации, Пользователей и других правообладателей.<br />
                            4.2. Использование контента, а также каких-либо иных элементов Сайта возможно только в рамках предлагаемого функционала. Никакие элементы содержания Сайта, а также любой контент, размещенный на Сайте, не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д.<br />
                            Использование Пользователем элементов содержания Сайта, программного обеспечения, а также любого контента для личного некоммерческого использования, допускается при сохранении соответствующего объекта в неизменном виде и указании прямой ссылки на Сайт https://wisewatcher.ru.</p>

                        <p><strong>5. Сайты и контент третьих лиц.</strong><br />
                            5.1. Сайт может содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и их контент не проверяются Администрацией на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). Администрация не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ используя Сайт, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем.<br />
                            5.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на Сайте, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны Администрации, за исключением случаев, когда на это прямо указывается на Сайте.</p>

                        <p><strong>6. Реклама на Сайте.</strong><br />
                            6.1. Администрация допускает возможность размещения рекламы на Сайте и не несет ответственность за содержание рекламных объявлений, размещенных на сайте.</p>

                        <p><strong>7. Отсутствие гарантий, ограничение ответственности.</strong><br />
                            7.1. Пользователь использует Сайт на свой собственный риск. Сайт предоставляется &laquo;как есть&raquo;. Администрация не принимает на себя никакой ответственности, в том числе за соответствие Сайта целям Пользователя;<br />
                            7.2. Администрация не гарантирует, что: Сайт соответствует/будет соответствовать требованиям Пользователя; Сайт будет доступен непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием Сайта, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество какого-либо продукта, услуги, информации и пр., полученных с использованием Сайта, будет соответствовать ожиданиям Пользователя;<br />
                            7.3. Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием Сайта, Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который это может причинить Пользователю или третьим лицам, за потерю данных или любой другой вред;<br />
                            7.4. Администрация не несет ответственности за любые виды убытков, произошедшие вследствие использования Пользователем Сайта или отдельных частей/функций Сайта;<br />
                            7.5. При любых обстоятельствах ответственность Администрации ограничена 1000 (одной тысячей) российских рублей и возлагается на Администрацию при наличии в ее действиях доказанной в судебном порядке вины.<br />
                            7.6. Пользователь обязуется не предоставлять свои аутентификационные данные для доступа в личный кабинет Сайта третьим лицам, а также не предоставлять иными способами доступ к материалам личного кабинета Сайта третьим лицам (в том числе не обнародовать, не распространять материалы). 7.7. Администрация не несет ответственность за действия банков, электронных платежных систем, обеспечивающих оплату и возвраты денежных средств при заключении, исполнении и расторжении Соглашения.</p>

                        <p><strong>8. Вознаграждение Администрации.</strong><br />
                            8.1. Размер вознаграждения по Соглашению указывается на странице Сайта и зависит от тарифа, выбранного Пользователем:<br />
                            ● Тариф “до  25 наблюдаемых каналов” - от 2190 руб. (Лимит добавление не более 75 каналов в период действия подписки)<br />
                            ● Тариф “до  50 наблюдаемых каналов” - от 3490 руб (Лимит добавление не более 150 каналов в течении действия подписки)<br />
                            ● Тариф “до 100 наблюдаемых каналов” - от 6190 руб (Лимит добавление не более 300 каналов в течении действия подписки)<br />
                            8.2. Вознаграждение не облагается НДС в связи с применением Администрацией упрощенной системы налогообложения.<br />
                            8.3. Стоимость услуг, оказываемых по Соглашению, зависит от тарифа, выбранного Пользователем. Пользователь оплачивает выбранный им период доступа к возможностям Сайта. Минимальный срок оплаченного доступа составляет 30 (тридцать) дней. В информации, указанной на Сайте, продолжительность 1 месяца принимается как 30 дней в каждом случае, если прямо не указано иное.<br />
                            8.4. При оплате услуг доступа к Сайту, вознаграждение Администрации по Соглашению может производится в порядке 100% единовременной оплаты стоимости услуги до начала оказания услуг по предоставлению доступа к Сайту.<br />
                            8.5. Администрация не несет ответственность за условия предоставления банком Пользователю кредита, а также за отказ банка в предоставлении Пользователю кредита или рассрочки.<br />
                            8.6. Моментом оплаты услуг является момент поступления денежных средств на расчетный счет Администрации.<br />
                            8.7. Администрация вправе устанавливать различные скидки на стоимость оказываемых услуг, а также произвольно менять стоимость услуг. Предоплаченные Пользователем услуги оказываются на условиях, действовавших для данного Пользователя на момент оплаты до истечения срока предоплаченного периода пользования Сайтом.<br />
                            8.8 Администрация вправе в одностороннем порядке изменять цены на оказываемые услуги, информация о которых размещается на Сайте. Датой вступления в силу новых цен и условий оплаты считается дата их размещения на Сайте.<br />
                            8.9. Пользователь понимает и соглашается с тем, что в случае, если с момента регистрации Пользователя на Сайте до момента фактической оплаты Пользователем выбранной им услуги стоимость соответствующей услуги была увеличена Администрацией и информация об этом опубликована на Сайте, то Пользователь обязуется оплатить новую стоимость услуги или отказаться от заключения Соглашения.</p>

                        <p><strong>9. Иные положения.</strong><br />
                            9.1. Настоящее Соглашение представляет собой договор между Пользователем и Администрацией относительно порядка использования Сайта и заменяет собой все предыдущие соглашения между Пользователем и Администрацией.<br />
                            9.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином &laquo;законодательство&raquo; понимается как законодательство Российской Федерации, так и законодательство места пребывания Пользователя.<br />
                            9.3. При недостижении соглашения спор передается на рассмотрение в суд по месту нахождения Исполнителя.<br />
                            9.4. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрацией агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.<br />
                            9.5. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.<br />
                            9.6. Бездействие со стороны Администрации в случае нарушения Пользователем либо иными пользователями положений Соглашений не лишает Администрацию права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа Администрации от своих прав в случае совершения в последующем подобных либо сходных нарушений.<br />
                            9.7. Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть предоставлено Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии Соглашения на ином языке, применяются положения русскоязычной версии настоящего Соглашения.</p>

                        <p><strong>10. Изменение, расторжение Договора.</strong><br />
                            10.1. Администрация оставляет за собой право изменять или дополнять любые из условий Соглашения в любое время, опубликовывая все изменения на Сайте. Если опубликованные изменения для Пользователя неприемлемы, то он в течение 7 дней с момента опубликования изменений должен уведомить об этом Администрацию письменно. Если уведомления не поступило, то считается, что Пользователь продолжает принимать участие в договорных отношениях на новых условиях.<br />
                            10.2. Стороны вправе расторгнуть Соглашение по взаимному согласию в любой момент до момента его фактического исполнения. 10.3. Руководствуясь ч. 4 ст. 421, ч. 1. ст. 782, ст. 783, ст. 717 ГК РФ Стороны пришли к согласию и установили следующие условия и порядок досрочного расторжения Соглашения при одностороннем отказе Пользователя от его исполнения: в случае, если Пользователь желает расторгнуть Соглашение, он обязан:<br />
                            10.3.1. направить Администрации уведомление об одностороннем отказе в исполнении Соглашения (форма - Приложение №2 к Соглашению) с адреса электронной почты, указанной им при регистрации на Сайте. Соглашение считается расторгнутым с момента получения Администрацией Уведомления Пользователя об одностороннем отказе от исполнения Соглашения.<br />
                            10.3.2. возместить Администрации фактически понесенные расходы, связанные с оказанием услуг по Соглашению, в том числе:<br />
                            10.3.2.1. расходы на использованное Администрацией в процессе оказания услуг ПО и оплату услуг третьих лиц;<br />
                            10.3.2.2. комиссии банковских, кредитных организаций и соответствующих платежных систем за осуществление возврата денежных средств;<br />
                            10.3.2.3. иные расходы, понесенные Администрацией в процессе оказания услуг по Соглашению. Конкретная сумма фактических расходов определяется Администрацией самостоятельно.<br />
                            10.3.3. оплатить стоимость оказанных на момент расторжения Соглашения услуг.<br />
                            10.4. Пользователю подлежат возврату денежные средства, уплаченные им в качестве вознаграждения Администрации за вычетом стоимости оказанных в соответствии с условиями Соглашения услуг и расходов. Удерживаемая Администрацией с Пользователя сумма не является штрафом или иной санкцией.<br />
                            10.5. Соглашение может быть расторгнуто по инициативе Администрации в следующих случаях:<br />
                            10.5.1. нарушение Пользователем обязательств в рамках Соглашения;<br />
                            10.5.2. нарушение сроков оплаты вознаграждения Администрации.<br />
                            10.6. Расторжение Соглашения по одной из причин, указанных в п. 10.5. Соглашения происходит посредством уведомления Пользователя электронным сообщением на адрес электронной почты, указанной им при регистрации на Сайте. Денежные средства, уплаченные Администрации, возврату не подлежат. Соглашение считается расторгнутым с момента направления Администрацией соответствующего уведомления Пользователю.<br />
                            10.7. Соглашение может быть расторгнуто Администрацией в одностороннем порядке в случае оскорбления в адрес Администрации, распространения Пользователем недостоверной информации, информации, порочащей либо наносящей вред деловой репутации Администрации и оказываемым им услугам, любым способом, включая распространение указанной информации (в том числе, в виде обнародования переписки с Администрацией, сотрудниками или представителями) в Сети Интернет: в социальных сетях, в мессенджерах, посредством любого рода рассылок и т.д. В случае обнаружения Администрацией факта подобного поведения Пользователя, Соглашение расторгается, о чем Пользователь уведомляется Администрацией на адрес электронной почты, сообщенный Пользователем при регистрации на Сайте или при оплате услуг. Денежные средства, уплаченные Администрации, возврату не подлежат. Соглашение считается расторгнутым со дня направления Администрацией соответствующего сообщения Пользователю.<br />
                            10.8. В момент досрочного расторжения Соглашения доступ к материалам Сайта и любым другим материалам, предоставленным Администрацией Пользователю в процессе исполнения Соглашения, прекращается.<br />
                            10.9. В случае, если при расторжении Соглашения Администрацией обязана произвести возврат денежных средств Пользователю, последний обязан предоставить банковские реквизиты, с которых производилась оплата услуг. В противном случае, Администрация вправе не производить перечисление денежных средств до момента предоставления надлежащих банковских реквизитов. На период непредоставления надлежащих банковских реквизитов неустойка на сумму возврата не начисляется.</p>

                        <p><strong>11. Территория и срок действия Соглашения.</strong><br />
                            11.1. Соглашение действует с даты его заключения, которой считается дата акцепта Оферты Пользователем, до даты исполнения обязательств, принятых на себя по Соглашению Сторонами.<br />
                            11.2. Соглашение действует на территории всех стран мира и в интерактивной информационной сети Интернет.</p>

                        <p><strong>12. Заключительные положения. </strong><br />
                            12.1. Исключительные и личные неимущественные права на Сайт и любые результаты интеллектуальной деятельности, размещенные на Сайте, а также все материалы, доступ к которым получает Пользователь при заключении и исполнении Соглашения, принадлежат Администрации (либо третьим лицам, предоставившим Администрации право их использования) и охраняются действующим Законодательством Российской Федерации.<br />
                            12.2. Любая информация, доступ к которой получает Пользователь в связи с получением услуг по Соглашению, не может копироваться, передаваться третьим лицам, тиражироваться, распространяться, пересылаться, публиковаться в электронной, &laquo;бумажной&raquo; или иной форме без дополнительных соглашений или официального письменного согласия Администрации.<br />
                            12.3. Администрация имеет право уступать права, переводить долги по всем обязательствам, возникшим из Соглашения. Настоящим Пользователь дает свое согласие на уступку прав и перевод долга любым третьим лицам. О состоявшейся уступке прав и/или переводе долга Администрация информирует Пользователя посредством направления соответствующего сообщения на адрес электронной почты Пользователя, указанной им при регистрации на Сайте.<br />
                            12.4. Электронный документооборот по адресам электронной почты, указанной Администрацией в Соглашении, указанном Пользователем при регистрации на Сайте приравнивается Сторонами к документообороту на бумажных носителях, если в тексте Соглашения прямо не предусмотрено иное.<br />
                            12.5. По всем вопросам, не урегулированным Соглашением, Стороны руководствуются действующим законодательством Российской Федерации.</p>

                        <p><strong>Реквизиты:</strong><br />
                            ИП Коновалов Денис Юрьевич<br />
                            Адрес регистрации: 610029, Кировская область, г. Киров, пос. Ганино, ул. Майская, д.2, кв.16<br />
                            ИНН: 434582744451<br />
                            ОГРНИП: 316435000050879<br />
                            Расчетный счет: 40802810900002352367<br />
                            Банк: АО ТИНЬКОФФ БАНК<br />
                            ИНН банка: 7710140679<br />
                            БИК банка: 044525974</p>

                        <p><br />
                            Приложение № 1<br />
                            к Публичной оферте на заключение абонентского договора возмездного<br />
                            оказания услуг по доступу к Сайту от ___________ г.<br />
                            от_____________________________________<br />
                            паспорт серии ___________ №_____________<br />
                            выдан _________________________________<br />
                            _______________________________________<br />
                            код подразделения _______________________<br />
                            адрес:__________________________________<br />
                            e-mail: _________________________________<br />
                            тел:____________________________________<br />
                            Индивидуальному предпринимателю<br />
                            Коновалов Денис Юрьевич<br />
                            ИНН: 434582744451<br />
                            ОГРНИП: 316435000050879<br />
                            адрес: __________________<br />
                            e-mail: ___________________</p>

                        <p><br />
                            Уведомление об одностороннем отказе от исполнения соглашения</p>

                        <p>&quot;___&quot; ____ 20__ г. мною была акцептована Публичная оферта на заключение абонентского возмездного договора оказания услуг по предоставлению доступа к Сайту от _____________ г . (далее - Соглашение), произведена оплата услуги &quot;___________________&quot; в размере: ________________________ (_________________________________) рублей.<br />
                            В связи с _____________________________________________________________________<br />
                            уведомляю об одностороннем отказе от дальнейшего исполнения Соглашения и прошу произвести возврат уплаченных мною денежных средств за вычетом стоимости оказанных на момент получения Администрацией настоящего Уведомления услуг и расходов Администрации, понесенных в связи с оказанием мне услуг по обучению на курсе в соответствии с условиями Соглашения.<br />
                            Денежные средства прошу перечислить по следующим банковским реквизитам, с которых производилась оплата услуг:<br />
                            Наименование банка&nbsp;&nbsp; &nbsp;______________________________________<br />
                            БИК банка &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;______________________________________<br />
                            Расчетный счет&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;______________________________________</p>

                        <p><br />
                            Дата и подпись</p>

                        <p>_______________/________________</p>

                    </div>
                </Card>
            </WideCard>
        </div>
    );
}

export default UserAgreement;