import {AxiosResponse} from "axios";
import {ChannelVideosRating} from "../models/ChannelVideosRating";
import $api from "./index";
import {ChannelsRating} from "../models/ChannelsRating";

export function channelVideosRating(channelId: string, serialNumber: number, shorts: boolean = false, view: string = "channel"): Promise<AxiosResponse<ChannelVideosRating>> {
    return $api.get<ChannelVideosRating>(`rating/video/channel/${channelId}/serial/${serialNumber}?shorts=${shorts}&view=${view}`)
}

export function overallVideosRating(topicId: string, serialNumber: number, shorts: boolean = false): Promise<AxiosResponse<ChannelVideosRating>> {
    return $api.get<ChannelVideosRating>(`rating/video/topic/${topicId}/serial/${serialNumber}?shorts=${shorts}`)
}

export function channelsRating(topicId: string, shorts: boolean = false): Promise<AxiosResponse<ChannelsRating>> {
    return $api.get<ChannelsRating>(`rating/channel/topic/${topicId}?shorts=${shorts}`);
}