import React from 'react';
import WideCard from "../ui/WideCard";
import Card from "../ui/Card";
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

type BreadcrumbsPage = {
    title: string
    link: string
}

type BreadcrumbsProps = {
    pages?: BreadcrumbsPage[]
}

function Breadcrumbs({pages}: BreadcrumbsProps) {
    const {t} = useTranslation();

    return (
        <WideCard className="mb-8">
            <Card>
                <div className="flex">
                    <Link to="/" className="text-blue-500 text-sm">{t("breadcrumbs.main")}</Link>
                    <>
                        {pages && pages!.map((page, i) => {
                            if (i == (pages!.length - 1)) {
                                return <React.Fragment key={i}>
                                    <div className="text-sm text-slate-500 mr-2 ml-2">/</div>
                                    <div className="text-sm text-slate-500 max-w-xs truncate">{page.title}</div>
                                </React.Fragment>;
                            }
                            return <React.Fragment key={i}>
                                <div className="text-sm text-slate-500 mr-2 ml-2">/</div>
                                <Link to={page.link} className="text-sm text-blue-500 max-w-xs truncate">{page.title}</Link>
                            </React.Fragment>
                        })}
                    </>
                </div>
            </Card>
        </WideCard>
    );
}

export default Breadcrumbs;