import React, {useMemo, useState} from 'react';
import {Topic} from "../../../models/Topic";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {Icon} from "@tremor/react";
import {ClockIcon} from "@heroicons/react/20/solid";
import SnapshotCard from "../../../components/SnapshotCard";
import {formatNumber} from "../../../utils/numbers";
import Modal from "../../../ui/Modal";
import VideosTable from "../../../components/VideosTable";
import Card from "../../../ui/Card";
import Status from "../../../ui/Status";

type LatestVideosProps = {
    topic: Topic
}

function LatestVideos({topic}: LatestVideosProps) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);

    const snapshotsList = useMemo(() => {
        if (!topic.latest_snapshots || topic.latest_snapshots.length === 0) {
            return <Status
                icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                headline={t("video_page.no_data")}
                text={t("topic_page.no_new_videos")}
            />;
        }

        return topic.latest_snapshots.map((snap, i) => {
            return <SnapshotCard
                key={i}
                snap={snap}
                bottomText={t("video_page.stats.table.views") + " " + formatNumber(snap.views)}
                onClick={() => {setModalShown(true)}}
            />;
        });
    }, [topic]);

    return (
        <WideCard className="mt-8 mb-8">
            <div className="text-center text-lg mb-8 font-bold">{t("topic_page.new_videos")}</div>
            <Card>
                {/*<div className="font-bold mb-8">*/}
                {/*    {t("topic_page.new_videos")}*/}
                {/*</div>*/}
                <div className="flex gap-4">
                    {snapshotsList}
                </div>

                {topic.latest_snapshots && topic.latest_snapshots.length ?
                    <div className="text-blue-500 text-sm text-center mt-8 cursor-pointer" onClick={() => {setModalShown(true)}}>
                        {t("topic_page.view_all_list")}
                    </div>
                    : <></>
                }

                <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                    <div className="text-center text-lg mb-8 font-bold">
                        {t("topic_page.new_videos")}
                    </div>
                    <>
                        {topic.latest_snapshots && topic.latest_snapshots.length &&
                            <VideosTable
                                hoursInPeriod={true}
                                snapshots={topic.latest_snapshots}
                                tableColumns={["preview", "title", "serial_number", "views", "vs", "erv"]}
                            />
                        }
                    </>
                </Modal>
            </Card>
        </WideCard>
    );
}

export default LatestVideos;