import {AxiosResponse} from "axios/index";
import $api from "../../../http";
import {Topic} from "../../../models/Topic";
import {AuthResponse} from "../../../models/AuthResponse";
import {ChannelFindResponse} from "../../../models/ChannelFindResponse";

export function topicInfo(topicId: string, shorts: boolean = false): Promise<AxiosResponse<Topic>> {
    return $api.get<Topic>(`topic/${topicId}?shorts=${shorts}`)
}

export function deleteTopic(topicId: string): Promise<AxiosResponse> {
    return $api.delete(`topic/${topicId}`)
}

export function deleteChannelFromTopic(topicId: string, channelId: string): Promise<AxiosResponse> {
    return $api.delete(`topic/${topicId}/channel/${channelId}`)
}

export function deleteChannelFromTopicRecommendations(topicId: string, channelId: string): Promise<AxiosResponse> {
    return $api.delete(`topic/recommendations/${topicId}/channel/${channelId}`)
}

export function addChannelToTopic(topicId: string, channelId: string): Promise<AxiosResponse> {
    return $api.post(`topic/${topicId}/channel/${channelId}`)
}

export function userTopics(): Promise<AxiosResponse<Topic[]>> {
    return $api.get<Topic[]>(`topic/`)
}

export function createTopic(title: string): Promise<AxiosResponse<Topic>> {
    return $api.post<Topic>('topic/', {
        title: title,
    })
}

export function findChannels(channels: string[]): Promise<AxiosResponse<ChannelFindResponse>> {
    return $api.post<ChannelFindResponse>('channel/find', {
        youtube_urls: channels,
    })
}

export function createChannels(channels: string[], topicId: string): Promise<AxiosResponse> {
    return $api.post('channel', {
        youtube_urls: channels,
        topic_id: topicId,
    })
}