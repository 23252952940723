export const median = (arr: number[]): number | undefined => {
    if (!arr.length) return undefined;
    const s = [...arr].sort((a, b) => a - b);
    const mid = Math.floor(s.length / 2);
    return s.length % 2 === 0 ? ((s[mid - 1] + s[mid]) / 2) : s[mid];
};

export const formatNumber = (num: number, percentage: boolean = false): string => {
    if (!num) return '-';

    let result = '';

    if (num % 1 === 0) {
        result = num.toLocaleString();
    } else {
        result = num.toFixed(2).toLocaleString();
    }

    if (percentage) result += "%";

    return result;
};

export const formatTick = (num: number, _: number): string => {
    if (!num) return '0';

    let result = '';

    if (num % 1 === 0) {
        result = num.toLocaleString();
    } else {
        result = num.toFixed(2).toLocaleString();
    }

    return result;
};

export const formatTickPercentage = (num: number, _: number): string => {
    if (!num) return '0';

    let result = '';

    if (num % 1 === 0) {
        result = num.toLocaleString();
    } else {
        result = num.toFixed(2).toLocaleString();
    }

    return result + "%";
};