import React, {useMemo, useState} from 'react';
import Card from "../../../ui/Card";
import {Channel} from "../../../models/Channel";
import {useTranslation} from "react-i18next";
import {ChannelsRatingFields, ChannelFields} from "../../../models/ChannelsRating";
import {Icon} from "@tremor/react";
import {ClockIcon} from "@heroicons/react/20/solid";
import {formatNumber} from "../../../utils/numbers";
import ChannelCard from "../../../components/ChannelCard";
import Modal from "../../../ui/Modal";
import ChannelsTable from "../../../components/ChannelsTable";
import Status from "../../../ui/Status";

type TopicChannelsRatingListProps = {
    rating: Channel[]
    field: ChannelsRatingFields
    shorts: boolean
}

function TopicChannelsRatingList({field, rating, shorts}: TopicChannelsRatingListProps) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);

    if (!rating || !rating.length) {
        return <Card className="mt-8 mb-8">
            <div className="font-bold mb-8">{t("topic_page.rating.headings." + field)}</div>
            <Status
                icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                text={t("video_page.need_more_data")}
                headline={t("video_page.no_data")}/>
        </Card>;
    }

    const key = useMemo(() => {
        if (shorts) return field + "_shorts";
        return field;
    }, [field, shorts])

    return (
        <Card className="mt-8 mb-8">
            <div className="font-bold mb-8">{t("topic_page.rating.headings." + field)}</div>
            <div className="flex gap-8">
                {!rating.length &&
                    <div className="text-center text-sm max-w-xs mx-auto">
                        <Icon icon={ClockIcon} color="slate" size="xl"></Icon>
                        <div className="text-md font-bold text-slate-700">{t("video_page.no_data")}</div>
                        <div className="text-slate-500">{t("video_page.need_more_data")}</div>
                    </div>
                }
                {rating.slice(0, 5).map((channel) => {
                    // @ts-ignore
                    return <ChannelCard
                        key={channel.id}
                        channel={channel}
                        onClick={(event) => {
                            event.preventDefault();
                            setModalShown(true);
                        }}
                        bottomText={t("channel_page.rating." + field) + " " + formatNumber(channel[key as ChannelFields], field === "median_vs" || field === "median_erv")}
                    />;
                })}
            </div>
            {rating.length ?
                <div className="text-blue-500 text-sm text-center mt-8 cursor-pointer" onClick={() => {setModalShown(true)}}>
                    {t("channel_page.video_rating.view_all")}
                </div>
                : <></>
            }
            <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                <div className="text-center text-lg mb-8 font-bold">
                    {t("topic_page.rating.headings." + field)}
                </div>
                {modalShown ?
                    <ChannelsTable
                        channels={rating}
                        // @ts-ignore
                        initialSortingKey={key}
                        shorts={shorts}
                    /> : <></>
                }
            </Modal>
        </Card>
    );
}

export default TopicChannelsRatingList;