import React, {useEffect, useRef, useState} from 'react';
import {Topic} from "../../../models/Topic";
import {useTranslation} from "react-i18next";
import WideCard from "../../../ui/WideCard";
import {ChannelsRating, ChannelsRatingFields} from "../../../models/ChannelsRating";
import {channelsRating} from "../../../http/rating";
import TopicChannelsRatingList from "./TopicChannelsRatingList";
import {Icon, Tab, TabGroup, TabList} from "@tremor/react";
import {XCircleIcon} from "@heroicons/react/20/solid";
import Status from "../../../ui/Status";
import Card from "../../../ui/Card";

type TopicChannelsRatingProps = {
    topic: Topic
    shorts: boolean
}

function TopicChannelsRating({topic, shorts}: TopicChannelsRatingProps) {
    const {t} = useTranslation();

    const [rating, setRating] = useState<ChannelsRating>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [toggleKey, setToggleKey] = useState<ChannelsRatingFields>('sum_views');

    const toggleKeys:ChannelsRatingFields[] = ["sum_views", "avg_views", "median_vs", "median_erv", "current_videos"];

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const res = await channelsRating(topic.id, shorts);
            setLoading(false);
            setRating(res.data);
        }

        fetchData().catch((e) => {
            setLoading(false);
            setError(true);
        });
    }, [topic]);

    return (
        <WideCard>
            <div className="text-center text-lg mb-8 font-bold">{t("topic_page.rating.heading")}</div>
            <Card>
                <TabGroup
                    defaultIndex={0}
                    onIndexChange={(index) => {
                        setToggleKey(toggleKeys[index]);
                    }}
                    className="relative"
                >
                    <TabList variant="solid" color="zinc">
                        <Tab>{t("topic_page.rating.short_headings.sum_views")}</Tab>
                        <Tab >{t("topic_page.rating.short_headings.avg_views")}</Tab>
                        <Tab >{t("topic_page.rating.short_headings.median_vs")}</Tab>
                        <Tab >{t("topic_page.rating.short_headings.median_erv")}</Tab>
                        <Tab >{t("topic_page.rating.short_headings.current_videos")}</Tab>
                    </TabList>
                </TabGroup>
            </Card>
            <>
                {loading && <Card className="loadable loadable_active"> </Card>}
                {rating && <TopicChannelsRatingList key={toggleKey} rating={rating[toggleKey]} shorts={shorts} field={toggleKey}  />}
                {error && <Status
                    icon={<Icon icon={XCircleIcon} color="red" size="xl"></Icon>}
                    text={t("video_page.something_went_wrong_text")}
                    headline={t("video_page.something_went_wrong")}/>}
            </>
        </WideCard>
    );
}

export default TopicChannelsRating;