import React from 'react';
import Status from "../ui/Status";
import {Button, Icon} from "@tremor/react";
import {LifebuoyIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {Link, redirect, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function SubscriptionFailPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="h-screen w-screen flex items-center justify-center">
            <div className="text-center">
                <Status
                    icon={<Icon icon={XCircleIcon} size="xl" color="red" />}
                    headline={t("subscription_page.fail")}
                    text={t("subscription_page.fail_text")}
                    className="max-w-sm"
                />
                <div className="mt-4 flex gap-4 items-center justify-center">
                    <Button variant="secondary" onClick={() => {navigate("/")}}>{t("subscription_page.home")}</Button>
                    <Link to="https://t.me/wisewatcher_support" target="_blank">
                        <Button icon={LifebuoyIcon}>{t("subscription_page.write_to_support")}</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionFailPage;