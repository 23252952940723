import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Video} from "../../../models/Video";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import Card from "../../../ui/Card";
import Linkify from "linkify-react";
import classes from "./VideoInfo.module.scss";
import {Badge, Icon} from "@tremor/react";
import {videoChanges} from "../http";
import {Change} from "../../../models/Change";
import ChangeCard from "../../../components/ChangeCard";
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon, NoSymbolIcon, XCircleIcon} from "@heroicons/react/20/solid";
import Status from "../../../ui/Status";

type VideoInfoProps = {
    video: Video
}

function VideoInfo({video}: VideoInfoProps) {
    const {t} = useTranslation();

    const shouldFetch = useRef(true);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState<Change[]>();
    const [tagsCopied, setTagsCopied] = useState(false);

    const tagsArray = useMemo<string[]>(() => {
        if (!video.latest_snap) return [];
        return video.latest_snap!.tags.split(",");
    }, [video]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const results  = await videoChanges(video.id);
            setChanges(results.data);
            setLoading(false);
        };

        if (shouldFetch.current) {
            shouldFetch.current = false;
            fetchData().catch((e) => {
                setLoading(false);
                setError(true);
            });
        }
    }, []);

    function handleTagsCopy() {
        if (video.latest_snap) {
            navigator.clipboard.writeText(video.latest_snap!.tags).then(() => {setTagsCopied(true);});
        }
    }

    return (
        <WideCard className="mt-8">
            <div className="text-center text-lg mb-8 font-bold">{t("video_page.description_tags_changes")}</div>
            <div className="grid grid-cols-2 gap-8">
                <div className="col-span-2">
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.description')}</div>
                        <p className={classes['description'] + " text-sm"} style={{margin: 0, whiteSpace: "pre-wrap"}}>
                            <Linkify as="" className="text-sm" options={{target: "_blank"}}>
                                {video.latest_snap!.description}
                            </Linkify>
                        </p>
                    </Card>
                </div>
                <Card>
                    <div className="flex justify-between items-center mb-4">
                        <div className="font-bold">{t('video_page.tags')}</div>
                        <div className="text-slate-500 cursor-pointer flex items-center" onClick={handleTagsCopy}>
                            <Icon
                                icon={tagsCopied ? ClipboardDocumentCheckIcon : ClipboardDocumentIcon}
                                color="slate"
                            />
                            <span className="text-xs">{t(tagsCopied ? "video_page.tags_copied" : "video_page.copy_tags")}</span>
                        </div>
                    </div>
                    <div className={"flex flex-wrap gap-2 " +classes['tags']}>
                        {video.latest_snap!.tags === "" && <Status
                            className="mt-8 mb-8"
                            icon={<Icon icon={NoSymbolIcon} color="slate" size="xl"></Icon>}
                            headline={t("video_page.no_tags")}
                            text={t("video_page.no_tags_text")}
                        />}
                        {video.latest_snap!.tags !== "" && tagsArray.map((el, i) => {
                            return <a key={i} href={"https://trends.google.ru/trends/explore?gprop=youtube&q=" + el} target="_blank" className="cursor-pointer">
                                <Badge className="cursor-pointer" size="xs" key={i}>{el}</Badge>
                            </a>;
                        })}
                    </div>
                </Card>
                <Card className={"flex flex-col loadable " + (loading ? "loadable_active" : "")}>
                    <div className="font-bold mb-4">{t('video_page.changes')}</div>
                    <div
                        style={(!changes && !loading || error)
                            ? {height: "100%", display: "flex", justifyContent: "center", flexDirection: "column"} : {}
                    }>
                        {!changes && !loading && !error &&
                            <Status
                                icon={<Icon icon={NoSymbolIcon} color="slate" size="xl"></Icon>}
                                headline={t("video_page.no_changes")}
                                text={t("video_page.no_changes_for_this_video")}
                            />
                        }

                        {error && <Status
                            icon={<Icon icon={XCircleIcon} color="red" size="xl"></Icon>}
                            headline={t("video_page.something_went_wrong")}
                            text={t("video_page.something_went_wrong_text")}
                        />}
                    </div>
                    <div className={"overflow-y-scroll max-h-60 " + classes['changes']}>
                        {changes?.map((el, i) => {
                            return <ChangeCard key={i} change={el} hideLink={true} />
                        })}
                    </div>
                </Card>
            </div>
        </WideCard>
    );
}

export default VideoInfo;