import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Topic} from "../../../models/Topic";
import {useTranslation} from "react-i18next";
import TextArea from "../../../ui/TextArea";
import {Button, Callout, Icon, ProgressCircle} from "@tremor/react";
import {ChannelFindResponse} from "../../../models/ChannelFindResponse";
import {createChannels, findChannels} from "../http";
import Status from "../../../ui/Status";
import {
    ArchiveBoxXMarkIcon,
    CircleStackIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon, NoSymbolIcon,
    TrashIcon
} from "@heroicons/react/20/solid";
import {Link} from 'react-router-dom';
import Modal from "../../../ui/Modal";
import DeleteChannel from "./DeleteChannel";
import {Channel} from "../../../models/Channel";
import Card from "../../../ui/Card";
import {useTopicsStore} from "../store";
import * as Sentry from "@sentry/react";
import {Plan} from "../../../models/Plan";
import {User} from "../../../models/User";
import {SubscribeModal} from "../../subscription";
import {useModalStore} from "../../../store/modal";
import RecommendedChannels from "./RecommendedChannels";
import useInterval from "../../../hooks/useInterval";

type EditTopicProps = {
    topic: Topic
}

function EditTopic({topic}: EditTopicProps) {
    const {t} = useTranslation();

    const {topics, addTopicChannels, updateTopic} = useTopicsStore();
    const {setSubscribeModalOpen} = useModalStore();

    const [channels, setChannels] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [response, setResponse] = useState<ChannelFindResponse>();

    const [loadingCircle, setLoadingCircle] = useState<boolean>(false);
    const [loadingCirclePercent, setLoadingCirclePercent] = useState<number>(0);


    const [channelToDelete, setChannelToDelete] = useState<Channel>();
    const [modalShown, setModalShown] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [tooManyChannels, setTooManyChannels] = useState(false);

    const currentTopic = useMemo(() => {
        return topics.find((el) => el.id === topic.id);
    }, [topic, topics])

    function handleModalClose() {
        setModalShown(false);
        setChannelToDelete(undefined);
    }

    useInterval(
        //@ts-ignore
        (loadingCirclePercent, setLoadingCirclePercent) => {
            if (loadingCirclePercent + 0.6 < 100) {
                setLoadingCirclePercent(loadingCirclePercent + 0.6);
            } else {
                setLoadingCirclePercent(100);
                setLoadingCircle(false);
            }
        },
        loadingCircle ? 1000 : null,
        60
    );

    const incrementCircleCallback = useCallback(() => {
        if (loadingCirclePercent + 0.6 < 100) {
            setLoadingCirclePercent(loadingCirclePercent + 0.6);
        } else {
            setLoadingCirclePercent(100);
            setLoadingCircle(false);
        }
    }, [loadingCirclePercent]);

    useEffect(() => {
        if (loadingCircle) {
            const timerFunc = async () => {
                for (let i = 0; i < 100; i++) {
                    if (i + 1 < 100) {
                        setLoadingCirclePercent(i + 1);
                    } else {
                        setLoadingCirclePercent(100);
                        setLoadingCircle(false);
                    }

                    await new Promise(resolve => setTimeout(resolve, 620));
                }

            }

            timerFunc();
        }
    }, [loadingCircle]);

    // const youtubeChannelRegex = /^((http|https):\/\/)?(www\.)?youtube\.com\/((channel|c|user|@)?\/?)?[a-zA-Z0-9.$#&_\-]+(\/(|videos|featured|streams|shorts|playlists|community|channels|about))?$/;
    // const youtubeChannelRegex = /^((http|https):\/\/)?(www\.)?youtube\.com\/(?:(channel|c|user)\/[\w\-.]+|@[\p{L}\p{N}.$#&_\-]+)(\/(|videos|featured|streams|shorts|playlists|community|channels|about))?$/u;
    const youtubeChannelRegex = /^((http|https):\/\/)?(www\.)?youtube\.com\/(?:(channel|c|user)\/[\w\-.%]+|@(?:[\p{L}\p{N}.$#&_\-%]|%[0-9A-Fa-f]{2})+)(\/(|videos|featured|streams|shorts|playlists|community|channels|about))?$/u;

    let regex = new RegExp(youtubeChannelRegex);

    async function handleAddChannels() {
        if (channels.some((el) => !!el && !el.trim().match(regex))) {
            let links = "\n" + channels.filter((el) => !!el && !el.trim().match(regex)).join("\n");
            setValidationError(t("main_page.errors.wrong_links", {links: links }).toString());
            return;
        } else {
            setValidationError('');
        }

        let filteredChannels = channels.filter((el) => !!el && el.trim().match(regex)).map((el) => el.trim());
        setChannels(filteredChannels);

        if (filteredChannels.length < 1) {
            setValidationError(t("main_page.errors.empty_channels").toString());
            return;
        } else {
            setValidationError('');
        }

        let user = JSON.parse(localStorage.getItem("user")!) as User;

        if (user.role === 0) {
            let planChannelsCount = (JSON.parse(localStorage.getItem("plan")!) as Plan)!.channel_count;
            let channelsUsed = parseInt(localStorage.getItem("channels_used")!);

            if (planChannelsCount - channelsUsed < filteredChannels.length) {
                setTooManyChannels(true);
                return;
            } else {
                setTooManyChannels(false);
            }
        }

        setLoading(true);

        const addChannels = async () => {
            const res = await findChannels(filteredChannels);
            await createChannels(filteredChannels, topic.id);
            setLoadingCircle(true);
            setResponse(res.data);
            setLoading(false);
            addTopicChannels(topic.id, res.data.added_count);
            let channelsUsed = parseInt(localStorage.getItem("channels_used")!);
            localStorage.setItem("channels_used", (channelsUsed + res.data.added_count).toString());
            localStorage.removeItem("new");
        }

        addChannels().catch((e) => {
            if (!!e.response && e.response.status === 402) {
                setSubscribeModalOpen(true, {replacementLimit: true, limit: false, trial: false});
                setLoading(false);
                return;
            }
            Sentry.captureException(e);
            console.log(e);
            setError(t("errors.something_went_wrong").toString());
        });
    }
    function handleChannelsChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setChannels(event.target.value.split('\n'))
    }

    function handleTryAgain() {
        setResponse(undefined);
        setError('');
        setLoading(false);
        setChannels([]);
    }

    return (
        <Card style={{boxShadow: 'none', border: 'none', paddingTop: 0}}>
            <>
                {(!response && !error) &&
                    <Callout
                        title={t("topic_page.add_channels_callout")}
                        color="green"
                        icon={InformationCircleIcon}
                        className="mb-8"
                    >
                        {t("topic_page.add_channels_callout_text")}
                    </Callout>}
            </>
            <>
                {tooManyChannels &&
                    <Callout
                        title={t("topic_page.too_many_channels")}
                        color="yellow"
                        icon={InformationCircleIcon}
                        className="mb-8"
                    >
                        {t("topic_page.too_many_channels_text")}
                        &nbsp;
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={() => {
                                setSubscriptionModal(true);
                            }}
                        >
                            {t("topic_page.change_plan")}
                        </span>
                    </Callout>}
            </>
            <div className="max-w-lg mx-auto flex flex-col items-center">
                {!response && error
                    ? <>
                        <Status
                            headline={t("main_page.error").toString()}
                            text={error}
                            icon={<Icon icon={ExclamationTriangleIcon} color="slate" size="xl" />}
                        />
                        <Button onClick={handleTryAgain} className="mt-4">{t("main_page.try_again")}</Button>
                    </>
                    : <></>
                }
                {
                    !response && !error
                    ? <>
                            <TextArea
                                placeholder={t("main_page.add_channels_placeholder").toString()}
                                className="h-36"
                                error={validationError != ''}
                                errorMessage={validationError}
                                onChange={handleChannelsChange}
                            />
                            <Button className="mt-4" onClick={handleAddChannels} loading={loading}>{t("main_page.add")}</Button>
                    </>
                    : (response && !error && !loadingCircle) ? <>
                        <Status
                            icon={<Icon icon={CircleStackIcon} size="xl" color={response?.total == response?.added_count ? "green" : "yellow"} />}
                            headline={t("main_page.data_collection")}
                            text={t("main_page.add_channels_success", {count: response?.added_count, total: response?.total})}
                        />
                        {response?.total != response?.added_count && <div className="text-sm text-slate-400 text-center">
                            {response?.invalid_urls && response?.invalid_urls.map((el, i) => {
                                return <div className="mt-2" key={i}>{el} - {t("main_page.add_channels_invalid")}</div>
                            })}
                            {response?.duplicate_urls && response?.duplicate_urls.map((el, i) => {
                                return <div className="mt-2" key={i}>{el} - {t("main_page.add_channels_duplicate")}</div>
                            })}
                        </div>}
                            <div
                                className="text-blue-500 text-sm cursor-pointer mt-2"
                                onClick={() => {
                                    setError('');
                                    setResponse(undefined);
                                    setChannels([]);
                                }}
                            >
                                {t("main_page.return_to_add_channels")}
                            </div>
                        {/*<Button className="mt-4" onClick={() => {closeModal();}}>{t("main_page.close")}</Button>*/}
                    </> : (response && !error && loadingCircle) ? <ProgressCircle color="blue" size="md" value={loadingCirclePercent}>
                            <span className="text-xs font-medium text-slate-700">{loadingCirclePercent}%</span>
                        </ProgressCircle> : <></>
                }
            </div>
            <>
                {(currentTopic?.channels && currentTopic?.channels.length !== 0 && !response && !error) &&
                    <div className="mt-8 flex flex-wrap gap-y-12 gap-x-5">
                        {currentTopic?.channels!.map((channel, i) => {
                            if (!channel.title) {
                                return <></>;
                            }

                            return <div key={i} className="block w-60 shrink-0 flex flex-col items-center text-center group">
                                <div className="rounded-full w-32 relative cursor-pointer" onClick={(event) => {
                                    event.preventDefault();
                                    setChannelToDelete(channel);
                                    setModalShown(true);
                                }}>
                                    <div className="absolute bg w-full h-full rounded-full bg-gray-950/50 flex flex-col items-center justify-center opacity-0 transition-all group-hover:opacity-100">
                                        <Icon icon={TrashIcon} size="xl" className="text-white" />
                                        <div className="text-xs text-white text-center">{t('topic_page.delete')}</div>
                                    </div>
                                    {(channel.deleted || channel.blocked)
                                        ? <div className="w-32 h-32 rounded-full bg-red-100 flex justify-center items-center">
                                            <Icon icon={channel.blocked ? NoSymbolIcon : ArchiveBoxXMarkIcon} color="red" className="text-red-700" size="xl" />
                                        </div>
                                        : <img src={channel.avatar} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src="/person.svg";
                                        }} alt="avatar" className="block w-32 rounded-full"/>
                                    }
                                </div>
                                <Link to={"/channel/" + channel.id} className="text-sm mt-2 text-slate-700 cursor-pointer">{channel.title}</Link>
                                <div className="text-sm mt-1 text-slate-500">{channel.subscriptions + " " + t("channel_page.subscribers")}</div>
                            </div>;
                        })}
                    </div>
                }
            </>
            <>

                {(response && !error && !!currentTopic?.recommended_channels  && currentTopic?.recommended_channels.length) ? <div className="mt-8"><RecommendedChannels channels={currentTopic.recommended_channels} topic={topic} setTopic={updateTopic} hidePreview={true}/></div> : <></>}
            </>
            <Modal shown={modalShown} close={handleModalClose} widthClass="sm:max-w-3xl">
                <div className="text-center text-lg font-bold mb-8">{channelToDelete?.title}: {t("main_page.delete_channel")}</div>
                {
                    channelToDelete ? <DeleteChannel channel={channelToDelete} topic={topic!} close={handleModalClose} /> : <></>
                }
            </Modal>
            <SubscribeModal shown={subscriptionModal} close={() => {setSubscriptionModal(false)}}/>
        </Card>
    );
}

export default EditTopic;