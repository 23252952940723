import React, {useEffect, useMemo, useState} from 'react';
import {Topic} from "../../../models/Topic";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {Badge, Callout, Icon} from "@tremor/react";
import {ClockIcon, InformationCircleIcon, LockClosedIcon} from "@heroicons/react/20/solid";
import SnapshotCard from "../../../components/SnapshotCard";
import {formatNumber} from "../../../utils/numbers";
import Modal from "../../../ui/Modal";
import VideosTable from "../../../components/VideosTable";
import Card from "../../../ui/Card";
import Status from "../../../ui/Status";
import {Plan} from "../../../models/Plan";
import {SubscribeModal} from "../../subscription";
import {useModalStore} from "../../../store/modal";
import {getTelegramToken} from "../../settings";
import {useShortsStore} from "../../../store/shorts";
import {User} from "../../../models/User";

type RecommendedVideosProps = {
    topic: Topic
}

function RecommendedVideos({topic}: RecommendedVideosProps) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);
    const [telegramLinked, setTelegramLinked] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    useEffect(() => {
        const fetchToken = async () => {
            const res = await getTelegramToken();
            if (!!res && !!res.data && !!res.data.token) {
                setTelegramLinked(true);
            }
        }

        fetchToken().catch((e) => { });
    }, [])

    const recentTopic = useMemo(() => {
        let currentDate = new Date();
        let topicDate = new Date(topic.created_at);

        return ((currentDate.getTime() - topicDate.getTime()) / 1000 / 60 < 60);
    }, [topic]);

    const { shorts } = useShortsStore();

    const showLocks = useMemo(() => {
        if (!localStorage.getItem("plan")) return true;
        const plan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        const user = JSON.parse(localStorage.getItem("user")!) as User;
        if (user.role !== 0) return false;
        if (!plan) return true;
        return (plan.is_trial || (plan.channel_count == 25 && shorts));
    }, [shorts]);

    const snapshotsList = useMemo(() => {
        if (!topic.recommended_snapshots || topic.recommended_snapshots.length === 0) {
            return <Status
                icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                headline={t("video_page.no_data")}
                text={t("topic_page.no_recommended_videos")}
            />;
        }

        return topic.recommended_snapshots.map((snap, i) => {
            return <SnapshotCard
                key={i}
                snap={snap}
                blocked={showLocks && i !== 0}
                onClick={(showLocks && i !== 0) ? () => {setSubscribeModalOpen(true, {limit: false, trial: true})} : () => {setModalShown(true)}}
                bottomText={
                    !showLocks || i === 0 ? t("video_page.stats.table.views") + " " + formatNumber(snap.views)
                        : <div className="flex items-center">{t("video_page.stats.table.views")} <Icon className="p-0 ml-1" icon={LockClosedIcon} color="gray" size="xs"/></div>
                }
            />;
        });
    }, [topic, showLocks]);

    return (
        <WideCard className="mt-8 mb-8">
            <div className="text-center text-lg mb-8 font-bold">{t("topic_page.recommended_videos")}</div>
            <Card>
                <div className="flex justify-center items-start">
                    {!telegramLinked && <Badge size="sm" color="green" className="mb-8"><div className="blue-links" dangerouslySetInnerHTML={{__html: t("topic_page.recommended_videos_bot")!}}></div></Badge>}
                </div>
                {((!topic.recommended_snapshots || topic.recommended_snapshots?.length <= 3) && !recentTopic) ? <Callout
                    title={t("topic_page.recommended_videos_callout_few")}
                    color="yellow"
                    icon={InformationCircleIcon}
                    className='mb-8'
                >
                    {t("topic_page.recommended_videos_callout_few_text")}
                </Callout> : <></>}
                <div className="flex gap-4">
                    {snapshotsList}
                </div>

                {topic.recommended_snapshots && topic.recommended_snapshots.length ?
                    <div className="text-blue-500 text-sm text-center mt-8 cursor-pointer" onClick={() => {setModalShown(true)}}>
                        {t("topic_page.view_all_list")}
                    </div>
                    : <></>
                }

                <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                    <div className="text-center text-lg mb-8 font-bold">
                        {t("topic_page.recommended_videos")}
                    </div>
                    <Callout
                        title={t("topic_page.recommended_videos_callout")}
                        color="green"
                        icon={InformationCircleIcon}
                    >
                        {t("topic_page.recommended_videos_callout_text")}
                    </Callout>
                    <>
                        {topic.recommended_snapshots && topic.recommended_snapshots.length &&
                            <VideosTable
                                blocked={showLocks}
                                onClick={showLocks ? () => {setSubscribeModalOpen(true, {limit: false, trial: true})} : undefined}
                                snapshots={topic.recommended_snapshots}
                                anomalies={topic.anomaly_snapshot_ids}
                                tableColumns={["preview", "title", "serial_number", "views", "vs", "erv"]}
                            />
                        }
                    </>
                </Modal>
            </Card>
        </WideCard>
    );
}

export default RecommendedVideos;