import {AxiosResponse} from "axios";
import $api from "../../../http";
import {Subscription} from "../../../models/Subscription";
import {SubscriptionResponse} from "../../../models/SubscriptionResponse";
import {Plan} from "../../../models/Plan";
import {Topic} from "../../../models/Topic";

export function subscriptionInfo(): Promise<AxiosResponse<SubscriptionResponse>> {
    return $api.get<SubscriptionResponse>('subscription');
}

export function availablePlans(): Promise<AxiosResponse<Plan[]>> {
    return $api.get<Plan[]>('subscription/plans');
}

export function getPaymentLink(planId: string, stripe: boolean, useBonuses: boolean): Promise<AxiosResponse<string>> {
    const params = new URLSearchParams();

    if (stripe) params.append('method', 'stripe');
    if (useBonuses) params.append('bonuses', 'true');

    return $api.get<string>(`subscription/pay/${planId}?${params.toString()}`);
}

export function downgradeTopics(): Promise<AxiosResponse<Topic[]>> {
    return $api.get<Topic[]>(`subscription/downgrade/topic/`)
}

export function downgradeDeleteChannel(topicId: string, channelId: string): Promise<AxiosResponse> {
    return $api.delete(`subscription/downgrade/topic/${topicId}/channel/${channelId}`)
}