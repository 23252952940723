import React, {useEffect, useMemo} from 'react';
import {formatNumber} from "../../../utils/numbers";
import {useTranslation} from "react-i18next";
import {Snapshot} from "../../../models/Snapshot";

type ChartTooltipProps = {
    active: boolean | undefined;
    payload: any;
    label: string;
    categoryColors: Map<string, any>;
}
function ChartTooltip({payload, label, categoryColors}: ChartTooltipProps) {
    const {t} = useTranslation();

    const currentSerialNumber = useMemo(() => {
        if (!!payload && !!payload.length) return payload[0].payload.serial_number;
        return 0;
    }, [payload]);

    return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top border-none"
             style={{outline: "none"}}>
            <div className="bg-white text-sm rounded-md shadow-lg border border-1 border-slate-100">
                <div className="border-gray-200 px-4 py-2 border-b">
                    <p className="text-gray-700 font-medium">{
                        currentSerialNumber != 0 ? ((currentSerialNumber % 24 === 0 && currentSerialNumber != 0
                                        ? <>{currentSerialNumber / 24} {t("video_page.stats.day_observation")}</>
                                        : <>{currentSerialNumber} {t("video_page.stats.hour_observation")}</>
                                )
                        ) : <>{t("video_page.stats.start")}</>
                    } ({label})</p>
                </div>
                <div className="px-4 py-2 space-y-1">
                    {payload?.map(({ value, name }: { value: number; name: string; }, idx: number) => (
                        <div className="flex items-center justify-between space-x-8">
                            <div className="flex items-center space-x-2">
                                <span className={"shrink-0 border-white h-3 w-3 rounded-full border-2 shadow bg-" + categoryColors.get(name) + "-500"}></span>
                                <p className="font-medium tabular-nums text-right whitespace-nowrap text-gray-700">
                                    {formatNumber(value, (name === "erv" || name === "vs"))}
                                </p>
                            </div>
                            <p className="text-right whitespace-nowrap text-gray-500 font-normal">{t("video_page.stats.table." + name)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ChartTooltip;