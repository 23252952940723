import React from 'react';
import classes from "./SearchResultVideo.module.scss";
import {SearchHitVideo} from "../../../models/SearchHit";
import {Link} from "react-router-dom";

interface SearchResultVideoProps {
    hit: SearchHitVideo
}
// TODO get backend url from env
function SearchResultVideo({hit}: SearchResultVideoProps) {
    function getHighlightedTags(tags: string) {
        const tagsList = tags.split(',');
        return tagsList.filter((el) => el.includes("<strong>"));
    }

    return (
        <Link to={"/video/" + hit.id}>
            <div className={classes['search-hit']}>
                <img src={import.meta.env.VITE_BACKEND_URL + hit.preview} alt="preview" className={classes['search-hit__preview']}/>
                <div className={classes['search-hit__info']}>
                    <div className={classes['search-hit__title']} dangerouslySetInnerHTML={{__html: hit._formatted.title}}></div>
                    <div className={classes['search-hit-tags']}>
                        {getHighlightedTags(hit._formatted.tags).map((el) => {
                            return <div className={" " + classes['search-hit-tags__el']} dangerouslySetInnerHTML={{__html: el}}></div>
                        })}
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default SearchResultVideo;