import {confirmEmail} from "../modules/verification";

export default async function verifyLoader() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token) {
        return 400;
    }

    try {
        const res = await confirmEmail(token);
        return res.status;
    } catch (e) {
        return 401;
    }
}