import React from 'react';
import {Button, Icon} from "@tremor/react";
import {CheckCircleIcon, PlusIcon} from "@heroicons/react/20/solid";

type StatusProps = {
    className?: string
    icon: JSX.Element | string | JSX.Element[]
    headline: string
    text: string
}

function Status({icon, headline, text, className}: StatusProps) {
    return (
        <div className={"text-center text-sm max-w-xs mx-auto whitespace-pre-line " + className}>
            {icon}
            <div className="text-md font-bold text-slate-700">{headline}</div>
            <div className="text-slate-500">{text}</div>
        </div>
    );
}

export default Status;