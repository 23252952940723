import {AxiosResponse} from "axios";
import $api from "../../../http";
import {TelegramToken} from "../../../models/TelegramToken";

export function changePassword(old_password: string, new_password: string): Promise<AxiosResponse> {
    return $api.put('user/password', {
        old_password: old_password,
        new_password: new_password,
    })
}

export function linkTelegramBot(token: string): Promise<AxiosResponse> {
    return $api.post('user/telegram/link', {
        token: token,
    })
}

export function getTelegramToken(): Promise<AxiosResponse<TelegramToken>> {
    return $api.get<TelegramToken>('user/telegram')
}