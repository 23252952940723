import React from 'react';
import Search from "./Search";
import {Link} from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import logo from "/logo.svg";
import {User} from "../../../models/User";

function Header() {
    return (
        <header className="py-5 border-b border-gray-200 bg-white">
            <div className="container">
                <div className="grid grid-cols-14 gap-8 items-center">
                    <div className="col-span-4">
                        <div className="text-4xl font-bold">
                            <Link to="/">
                                <img src={logo} alt="logo" className="block w-16"/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-span-6">
                        {((!!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).role) || (!localStorage.getItem("new") && !!localStorage.getItem("plan") && JSON.parse(localStorage.getItem("plan")!) && !!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) && (JSON.parse(localStorage.getItem("user")!) as User).email_verified)) &&
                            <Search/>
                        }
                    </div>
                    <div className="col-span-4 flex justify-end">
                        <HeaderMenu/>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;