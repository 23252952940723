import React, {useEffect, useMemo, useState} from 'react';
import {useLoaderData} from "react-router-dom";
import {Channel} from "../models/Channel";
import {AddChannelToTopic, ChannelInfo, ChannelRating, ChannelVideos, LatestVideo} from "../modules/channel";
import PeriodVideoRating from "../components/PeriodVideoRating";
import {channelVideosRating} from "../http/rating";
import WideCard from "../ui/WideCard";
import {useTranslation} from "react-i18next";
import Changes from "../components/Changes";
import Breadcrumbs from "../components/Breadcrumbs";
import ToggleShorts from "../components/ToggleShorts";
import {useShortsStore} from "../store/shorts";
import {topicInfo} from "../modules/topic/http";
import {channelInfo} from "../modules/channel/http";

function ChannelPage() {
    const {t} = useTranslation();
    const initialChannel: Channel = useLoaderData() as Channel;

    const [channel, setChannel] = useState(initialChannel);

    const {shorts} = useShortsStore();

    if (!!channel.topics) {
        return <AddChannelToTopic channel={channel} />;
    }

    const emptyChannel = useMemo(() => {
        return !channel.videos || channel.videos?.length === 0;
    }, [channel]);

    useEffect(() => {
        channelInfo(channel.id, shorts).then((res) => {
            setChannel(res.data);
        });
    }, [shorts]);

    return (
        <div className="pt-8 pb-8" key={channel.id}>
            <Breadcrumbs pages={[
                {
                    title: `${t("breadcrumbs.topic")}: ${channel.breadcrumbs![0].title}`,
                    link: `/topic/${channel.breadcrumbs![0].id}`
                },
                {
                    title: `${t("breadcrumbs.channel")}: ${channel.title}`,
                    link: ""
                }
            ]} />
            <ToggleShorts/>
            <div className="mb-8"></div>
            <ChannelInfo channel={channel} />
            {!emptyChannel && <ChannelRating channel={channel} shorts={shorts} />}
            {(channel.videos && channel.videos.length != 0) && <LatestVideo snapshot={channel.videos![0].latest_snap}/>}
            {!emptyChannel && <WideCard className="mt-8 mb-8 overflow-visible">
                <div className="text-lg font-bold text-center mb-8">{t("channel_page.video_rating.heading")}</div>
                <PeriodVideoRating channel={channel} shorts={shorts} requestRating={channelVideosRating} />
            </WideCard>}
            {(!emptyChannel && (!channel.blocked && !channel.deleted)) && <WideCard className="mt-8 mb-8">
                <div className="text-lg font-bold text-center mb-8">{t("channel_page.changes.heading")}</div>
                <Changes channel={channel} shorts={shorts} />
            </WideCard>}
            {(!channel.blocked && !channel.deleted) && <ChannelVideos channel={channel} />}
        </div>
    );
}

export default ChannelPage;