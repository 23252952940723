import React, {useEffect, useState} from 'react';
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {getPlanFactGraph} from "../http";
import * as Sentry from "@sentry/react";
import {PlanFactGraphResponse} from "../../../models/PlanFactGraphResponse";
import {Color, DateRangePicker, DateRangePickerItem, DateRangePickerValue} from "@tremor/react";
import {dateObjectToDateString, getFirstDayOfMonth, getLastDayOfMonth} from "../../../utils/date";
import {ru} from "date-fns/locale";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatNumber, formatTick} from "../../../utils/numbers";
import {BaseColors, hexColors} from "../../../constants";
function PlanFactGraph() {
    const {t} = useTranslation();

    const [data, setData] = useState<PlanFactGraphResponse[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [dateValue, setDateValue] = useState<DateRangePickerValue>({
        from: getFirstDayOfMonth((new Date()).getFullYear(), (new Date()).getMonth()),
        to: getLastDayOfMonth((new Date()).getFullYear(), (new Date()).getMonth()),
    });

    const categoryColors: Map<string, Color> = new Map([
        ["actual_amount", BaseColors.Blue],
        ["expected_amount", BaseColors.Gray],
    ]);

    console.log(categoryColors)

    const fetchData = async () => {
        let startDate = dateObjectToDateString(dateValue.from!);
        let endDate = dateObjectToDateString(dateValue.to!);
        const result = await getPlanFactGraph(startDate, endDate);
        setData(result.data.map((el) => {
            return {...el, actual_amount: el.actual_amount == 0 ? null : el.actual_amount};
        }));
        setLoading(false);
    }

    useEffect(() => {
        if (!dateValue.from || !dateValue.to) return;
        setLoading(true);
        fetchData().catch((e) => {
            setLoading(false);
            Sentry.captureException(e);
        })
    }, [dateValue]);

    return (
        <Card className={"mb-8 loadable overflow-visible" + (loading ? " loadable_active" : "")}>
            <div className="text-lg font-bold text-center mb-8">{t("stats_page.plan_fact")}</div>
            <div className="max-w-lg mx-auto mb-8">
                <DateRangePicker
                    className="max-w-full w-full"
                    onValueChange={setDateValue}
                    locale={ru}
                    value={dateValue}
                    placeholder={t("subscription_manager_page.choose_dates").toString()}
                    selectPlaceholder={t("subscription_manager_page.choose").toString()}
                >
                    <DateRangePickerItem
                        key="last_month"
                        value="last_month"
                        from={getFirstDayOfMonth((new Date()).getFullYear(), (new Date()).getMonth() == 0 ? 11 : (new Date()).getMonth() - 1)}
                        to={getLastDayOfMonth((new Date()).getFullYear(),(new Date()).getMonth() == 0 ? 11 : (new Date()).getMonth() - 1)}
                    >
                        Предыдущий месяц
                    </DateRangePickerItem>
                    <DateRangePickerItem
                        key="month"
                        value="month"
                        from={getFirstDayOfMonth((new Date()).getFullYear(), (new Date()).getMonth())}
                        to={getLastDayOfMonth((new Date()).getFullYear(), (new Date()).getMonth())}
                    >
                        Текущий месяц
                    </DateRangePickerItem>
                    <DateRangePickerItem
                        key="next_month"
                        value="next_month"
                        from={getFirstDayOfMonth((new Date()).getFullYear(), ((new Date()).getMonth() + 1) % 12)}
                        to={getLastDayOfMonth((new Date()).getFullYear(), ((new Date()).getMonth() + 1) % 12)}
                    >
                        Следующий месяц
                    </DateRangePickerItem>
                </DateRangePicker>
            </div>
            <>
            {data && <div className="h-96 w-full">
                <ResponsiveContainer height="100%" width="100%">
                    <LineChart data={data!} margin={{bottom: 20, left: 10}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="day"
                            interval="preserveStartEnd"
                            tick={{ transform: "translate(0, 6)" }}
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter; Helvetica",
                            }}
                            tickLine={false}
                            axisLine={false}
                            padding={{ left: 10, right: 10 }}
                            minTickGap={5}
                            tickFormatter={(value, index) => {
                                return dateObjectToDateString(new Date(value));
                            }}
                        />

                        <YAxis
                            width={62}
                            tick={{ transform: "translate(-3, 0)" }}
                            axisLine={false}
                            tickLine={false}
                            type="number"
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter; Helvetica",
                            }}
                            tickFormatter={formatTick}
                        />

                        <Line
                            key={"actual_amount"}
                            name={"actual_amount"}
                            type="linear"
                            dataKey={"actual_amount"}
                            stroke={hexColors[categoryColors.get("actual_amount") ?? BaseColors.Gray]}
                            strokeWidth={2}
                            dot={true}
                            isAnimationActive={true}
                            connectNulls={false}
                        />

                        <Line
                            key={"expected_amount"}
                            name={"expected_amount"}
                            type="linear"
                            dataKey={"expected_amount"}
                            stroke={hexColors[categoryColors.get("expected_amount") ?? BaseColors.Gray]}
                            strokeWidth={2}
                            dot={false}
                            isAnimationActive={true}
                            connectNulls={true}
                        />

                        <Tooltip
                            wrapperStyle={{outline: "none"}}
                            content={({ active, payload, label }) => (
                                <ChartTooltip
                                    active={active}
                                    payload={payload}
                                    label={label}
                                    categoryColors={categoryColors}
                                />
                            )}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>}
            </>
        </Card>
    );
}

type ChartTooltipProps = {
    active: boolean | undefined;
    payload: any;
    label: string;
    categoryColors: Map<string, any>;
}
function ChartTooltip({payload, label, categoryColors}: ChartTooltipProps) {
    const {t} = useTranslation();
    return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top border-none"
             style={{outline: "none"}}>
            <div className="bg-white text-sm rounded-md shadow-lg border border-1 border-slate-100">
                <div className="border-gray-200 px-4 py-2 border-b">
                    <p className="text-gray-700 font-medium">{dateObjectToDateString(new Date(label))}</p>
                </div>
                <div className="px-4 py-2 space-y-1">
                    {payload?.map(({ value, name }: { value: number; name: string; }, idx: number) => (
                        <div className="flex items-center justify-between space-x-8">
                            <div className="flex items-center space-x-2">
                                <span className={"shrink-0 border-white h-3 w-3 rounded-full border-2 shadow bg-" + categoryColors.get(name) + "-500"}></span>
                                <p className="font-medium tabular-nums text-right whitespace-nowrap text-gray-700">
                                    {t("stats_page." + name)} {formatNumber(value)}₽
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PlanFactGraph;