import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Video} from "../../../models/Video";
import WideCard from "../../../ui/WideCard";
import {channelVideosRating, overallVideosRating} from "../../../http/rating";
import {ChannelVideosRating} from "../../../models/ChannelVideosRating";
import VideoRatingCard from "./VideoRatingCard";
import {useTranslation} from "react-i18next";
import {Icon} from "@tremor/react";
import {ClockIcon, XCircleIcon} from "@heroicons/react/20/solid";
import Status from "../../../ui/Status";
import {Plan} from "../../../models/Plan";
import {useShortsStore} from "../../../store/shorts";
import {User} from "../../../models/User";

type VideoRatingProps = {
    video: Video
    overall: boolean
}

function VideoRating({video, overall}: VideoRatingProps) {
    const {t} = useTranslation();

    const shouldFetch = useRef(true);

    const [error, setError] = useState(false);
    const [rating, setRating] = useState<ChannelVideosRating>();

    const { shorts } = useShortsStore();

    const showLocks = useMemo(() => {
        if (!localStorage.getItem("plan")) return true;
        const plan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        const user = JSON.parse(localStorage.getItem("user")!) as User;
        if (user.role !== 0) return false;
        if (!plan) return true;
        return (plan.is_trial || (plan.channel_count == 25 && shorts));
    }, [shorts]);

    useEffect(() => {
        if (!video.latest_snap) return;

        let fetchData;

        if (overall) {
            fetchData = async () => {
                if (!video.topic_id) throw new Error("No topic id");
                const results  = await overallVideosRating(video.topic_id!, video.latest_snap!.serial_number, video.shorts);
                setRating(results.data);
            };
        } else {
            fetchData = async () => {
                const results  = await channelVideosRating(video.channel_id, video.latest_snap!.serial_number, video.shorts, "video");
                setRating(results.data);
            };
        }

        if (shouldFetch.current) {
            shouldFetch.current = false;
            fetchData().catch((e) => {
                setError(true);
            });
        }
    }, []);

    const headingText = useMemo(() => {
        if (!video.latest_snap) {
            return "";
        }

        if (video.latest_snap?.serial_number === 0) {
            if (overall) return "video_page.rating.headings.start_overall";
            return "video_page.rating.headings.start";
        }

        if (video.latest_snap?.serial_number % 24 === 0 && video.latest_snap?.serial_number != 0) {
            if (overall) return "video_page.rating.headings.day_overall";
            return "video_page.rating.headings.day";
        }

        if (overall) return "video_page.rating.headings.hour_overall";

        return "video_page.rating.headings.hour";
    }, [video]);

    const serialNumber = useMemo(() => {
        if (!video.latest_snap) return 0;

        return video.latest_snap?.serial_number % 24 === 0 ? video.latest_snap?.serial_number / 24 : video.latest_snap?.serial_number;
    }, [video]);

    return (
        <WideCard className="mt-8 overflow-visible">
            <div className="text-center text-lg mb-8 font-bold">{t(headingText, {serial: serialNumber})}</div>
            {(rating && rating.views && rating.views.length < 3 && !error || video.latest_snap?.serial_number === 0)
                ? <Status
                    icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                    text={t("video_page.need_more_data")}
                    headline={t("video_page.no_data")}/>
                : (error
                        ? <Status
                            icon={<Icon icon={XCircleIcon} color="red" size="xl"></Icon>}
                            text={t("video_page.something_went_wrong_text")}
                            headline={t("video_page.something_went_wrong")}/>
                        : <div className="grid grid-cols-2 gap-8">
                            <VideoRatingCard blocked={showLocks && overall} overall={overall} rating={rating ? rating.views : null} video={video} field="views" />
                            <VideoRatingCard blocked={showLocks && overall} overall={overall} rating={rating ? rating.vs : null} video={video} field="vs" />
                            <VideoRatingCard blocked={showLocks && overall} overall={overall} rating={rating ? rating.erv : null} video={video} field="erv" />
                            {/*{video.latest_snap!['score'] ? <VideoRatingCard blocked={isPlanTrial && overall} overall={overall} rating={rating ? rating.score : null} video={video} field="score" /> : <></>}*/}
                        </div>
                    )
            }
        </WideCard>
    );
}

export default VideoRating;