import React, {MouseEventHandler, useState} from 'react';
import {Topic} from "../../../models/Topic";
import {formatNumber} from "../../../utils/numbers";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {Icon} from "@tremor/react";
import {LockClosedIcon, QuestionMarkCircleIcon} from "@heroicons/react/20/solid";
import {secondsToTimeString} from "../../../utils/date";

type Field = "avg_views" | "sum_views" | "median_vs" | "median_erv" | "median_score" | "current_videos" | "median_subscriptions" | "median_length" | "avg_views_shorts" | "sum_views_shorts" | "median_vs_shorts" | "median_erv_shorts" | "median_score_shorts" | "current_videos_shorts"  | "median_length_shorts"

type TopicStatsCardProps = {
    topic: Topic
    field: Field
    blocked?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
}

function TopicStatsCard({topic, field, blocked, onClick}: TopicStatsCardProps) {
    const {t} = useTranslation();

    const [tooltipShown, setTooltipShown] = useState(false);
    function defaultOnClick() {}

    return (
        <Card className="overflow-visible">
            <div className="text-slate-500 mb-4 flex items-center">
                {t("topic_page.rating." + field.replace("_shorts", ""))}
                <div className="relative">
                    <Icon
                        icon={QuestionMarkCircleIcon}
                        size="sm"
                        color="slate"
                        className="cursor-pointer"
                        onMouseLeave={() => {setTooltipShown(false)}}
                        onMouseOver={() => {setTooltipShown(true)}}
                    />
                    <div
                        className={"absolute border border-slate-200 text-xs drop-shadow-md text-center transition-all px-4 w-96 py-1 text-sm rounded-md bg-slate-100 -translate-x-1/2 " + (tooltipShown ? "" : "opacity-0 invisible")}
                        style={{left: "50%", bottom: "100%"}}
                    >
                        {t("topic_page.rating.tooltips." + field.replace("_shorts", ""))}
                    </div>
                </div>
            </div>
            <div className="text-4xl font-extrabold" onClick={onClick ?? defaultOnClick}>{
                !blocked ? (field.replace("_shorts", "") === "median_length" ? secondsToTimeString(topic[field]) : formatNumber(field.replace("_shorts", "") === "median_score" ? Math.ceil(topic[field]) : topic[field], field.replace("_shorts", "") == "median_vs" || field.replace("_shorts", "") == "median_erv"))
                    : <Icon className="cursor-pointer" icon={LockClosedIcon} color="gray" size="xl"/>
            }</div>
        </Card>
    );
}

export default TopicStatsCard;