import {createBrowserRouter, redirect} from "react-router-dom";
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import MainPage from "./pages/MainPage";
import AuthLayout from "./layouts/AuthLayout";
import UserLayout from "./layouts/UserLayout";
import ErrorPage from "./pages/ErrorPage";
import {videoLoader} from "./pages/videoPageLoader";
import VideoPage from "./pages/VideoPage";
import ChannelPage from "./pages/ChannelPage";
import {channelLoader} from "./pages/channelPageLoader";
import {topicLoader} from "./pages/topicPageLoader";
import TopicPage from "./pages/TopicPage";
import SettingsPage from "./pages/SettingsPage";
import verifyLoader from "./pages/verifyPageLoader";
import VerifyPage from "./pages/VerifyPage";
import FavouritesPage from "./pages/FavouritesPage";
import ForgotPage from "./pages/ForgotPage";
import ResetPage from "./pages/ResetPage";
import {settingsLoader} from "./pages/settingsPageLoader";
import {mainLoader} from "./pages/mainPageLoader";
import SubscriptionFailPage from "./pages/SubscriptionFailPage";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAgreement from "./pages/UserAgreement";
import ExpiredPage from "./pages/ExpiredPage";
import AdminLayout from "./layouts/AdminLayout";
import AdminSubscriptionManager from "./pages/AdminSubscriptionManager";
import {User} from "./models/User";
import AdminStats from "./pages/AdminStats";

export const router = createBrowserRouter([
    {
        path: "/",
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy/>
            },
            {
                path: "/user-agreement",
                element: <UserAgreement/>
            },
            {
                path: "/verify",
                loader: verifyLoader,
                element: <VerifyPage/>
            },
            {
                path: '/subscription/fail',
                element: <SubscriptionFailPage/>
            },
            {
                path: '/subscription/success',
                element: <SubscriptionSuccessPage/>
            },
            {
                element: <AuthLayout/>,
                loader: unauthenticatedLoader,
                children: [
                    {path: '/auth/login', element: <LoginPage/>},
                    {path: '/auth/register', element: <RegisterPage/>},
                    {path: '/auth/forgot', element: <ForgotPage/>},
                    {path: '/auth/reset', element: <ResetPage/>},
                ]
            },
            {
                element: <UserLayout/>,
                loader: authenticatedLoader,
                children: [
                    {
                        path: '',
                        element: <MainPage/>,
                        loader: mainLoader,
                    },
                    {
                        path: '/settings',
                        element: <SettingsPage/>,
                        loader: settingsLoader,
                    },
                    {
                        path: '/expired',
                        element: <ExpiredPage/>,
                    },
                    {
                        path: '/favourites',
                        element: <FavouritesPage/>,
                    },
                    {
                        path: '/video/:videoId',
                        loader: videoLoader,
                        element: <VideoPage />,
                    },
                    {
                        path: '/channel/:channelId',
                        loader: channelLoader,
                        element: <ChannelPage />
                    },
                    {
                        path: '/topic/:topicId',
                        loader: topicLoader,
                        element: <TopicPage />
                    },
                ]
            },
            {
                element: <AdminLayout/>,
                loader: adminLoader,
                path: '/admin',
                children: [
                    {
                        path: '/admin/manage-subscriptions',
                        element: <AdminSubscriptionManager/>,
                    },
                    {
                        path: '/admin/stats',
                        element: <AdminStats/>,
                    }
                ]
            }
        ]
    }
])

router.subscribe((state) => {
    try {
        // @ts-ignore
        window['ym'](94300532,'hit',window.location.href);
    } catch (e) {}
});

function unauthenticatedLoader() {
    if (localStorage.getItem("access_token")) {
        return redirect("/");
    }

    return null;
}

function authenticatedLoader() {
    if (!localStorage.getItem("access_token")) {
        return redirect("/auth/login");
    }

    return null;
}

function adminLoader() {
    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) && (JSON.parse(localStorage.getItem("user")!) as User).role == 2) {
        return null;
    }

    return redirect("/");
}