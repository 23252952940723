import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from "i18next";
import {useLanguageStore} from "./store/language";
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        // supportedLngs: ['en', 'ru'],
    });
i18n.on("loaded", () => {
    useLanguageStore.getState().setLanguage(i18next.resolvedLanguage as ('ru' | 'en'));
    console.log("Lang: ", i18next.resolvedLanguage);
});

export default i18n;