import {AxiosResponse} from "axios/index";
import {Change} from "../models/Change";
import $api from "./index";

export function channelChanges(channelId: string, page: number, shorts: boolean = false): Promise<AxiosResponse<Change[]>> {
    return $api.get<Change[]>(`changes/channel/${channelId}?page=${page}&shorts=${shorts}`);
}

export function topicChanges(topicId: string, page: number, shorts: boolean = false): Promise<AxiosResponse<Change[]>> {
    return $api.get<Change[]>(`changes/topic/${topicId}?page=${page}&shorts=${shorts}`);
}