import React, {MouseEventHandler, useMemo, useState} from 'react';
import {Button, Icon} from "@tremor/react";
import {useTranslation} from "react-i18next";
import {deleteChannelFromTopic, deleteTopic} from "../http";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import Status from "../../../ui/Status";
import {useTopicsStore} from "../store";
import {Channel} from "../../../models/Channel";
import {Topic} from "../../../models/Topic";
import Card from "../../../ui/Card";

type DeleteChannelProps = {
    topic: Topic
    channel: Channel
    close: MouseEventHandler
}

function DeleteChannel({topic, channel, close}: DeleteChannelProps) {
    const {t} = useTranslation();

    const {deleteTopicChannel} = useTopicsStore();

    const [loading, setLoading] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();

    function handleDeleteTopic() {
        setLoading(true);
        const removeChannel = async () => {
            const res = await deleteChannelFromTopic(topic.id, channel.id);
            setResponseCode(res.status);
            setLoading(false);
            deleteTopicChannel(topic.id, channel.id);
            let channelsUsed = parseInt(localStorage.getItem("channels_used")!);
            localStorage.setItem("channels_used", (channelsUsed - 1).toString());
        }

        removeChannel().catch((e) => {
            setLoading(false);
            setResponseCode(e.response.status);
        });
    }

    const content = useMemo(() => {
        if (!responseCode) {
            return <>
                <img src={channel.avatar} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/person.svg";
                }}  alt="avatar" className="block w-32 rounded-full mx-auto"/>
                <div className="text-center text-sm mt-2 text-slate-700 mb-4">{channel.title}</div>
                <div className="text-center text-sm">{t("main_page.are_you_sure_channel")}</div>
                <div className="mt-8 text-center flex items-center justify-center">
                    <Button onClick={close} className="mr-2">{t("main_page.cancel")}</Button>
                    <Button onClick={handleDeleteTopic} loading={loading} color="red">{t("main_page.yes_delete")}</Button>
                </div>
            </>;
        } else if (responseCode === 200) {
            return <div className="text-center">
                <Status
                    icon={<Icon icon={CheckCircleIcon} size="xl" color="green" />}
                    headline={t("main_page.done")}
                    text={t("main_page.delete_channel_success")}
                />
                <Button onClick={close} className="mt-4">{t("main_page.close")}</Button>
            </div>;
        }

        return <Status
            icon={<Icon icon={XCircleIcon} size="xl" color="red" />}
            headline={t("errors.error")}
            text={t("errors.something_went_wrong")}
        />;
    }, [responseCode, loading, close]);

    return (
        <Card>
            {content}
        </Card>
    );
}

export default DeleteChannel;