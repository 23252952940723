import {LoaderFunctionArgs, redirect} from "react-router-dom";
import {videoInfo} from "../modules/video/http";
import {uuidValid} from "../utils/regexp";

export async function videoLoader({params}: LoaderFunctionArgs) {
    if (!localStorage.getItem("access_token")) {
        return redirect("/auth/login");
    }

    if (!params.videoId || !uuidValid(params.videoId!)) {
        throw new Response("video_not_found", { status: 404 });
    }

    try {
        const video = await videoInfo(params.videoId);
        return video.data
    } catch (e: any) {
        if (e.response.status === 402) return redirect("/");

        if (e.response.status == 403) {
            if (!!e.response && !!e.response.data.channel_id) return redirect("/channel/" + e.response.data.channel_id);
            throw new Response("video_forbidden", { status: 403 });
        } else if (e.response.status == 400) {
            throw new Response("video_not_found", { status: 400 });
        } else {
            throw new Response("video_not_found", { status: 404 });
        }
    }
}
