import {search} from "../http";
import {SearchHitChannel, SearchHitVideo} from "../../../models/SearchHit";
import {SearchResponse} from "../../../models/SearchResponse";
import {AxiosResponse} from "axios";

export interface SearchResult  {
    channels: SearchHitChannel[]
    videos: SearchHitVideo[]
}


export default class SearchService {
    static async search(query: string): Promise<SearchResult> {
        let searchResponse: (AxiosResponse<SearchResponse[]> | null) = null;
        const result:SearchResult = {channels: [], videos: []}

        try {
            searchResponse = await search(query);
        } catch (e) {
            return result
        }

        if (searchResponse!.data[0].indexUid === "channels") {
            result.channels = searchResponse!.data[0].hits as SearchHitChannel[];
            result.videos = searchResponse!.data[1].hits as SearchHitVideo[];
        } else {
            result.videos = searchResponse!.data[0].hits as SearchHitVideo[];
            result.channels = searchResponse!.data[1].hits as SearchHitChannel[];
        }

        return result
    }
}