import { create } from 'zustand'
import i18n from "i18next";

type Language = 'ru' | 'en';

type State = {
    language: Language | undefined
    isRussian: boolean
}

type Action = {
    setLanguage:  (lng: Language) => void
}

export const useLanguageStore = create<State & Action>((set) => ({
    language: 'en',
    isRussian: false,
    setLanguage: (lng: Language) => {
        i18n.changeLanguage(lng).then(r => {
            set(() => ({language: lng, isRussian: lng == 'ru'}));
        });
    },
}))