import React, {useEffect, useState} from 'react';
import Modal from "../../../ui/Modal";
import {useTranslation} from "react-i18next";
import {Button, Callout} from "@tremor/react";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {Topic} from "../../../models/Topic";

function DataNotification({topic}: {topic: Topic}) {
    const {t} = useTranslation();

    const [modalShown, setModalShown] = useState(false);

    useEffect(() => {
        let currentDate = new Date();
        let topicDate = new Date(topic.created_at);

        if(((currentDate.getTime() - topicDate.getTime()) / 1000 / 60 < 15) && !localStorage.getItem(topic.id)){
            setModalShown(true);
        }
    }, [topic]);

    function handleClickOk() {
        setModalShown(false);
        localStorage.setItem(topic.id, "true");
    }

    return (
        <Modal shown={modalShown} close={() => {setModalShown(false)}} widthClass="max-w-4xl flex flex-col items-center">
            <div className="text-center text-lg mb-8 font-bold">
                {t("topic_page.data_notification_heading")}
            </div>
            <Callout
                title={t("topic_page.data_notification_heading")}
                color="green"
                icon={InformationCircleIcon}
            >
                {t("topic_page.data_notification_text")}
            </Callout>
            <Button onClick={handleClickOk} className="mx-auto mt-4">{t("topic_page.got_it")}</Button>
        </Modal>
    );
}

export default DataNotification;