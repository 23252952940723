import {AxiosResponse} from 'axios'
import $api from "../../../http";
import {AuthResponse} from "../../../models/AuthResponse";

export function login(email: string, password: string, code: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('auth/login', {
        email: email,
        password: password,
        two_factor_code: code
    })
}

export function register(email: string, password: string, language: string, partnerID = ""): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('auth/register', {
        email: email,
        password: password,
        partner_id: partnerID,
        language: language,
    })
}

export function requestReset(email: string): Promise<AxiosResponse> {
    return $api.post('auth/password/forgot', {
        email: email,
    })
}

export function resetPassword(password: string, token: string): Promise<AxiosResponse> {
    return $api.post(`auth/password/reset/${token}`, {
        new_password: password,
    }, {validateStatus: null})
}

export function resendEmailConfirmation(): Promise<AxiosResponse> {
    return $api.post('/user/email/send');
}