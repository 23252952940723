import React, {useEffect, useRef, useState} from 'react';
import {Channel} from "../../../models/Channel";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {channelsRating} from "../../../http/rating";
import {ChannelsRating} from "../../../models/ChannelsRating";
import {Icon} from "@tremor/react";
import {ClockIcon, XCircleIcon} from "@heroicons/react/20/solid";
import ChannelRatingCard from "./ChannelRatingCard";
import Status from "../../../ui/Status";

type ChannelRatingProps = {
    channel: Channel
    shorts: boolean
}

function ChannelRating({channel, shorts}: ChannelRatingProps) {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [rating, setRating] = useState<ChannelsRating>();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            if (!channel.topic_id) throw new Error("No topic id");
            const results  = await channelsRating(channel.topic_id, shorts);
            setRating(results.data);
        };

        fetchData().catch((e) => {
            setError(true);
        }).finally(() => {
            setLoading(false);
        });
    }, [shorts]);

    return (
        <WideCard className={"mt-8 mb-8 loadable" + (loading ? " loadable_active" : "")}>
            <div className="text-center text-lg mb-8 font-bold">{t("channel_page.rating.heading")}</div>
            {(rating && rating.avg_views && rating.avg_views.length < 3 && !error)
                ? <Status
                    icon={<Icon icon={ClockIcon} color="slate" size="xl"></Icon>}
                    headline={t("video_page.no_data")}
                    text={t("video_page.need_more_data")}
                />
                : (error
                        ? <Status
                            icon={<Icon icon={XCircleIcon} color="red" size="xl"></Icon>}
                            headline={t("video_page.something_went_wrong")}
                            text={t("video_page.something_went_wrong_text")}
                        />
                        : <>
                            {shorts
                            ?   <div className="grid grid-cols-2 gap-8">
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.sum_views : null} channel={channel} field="sum_views_shorts" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.median_vs : null} channel={channel} field="median_vs_shorts" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.median_erv : null} channel={channel} field="median_erv_shorts" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.median_length : null} channel={channel} field="median_length_shorts" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.current_videos : null} channel={channel} field="current_videos_shorts" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.avg_views : null} channel={channel} field="avg_views_shorts" />
                                </div>
                            :   <div className="grid grid-cols-2 gap-8">
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.sum_views : null} channel={channel} field="sum_views" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.median_vs : null} channel={channel} field="median_vs" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.median_erv : null} channel={channel} field="median_erv" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.median_length : null} channel={channel} field="median_length" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.current_videos : null} channel={channel} field="current_videos" />
                                    <ChannelRatingCard shorts={shorts} rating={rating ? rating.avg_views : null} channel={channel} field="avg_views" />
                                    {/*<ChannelRatingCard rating={rating ? rating.subscriptions : null} channel={channel} field="subscriptions" />*/}
                                    {/*<ChannelRatingCard rating={rating ? rating.median_length : null} channel={channel} field="median_length" />*/}
                                </div>}
                        </>
                )
            }
        </WideCard>
    );
}

export default ChannelRating;