import React, {useEffect, useMemo} from 'react';
import {TopicsList, AddTopic, useTopicsStore} from "../modules/topic";
import {useLoaderData} from "react-router-dom";
import {Topic} from "../models/Topic";
import {EmailNotVerified} from "../modules/authentication";
import Tutorial from "../components/Tutorial";
import Card from "../ui/Card";
import WideCard from "../ui/WideCard";
function MainPage() {
    const initialTopics:Topic[] = useLoaderData() as Topic[];

    if (initialTopics && initialTopics.length === 0) {
        return <EmailNotVerified/>;
    }

    const hasSubscription = useMemo(() => {
        if (Boolean(!!localStorage.getItem("subscription") && JSON.parse(localStorage.getItem("subscription")!))) return true;
        if (!!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).role) return true;
        return false;
    }, []);

    const {setTopics, topics} = useTopicsStore();

    useEffect(() => {
        setTopics(initialTopics);
    }, [initialTopics])

    return (
        <div>
            <Tutorial/>
            {(hasSubscription && !!topics && topics.length !== 0) &&
                <WideCard className="mt-8 mb-8">
                    <Card className="flex justify-center">
                        <AddTopic />
                    </Card>
                </WideCard>
            }
            <TopicsList />
        </div>
    );
}

export default MainPage;