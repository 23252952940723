import {AxiosResponse} from "axios";
import {ChannelVideosRating} from "../models/ChannelVideosRating";
import $api from "./index";
import {Partner} from "../models/Partner";
import {ReferralSubscription} from "../models/ReferralSubscription";

export function getUserRefAccount(): Promise<AxiosResponse<Partner>> {
    return $api.get<Partner>(`user/referral`)
}

export function getUserReferralSubscriptions(): Promise<AxiosResponse<ReferralSubscription[]>> {
    return $api.get<ReferralSubscription[]>(`user/referral/stats`)
}

export function createUserRefAccount(): Promise<AxiosResponse<Partner>> {
    return $api.post<Partner>(`user/referral`)
}
