import React, {ChangeEventHandler} from 'react';

type TextAreaProps = {
    value?: string | number
    onChange?: ChangeEventHandler
    error?: boolean
    errorMessage?: string
    className?: string
    placeholder?: string
}

function TextArea({onChange, error, errorMessage, value, placeholder, className}: TextAreaProps) {
    return (
        <>
            <textarea
                onChange={onChange}
                placeholder={placeholder}
                className={"block text-sm w-full rounded-lg resize-none pl-4 pr-4 py-2 focus:ring-0 placeholder:text-gray-500 ring-1 ring-gray-200 focus:outline-gray-200 " + className + (error ? " border border-rose-300" : "")}
            >
                {value}
            </textarea>
            {error
                ? <p className="mt-1 text-sm text-rose-500 whitespace-pre-line" dangerouslySetInnerHTML={{__html: errorMessage!}}></p>
                : <></>
            }
        </>
    );
}

export default TextArea;