import React from 'react';
import WideCard from "../ui/WideCard";
import Card from "../ui/Card";

function PrivacyPolicy() {
    return (
        <div className="container py-8">
        <WideCard>
            <Card>
                <div className="text-md">
                    <h1>Политика обработки персональных данных</h1>

                    <p>Дата размещения на Сайте &laquo;14&raquo; июля 2023 года.<br />
                        Настоящая Политика обработки персональных данных (далее &ndash; Политика) определяет общие принципы и порядок обработки персональных данных Пользователей сайта и меры по обеспечению их безопасности в ИП &laquo;Коновалов Денис Юрьевич&raquo;.</p>

                    <p><strong>Термины и определения.</strong><br />
                        Стороны используют следующие термины в указанном ниже значении:<br />
                        a). Данные &ndash; иные данные о Пользователе (не входящие в понятие персональных данных).<br />
                        b). Законодательство &ndash; действующее законодательство Российской Федерации.<br />
                        &bull;&nbsp;&nbsp; &nbsp;Оператор &ndash; ИП &laquo;Коновалов Денис Юрьевич&raquo;, самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br />
                        c). Персональные данные &ndash; любая информация, относящаяся к прямо или косвенно определённому или определяемому физическому лицу (Пользователю).<br />
                        &bull;&nbsp;&nbsp; &nbsp;Пользователь &ndash; лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.<br />
                        d). Предоставление персональных данных &ndash; действия, направленные на раскрытие Персональных данных определённому лицу или определённому кругу лиц.<br />
                        e). Сайт &ndash; сайт, расположенный в сети Интернет по адресу https://wisewatcher.ru.<br />
                        f).&nbsp; Субъект персональных данных &ndash; Пользователь (физическое лицо), к которому относятся Персональные данные.<br />
                        g). Транспортные компании &ndash; третьи лица, оказывающие курьерские услуги.<br />
                        Иные термины, используемые в Политике трактуются в соответствии с Законодательством РФ.</p>

                    <p><strong>1. Общие положения.</strong><br />
                        1.1. Настоящая Политика в отношении обработки персональных данных разработана в соответствии с положениями Федерального закона от 27.07.2006 №152-ФЗ &laquo;О персональных данных&raquo; (с изменениями и дополнениями), другими законодательными и нормативными правовыми актами и определяет порядок работы с Персональными данными Пользователей и (или) передаваемых Пользователями и требования к обеспечению их безопасности.<br />
                        1.2.&nbsp; Мероприятия по обеспечению безопасности персональных данных являются составной частью деятельности Оператора.</p>

                    <p><strong>2. Принципы обработки персональных данных.</strong><br />
                        2.1. Обработка Персональных данных Оператором осуществляется в соответствии со следующими принципами:<br />
                        2.1.1. Законность и справедливая основа обработки Персональных данных. Оператор принимает все необходимые меры по выполнению требований Законодательства, не обрабатывает Персональные данные в случаях, когда это не допускается Законодательством, не использует Персональные данные во вред Пользователю.<br />
                        2.1.2.&nbsp; Обработка только тех Персональных данных, которые отвечают заранее объявленным целям их обработки. Соответствие содержания и объёма обрабатываемых Персональных данных заявленным целям обработки. Недопущение обработки Персональных данных, не совместимых с целями сбора Персональных данных, а также избыточных по отношению к заявленным целям их обработки.<br />
                        Оператор обрабатывает Персональные данные исключительно в целях исполнения договорных обязательств перед Пользователем.<br />
                        2.1.3.&nbsp; Обеспечение точности, достаточности и актуальности Персональных данных по отношению к целям обработки Персональных данных. Оператор принимает все разумные меры по поддержке актуальности обрабатываемых Персональных данных, включая, но не ограничиваясь реализацией права каждого Субъекта получать для ознакомления свои Персональные данные и требовать от Оператора их уточнения, блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленных выше целей обработки.<br />
                        2.1.4.&nbsp; Хранение Персональных данных в форме, позволяющей определить Пользователя Персональных данных, не дольше, чем этого требуют цели обработки Персональных данных, если срок хранения Персональных данных не установлен федеральным законодательством, договором, стороной которого или выгодоприобретателем, по которому является Пользователь Персональных данных.<br />
                        2.1.5.&nbsp; Недопустимость объединения созданных для несовместимых между собой целей баз данных Информационных систем Персональных данных.</p>

                    <p><strong>3. Условия обработки персональных данных.</strong><br />
                        3.1.&nbsp; Обработка Персональных данных Оператором допускается в следующих случаях:<br />
                        3.1.1.&nbsp; При наличии согласия Пользователя на обработку его Персональных данных. Согласие дается путем акцепта Публичной оферты, размещенной на Сайте, путем регистрации на Сайте.<br />
                        3.1.2.&nbsp; При передаче Персональных данных Субъекта персональных данных Пользователем при осуществлении работы на Сайте. Пользователь гарантирует, что предварительно получил от Субъекта персональных данных согласие на передачу данных Оператору.<br />
                        3.1.3.&nbsp; Персональные данные подлежат опубликованию или обязательному раскрытию в соответствии с Законодательством.<br />
                        3.2.&nbsp; Оператор не раскрывает третьим лицам и не распространяет Персональные данные без согласия Пользователя, если иное не предусмотрено Законодательством.<br />
                        3.3.&nbsp; Оператор не обрабатывает Персональные данные, относящиеся к специальным категориям и касающиеся расовой и национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни Субъекта персональных данных, о членстве Субъекта персональных данных в общественных объединениях, за исключением случаев, прямо предусмотренных Законодательством.<br />
                        3.4. Оператор осуществляет Трансграничную передачу Персональных данных Пользователей.</p>

                    <p><strong>4. Сбор и обработка персональных данных и иных данных.</strong><br />
                        4.1.&nbsp; Оператор собирает и хранит только те Персональные данные, которые необходимы для предоставления услуг Пользователю для осуществления работы Пользователя с Сайтом. При этом сбор Персональных данных может осуществляться как посредством Сайта, так и в офисе Оператора.<br />
                        4.2.&nbsp; Оператор обрабатывает Персональные данные в следующих целях:<br />
                        4.2.1.&nbsp; осуществления деятельности, предусмотренной̆ назначением Сайта, действующим законодательством РФ;<br />
                        4.2.2.&nbsp; выполнения обязательств Оператора перед Пользователем по продаже товаров, в том числе обработку платежей, доставку товаров.<br />
                        4.2.3.&nbsp; для связи с Пользователями в случае необходимости, в том числе для направления уведомлений, информации и запросов, связанных с оказанием услуг, а также обработки заявлений, запросов и заявок Пользователей;<br />
                        4.2.4.&nbsp; для улучшения качества услуг, оказываемых Оператором;<br />
                        4.2.5.&nbsp; для продвижения услуг на рынке путем осуществления прямых контактов с Пользователями;<br />
                        4.2.6.&nbsp; для проведения статистических и иных исследований на основе обезличенных персональных данных.<br />
                        4.3.&nbsp; В целях, указанных в п. 4.2. Оператор обрабатывает следующие персональные:<br />
                        &bull;&nbsp;&nbsp; &nbsp;Фамилия, имя и отчество;<br />
                        &bull;&nbsp;&nbsp; &nbsp;Адрес электронной почты;<br />
                        &bull;&nbsp;&nbsp; &nbsp;Телефон.<br />
                        &bull;&nbsp;&nbsp; &nbsp;Email<br />
                        4.4.&nbsp; В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме случаев, когда указанные данные являются общедоступными.<br />
                        4.5.&nbsp; Оператор имеет право сохранять архивную копию Персональных данных и иных Данных, в том числе после удаления аккаунта Пользователя.<br />
                        4.6.&nbsp; Оператор имеет право передавать Персональные данные и иные Данные Пользователя без согласия Пользователя следующим лицам:<br />
                        4.6.1.&nbsp; Государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по их мотивированному запросу.<br />
                        4.6.2.&nbsp; Партнёрам Оператора c целью выполнения договорных обязательств перед Пользователем.<br />
                        4.6.3.&nbsp; В иных случаях, прямо предусмотренных действующим законодательством РФ.<br />
                        4.7.&nbsp; Оператор имеет право передавать Персональные данные и иные Данные третьим лицам, не указанным в п. 4.6 настоящей Политики, в следующих случаях:<br />
                        4.7.1.&nbsp; Пользователь выразил свое согласие на такие действия.<br />
                        4.7.2.&nbsp; Передача необходима в рамках использования Пользователем Сайта или продажи товаров Пользователю (в том числе в целях доставки товаров);<br />
                        4.7.3.&nbsp; Передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики.<br />
                        4.8.&nbsp; Оператор осуществляет автоматизированную и неавтоматизированную обработки Персональных данных и иных Данных.<br />
                        4.9.&nbsp; Доступ к Информационным системам, содержащим Персональные данные, обеспечивается системой паролей. Пароли устанавливаются уполномоченными сотрудниками Оператора и индивидуально сообщаются работникам Оператора, имеющим доступ к Персональным данным/Данным.<br />
                        4.10.&nbsp; Оператор передает обработанную информацию транспортным (курьерским) компаниям на основании согласия Пользователя (ст. 9 ФЗ &laquo;О персональных данных&raquo;).<br />
                        4.11.&nbsp; Оператор не несет ответственности за ненадлежащую обработку персональных данных Пользователя, осуществляемую Транспортными компаниями.<br />
                        4.12.&nbsp; При получении Оператором персональных данных запроса, содержащего отзыв субъекта персональных данных согласия на обработку персональных данных, Оператор обязуется в течение 30 (тридцати) календарных дней с момента его получения запроса удалить персональные данные.</p>

                    <p><strong>5. Изменение персональных данных.</strong><br />
                        5.1.&nbsp; Пользователь может в любой момент изменить (обновить, дополнить) Персональные данные путём направления письменного заявления Оператору.<br />
                        5.2.&nbsp; Пользователь в любой момент имеет право удалить Персональные данные/Данные.</p>

                    <p><strong>6. Конфиденциальность персональных данных.</strong><br />
                        6.1.&nbsp; Оператор обеспечивает конфиденциальность обрабатываемых им Персональных данных/Данных в порядке, предусмотренном Законодательством. Обеспечение конфиденциальности не требуется в отношении:<br />
                        6.1.1.&nbsp; Персональных данных после их обезличивания.<br />
                        6.1.2.&nbsp; Персональных данных, доступ неограниченного круга лиц к которым предоставлен Пользователем либо по его просьбе (далее &ndash; Персональные данные, сделанные общедоступными Пользователем).<br />
                        6.1.3.&nbsp; Персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с Законодательством.<br />
                        6.1.4.&nbsp; Не является нарушением конфиденциальности Персональных данных предоставление Оператором информации транспортным (курьерским) компаниям и иным третьим лицам, действующим на основании договора с Оператором для исполнения обязательств перед Пользователем.</p>

                    <p><strong>7. Согласие пользователя на обработку персональных данных.</strong><br />
                        7.1.&nbsp; Пользователь принимает решение о предоставлении своих Персональных данных Оператору и даёт согласие на их обработку свободно, своей волей и в своём интересе. Согласие на обработку Персональных данных должно быть конкретным, информированным и сознательным и предоставляется Пользователем в момент его Регистрации на Сайте Оператора, а также в любой позволяющей подтвердить факт его получения форме, если иное не установлено Законодательством.<br />
                        7.2.&nbsp; Персональные данные лиц, вступивших в договорные обязательства с Оператором, содержащиеся в единых государственных реестрах юридических лиц и индивидуальных предпринимателей, являются открытыми и общедоступными, за исключением сведений о номере, дате выдачи и органе, выдавшем документ, удостоверяющий личность физического лица. Охраны их конфиденциальности и согласия Пользователей на обработку не требуется.<br />
                        7.3.&nbsp; В случае поступления запросов из организаций, не обладающих соответствующими полномочиями, Оператор обязан получить предварительное согласие Пользователя на предоставление его Персональных данных и предупредить лиц, получающих Персональные данные, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, а также требовать от этих лиц подтверждения того, что это правило будет (было) соблюдено.</p>

                    <p><strong>8. Права субъектов персональных данных.</strong><br />
                        8.1.&nbsp; Пользователь имеет право на получение информации, касающейся обработки его Персональных данных/Данных. Пользователь вправе требовать от Оператора уточнения его Персональных данных, их блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.<br />
                        8.2.&nbsp; Если Пользователь считает, что Оператор осуществляет обработку его Персональных данных с нарушением требований Законодательства или иным образом нарушает его права и свободы, Пользователь вправе обжаловать действия или бездействие Оператора в уполномоченном органе по защите прав субъектов Персональных данных или в судебном порядке.<br />
                        8.3.&nbsp; Пользователь имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.</p>

                    <p><strong>9. Персональные данные третьих лиц, используемые пользователями.</strong><br />
                        9.1.&nbsp; Используя Сайт, Пользователь имеет право заносить данные третьих лиц для заказа товаров.<br />
                        9.2.&nbsp; Пользователь обязуется получить предварительное согласие субъекта Персональных данных на их использование посредством Сайта. Пользователь самостоятельно несет ответственность за отсутствие такого согласия.<br />
                        9.3.&nbsp; Оператор обязуется предпринять необходимые меры для обеспечения сохранности Персональных данных третьих лиц, занесённых Пользователем.</p>

                    <p><strong>10. Иные положения.</strong><br />
                        10.1.&nbsp; К настоящей Политике и отношениям между Пользователем и Оператором, возникающим в связи с применением Политики, подлежит к применению право Российской Федерации.<br />
                        10.2.&nbsp; Все возможные споры подлежат разрешению в соответствии с Законодательством по месту регистрации Оператора.<br />
                        Перед обращением в суд Пользователь должен соблюсти обязательный досудебный порядок и направить Оператору соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.<br />
                        10.3.&nbsp; Если по тем или иным причинам одно или несколько положений Политики будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Политики конфиденциальности.<br />
                        10.4.&nbsp; Оператор имеет право в любой момент изменять настоящую Политику (полностью или в части) в одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают в силу на следующий день после размещения на Сайте.<br />
                        10.5.&nbsp; Пользователь обязуется самостоятельно следить за изменениями Политики конфиденциальности путем ознакомления с актуальной редакцией.</p>

                    <p><strong>11. Контактная информация оператора.</strong><br />
                        11.1. Telegram чат службы поддержки&nbsp; <a href="https://t.me/wisewatcher_support" className="text-blue-500">https://t.me/wisewatcher_support</a> <br />
                        11.2. E-mail: <a href="mailto:support@wisewatcher.ru" className="text-blue-500">support@wisewatcher.ru</a></p>
                </div>
            </Card>
        </WideCard>
        </div>
    );
}

export default PrivacyPolicy;