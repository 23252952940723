import React, {useEffect, useState} from 'react';
import {Video} from "../../../models/Video";
import WideCard from "../../../ui/WideCard";
import YouTube, { YouTubeProps } from 'react-youtube';
import classes from "./Player.module.scss";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Badge, Button, Callout, Icon} from "@tremor/react";
import {
    ArchiveBoxXMarkIcon,
    BookmarkIcon,
    ExclamationCircleIcon,
    NoSymbolIcon,
    SignalIcon
} from "@heroicons/react/20/solid";
import {BookmarkIcon as BookmarkOutlineIcon} from "@heroicons/react/24/outline";
import {addToFavourites, removeFromFavourites} from "../../../http/favourite";
import * as Sentry from "@sentry/react";

type PlayerProps = {
    video: Video
    channel_name: string
}

function Player({video, channel_name}: PlayerProps) {
    const {t} = useTranslation();
    const opts: YouTubeProps['opts'] = {
        height: '100%',
        width: '100%',
    };

    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        setIsFavourite(video.is_favourite!);
    }, [video]);


    function handleToggleFavourite() {
        const fromFavourites = async () => {
            await removeFromFavourites(video.id);
        };

        const toFavourites = async () => {
            await addToFavourites(video.id);
        };

        if (isFavourite) {
            fromFavourites().catch((e) => {
                Sentry.captureException(e)
            });
        } else {
            toFavourites().catch((e) => {
                Sentry.captureException(e)
            });
        }

        setIsFavourite(!isFavourite);
    }

    return (
        <WideCard>
            <div className={classes['player-wrapper']}>
                <YouTube videoId={video.youtube_id} iframeClassName={classes['react-player']} opts={opts} />
            </div>
            <>
                {(video.blocked || video.deleted) && <Card className="mt-8">
                    <Callout
                        title={t("video_page." + (video.deleted ? "video_deleted" : "video_blocked"))}
                        icon={ExclamationCircleIcon}
                        color="red"
                    >
                        {t("video_page." + (video.deleted ? "video_deleted_text" : "video_blocked_text"))}
                    </Callout>
                </Card>}
            </>
            <Card className="mt-8">
                <div className={classes['player-info'] + " flex"}>
                    <Link to={"/channel/" + video.channel?.id} className="shrink-0 flex flex-col items-center">
                        {(video.channel?.blocked || video.channel?.deleted)
                            ? <div className="w-32 h-32 rounded-full bg-red-100 flex justify-center items-center">
                                <Icon icon={video.channel?.blocked ? NoSymbolIcon : ArchiveBoxXMarkIcon} color="red" className="text-red-700" size="xl" />
                            </div>
                            : <img src={video.channel?.avatar} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="/person.svg";
                            }} alt="avatar" className={classes['player-info__avatar']} />
                        }
                        <div className="text-sm mt-2 text-blue-500">{t("video_page.channel_stats")}</div>
                    </Link>
                    <div className={classes['player-info-wrapper'] + " ml-8"}>
                        <div className="font-bold mb-2">{channel_name}: {video.latest_snap?.title}</div>
                        <Button size="lg" onClick={handleToggleFavourite} variant="light" className="mb-1 text-base" icon={isFavourite ? BookmarkIcon : BookmarkOutlineIcon}><div className="text-base">{t("video_page." + (isFavourite ? "in_favourites" : "add_to_favourites"))}</div></Button>
                        {video.stream && <div className="block mb-2"> <Badge color="red" icon={SignalIcon}>{t("video_page.stream")}</Badge> </div>}
                        <div className="text-slate-400 mb-2">{t("video_page.publish_date")}: {new Date(video.date).toLocaleDateString()}</div>
                        {video.snapshots && video.snapshots?.length > 0 &&
                            <div className="text-slate-400 mb-2">{t("video_page.start_of_observation")}: {new Date(video.snapshots![video.snapshots!.length - 1].created_at).toLocaleString()}</div>
                        }
                        {video.latest_snap &&
                            <div className="text-slate-400">{t("video_page.latest_update")}: {new Date(video.latest_snap!.created_at).toLocaleString()}</div>
                        }
                    </div>
                </div>
            </Card>
        </WideCard>
    );
}

export default Player;