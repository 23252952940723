import React, {useMemo} from 'react';
import {useLoaderData} from "react-router-dom";
import {Video} from "../models/Video";
import {Player, VideoRating, VideoInfo, VideoStats, AddToFavourites} from "../modules/video";
import Breadcrumbs from "../components/Breadcrumbs";
import {useTranslation} from "react-i18next";
import WideCard from "../ui/WideCard";
import Card from "../ui/Card";
import Status from "../ui/Status";
import {Icon} from "@tremor/react";
import {ArchiveBoxIcon} from "@heroicons/react/20/solid";

function VideoPage() {
    const video: Video = useLoaderData() as Video;
    const {t} = useTranslation();

    const isArchive = useMemo(() => {
        const nowDate = new Date();
        const videoDate = new Date(video.date);
        videoDate.setDate(videoDate.getDate() + 31);

        if (nowDate > videoDate) {
            return true;
        }

        return false;
    }, [video]);

    return (
        <div className="pt-8 pb-8" key={video.id}>
            <Breadcrumbs pages={[
                {
                    title: `${t("breadcrumbs.topic")}: ${video.breadcrumbs![0].title}`,
                    link: `/topic/${video.breadcrumbs![0].id}`
                },
                {
                    title: `${t("breadcrumbs.channel")}: ${video.breadcrumbs![1].title}`,
                    link: `/channel/${video.breadcrumbs![1].id}`
                },
                {
                    title: `${t("breadcrumbs.video")}: ${video.latest_snap!.title}`,
                    link: ""
                }
            ]} />
            <Player video={video} channel_name={video.breadcrumbs![1].title} />
            <>
                {isArchive
                    ? <WideCard className="mt-8">
                        <Card>
                            <Status
                                icon={<Icon icon={ArchiveBoxIcon} color="slate" size="xl"/>}
                                headline={t("video_page.archive_video")}
                                text={t("video_page.archive_video_text")}
                            />
                        </Card>
                    </WideCard>
                    : <>
                        <VideoRating video={video} overall={false}/>
                        <VideoRating video={video} overall={true}/>
                    </>
                }
            </>
            <VideoInfo video={video}/>
            <VideoStats video={video}/>
        </div>
    );
}

export default VideoPage;