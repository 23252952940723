import React from 'react';
import classes from "./SearchResultChannel.module.scss";
import {SearchHitChannel} from "../../../models/SearchHit";
import {Link} from "react-router-dom";

interface SearchHitChannelProps {
    hit: SearchHitChannel
}

function SearchResultChannel({hit}: SearchHitChannelProps) {
    return (
        <Link to={"/channel/" + hit.id} className={classes['search-hit']}>
            <img src={hit.avatar} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/person.svg";
            }} alt="channelAvatar" className={classes['search-hit__avatar']}/>
            <div className={classes['search-hit__title']} dangerouslySetInnerHTML={{__html: hit._formatted.title}}></div>
        </Link>
    );
}

export default SearchResultChannel;