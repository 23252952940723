import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import classes from "./Search.module.scss";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {TextInput} from "@tremor/react";
import {useTranslation} from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";
import SearchService, {SearchResult} from "../service";
import SearchResultChannel from "./SearchResultChannel";
import SearchResultVideo from "./SearchResultVideo";

function Search() {
    const { t } = useTranslation();

    const searchResultsRef = useRef<HTMLDivElement>(null);
    const stateRef = useRef<number>();

    const [requestId, setRequestId] = useState<number>(0);
    const [query, setQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<SearchResult | null>(null);
    const [searchResultsActive, setSearchResultsActive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const debouncedQuery = useDebounce(query, 500);

    stateRef.current = requestId;

    function handleClickOutside(event: MouseEvent) {
        if (searchResultsRef.current && !searchResultsRef.current.contains(event.target as Node)) {
            setSearchResultsActive(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const newQuery = event.target.value;
        setQuery(newQuery);

        if (newQuery === '') {
            setSearchResultsActive(false);
            setLoading(false);
        } else {
            setLoading(true);
        }
    }

    useEffect(() => {
        setSearchResultsActive(true);
        if (debouncedQuery != '') {
            const currentRequestId = requestId + 1;
            setRequestId(currentRequestId);

            SearchService.search(debouncedQuery).then((res) => {
                if (currentRequestId === stateRef.current) {
                    setSearchResults(res);
                    setLoading(false);
                }
            });
        } else {
            setSearchResults(null);
            setSearchResultsActive(false);
            setLoading(false);
        }
    }, [debouncedQuery])

    return (
        <div className={classes['search']} ref={searchResultsRef}>
            <TextInput onChange={handleChange} icon={MagnifyingGlassIcon} placeholder={t("search.search").toString()} className={classes['search__input']}/>
            <div className={"loadable loadable_absolute shadow-sm border border-gray-300 " +
                classes['search-results'] + (searchResultsActive ? " " + classes['search-results_active'] : '')
                + (loading ? " loadable_active" : "")}>
                <h4>{t('search.channels')}</h4>
                <div onClick={() => {setSearchResultsActive(false)}}>
                    {searchResults?.channels.length ? searchResults?.channels.map((el) => {
                        return <SearchResultChannel key={el.id} hit={el}/>
                    }) : <div>{t('search.channels_not_found', {query: query})}</div>}
                </div>
                <h4>{t('search.videos')}</h4>
                <div onClick={() => {setSearchResultsActive(false)}}>
                    {searchResults?.videos.length ? searchResults?.videos.map((el) => {
                        return <SearchResultVideo key={el.id} hit={el}/>
                    }) : <div>{t('search.videos_not_found', {query: query})}</div>}
                </div>
            </div>
        </div>
    );
}

export default Search;