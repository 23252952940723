import React, {useEffect, useState} from 'react';
import WideCard from "../ui/WideCard";
import classes from "./Tutorial.module.scss";
import YouTube, {YouTubeProps} from "react-youtube";
import Card from "../ui/Card";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {Button, Callout} from "@tremor/react";
import {useTranslation} from "react-i18next";

function Tutorial() {
    const {t} = useTranslation();

    const opts: YouTubeProps['opts'] = {
        height: '100%',
        width: '100%',
    };

    const [shown, setShown] = useState(true);

    useEffect(() => {
        if (!!localStorage.getItem("tutorial")) {
            setShown(false);
        }
    }, [])

    function handleClose() {
        setShown(false);
        localStorage.setItem("tutorial", "1");
    }

    return (
        shown ? <WideCard className="mt-8">
            <div className={classes['player-wrapper']}>
                <YouTube videoId="2TI40_BfDW0" iframeClassName={classes['react-player']} opts={opts} />
            </div>
            <Card className="mt-8 flex flex-col items-center">
                <Callout
                    title={t("main_page.tutorial_heading")}
                    color="green"
                    icon={InformationCircleIcon}
                    className="mb-4"
                >
                    {t("main_page.tutorial_text")}
                </Callout>
                <Button onClick={handleClose}>{t("main_page.tutorial_button")}</Button>
            </Card>
        </WideCard> : <></>
    );
}

export default Tutorial;