import React from 'react';
import {Topic} from "../../../models/Topic";
import WideCard from "../../../ui/WideCard";
import {
    Area,
    AreaChart,
    CartesianGrid, ComposedChart,
    Label,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import ChartLegend from "../../video/components/ChartLegend";
import {formatTick, formatTickPercentage} from "../../../utils/numbers";
import {BaseColors, hexColors, themeColorRange} from "../../../constants";
import ChartTooltip from "../../video/components/ChartTooltip";
import {useTranslation} from "react-i18next";
import {Card} from "@tremor/react";
import {constructCategoryColors} from "@tremor/react/dist/components/chart-elements/common/utils";

type ViewsChartProps = {
    topic: Topic
}

function ViewsChart({topic}: ViewsChartProps) {
    const {t} = useTranslation();
    const categoryColors = constructCategoryColors(['median_views'], themeColorRange);
    return (
        <WideCard className="mt-8 mb-8">
            <Card>
                <div className="h-96 w-full">
                    <ResponsiveContainer height="100%" width="100%">
                        <ComposedChart data={topic.views_chart} margin={{bottom: 20, left: 15}}>
                            <CartesianGrid strokeDasharray="3 3"/>

                            <XAxis
                                dataKey="day_number"
                                interval="preserveStartEnd"
                                tick={{transform: "translate(0, 6)"}}
                                style={{
                                    fontSize: "12px",
                                    fontFamily: "Inter; Helvetica",
                                }}
                                tickLine={false}
                                axisLine={false}
                                padding={{left: 10, right: 10}}
                                minTickGap={5}
                                tickFormatter={(value, index) => {
                                    // @ts-ignore
                                    return (topic.views_chart[index]["day_number"] as number).toString();
                                }}
                            >
                                <Label
                                    value={t("channel_page.days").toString()}
                                    offset={0} position="bottom" className="text-xs"/>
                            </XAxis>

                            {/*<Legend*/}
                            {/*    verticalAlign="top"*/}
                            {/*    height={legendHeight}*/}
                            {/*    content={({payload}) => ChartLegend({payload}, categoryColors, setLegendHeight)}*/}
                            {/*/>*/}

                            <YAxis
                                width={62}
                                tick={{transform: "translate(-3, 0)"}}
                                axisLine={false}
                                tickLine={false}
                                type="number"
                                style={{
                                    fontSize: "12px",
                                    fontFamily: "Inter; Helvetica",
                                }}
                                tickFormatter={formatTick}
                            />

                            <Area
                                type="monotone"
                                dataKey="min_views"
                                fill="#fff"
                                stroke={"#fff"}
                                fillOpacity={1}
                                stackId="1"
                            />
                            <Area
                                type="monotone"
                                dataKey="max_views"
                                fill="#ccc"
                                stroke={"#fff"}
                                fillOpacity={1}
                                stackId="1"
                            />


                            <Line
                                key={"median_views"}
                                name={"median_views"}
                                type="linear"
                                dataKey={"median_views"}
                                stroke={hexColors[BaseColors.Red]}
                                strokeWidth={2}
                                dot={true}
                                isAnimationActive={true}
                                connectNulls={true}
                            />

                            <Tooltip
                                wrapperStyle={{outline: "none"}}
                                content={({active, payload, label}) => (
                                    <ChartTooltip
                                        active={active}
                                        payload={payload}
                                        label={label}
                                        categoryColors={categoryColors}
                                    />
                                )}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </WideCard>
    );
}

export default ViewsChart;