import {Link, useRouteError} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import * as Sentry from "@sentry/react";

export default function ErrorPage() {
    const {t} = useTranslation();

    const error: any  = useRouteError();

    const [errorId, setErrorId] = useState<string>('');

    useEffect(() => {
        if (error && !error.data) {
            setErrorId(Sentry.captureException(error));
        }
    }, [error]);

    return (
        <section className="flex items-center h-screen p-16 bg-gray-100 text-gray-100">
            <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div className="max-w-md text-center flex flex-col items-center">
                    {
                        (!!error && !!error.status)
                        ? <p className="text-7xl mb-4 text-gray-900 font-bold">{error.status}</p>
                        : <></>
                    }
                    <p className="text-gray-700">
                        {
                            (!!error && !!error.data) ? <>{t("error_page." + error.data)}</>
                            : <>{t("error_page.unexpected_error")} <a href="https://t.me/wisewatcher_support" className="text-blue-500" target="_blank">{t("error_page.support")}.</a></>
                        }
                    </p>
                    {
                        (!!errorId)
                            ? <p className="text-sm mt-4 text-gray-700">{t("error_page.error_id")}: {errorId}</p>
                            : <></>
                    }
                    <Link to="/" reloadDocument={true} className="block px-8 py-3 mt-8 font-semibold rounded bg-blue-500 text-gray-100">{t("error_page.back_home")}</Link>
                </div>
            </div>
        </section>
    );
}