import React from 'react';
import {Snapshot} from "../../../models/Snapshot";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {stringToDateString} from "../../../utils/date";
import Card from "../../../ui/Card";
import {Link} from "react-router-dom";
import {formatNumber} from "../../../utils/numbers";

type LatestVideoProps = {
    snapshot: Snapshot | null
}

function LatestVideo({snapshot}: LatestVideoProps) {
    const {t} = useTranslation();

    if (!snapshot) {
        return <></>;
    }

    return (
        <WideCard className="mt-8 mb-8">
            <div className="text-lg font-bold text-center mb-8">
                {t("channel_page.latest_update", {
                    date: stringToDateString(snapshot.created_at),
                    serial: (snapshot.serial_number % 24 == 0 && snapshot.serial_number != 0) ? (snapshot.serial_number / 24) : snapshot.serial_number,
                    period: (snapshot.serial_number % 24 == 0 && snapshot.serial_number != 0)? t("channel_page.day") : t("channel_page.hour")
                })}
            </div>
            <div className="grid grid-cols-2 gap-8 items-start">
                <Link to={"/video/" + snapshot.video_id}>
                    <img src={import.meta.env.VITE_BACKEND_URL + snapshot.preview} alt="preview" className="block rounded-md aspect-video w-full"/>
                    <div className="text-sm mt-4">{snapshot.title}</div>
                </Link>
                <div>
                    <div className="grid grid-cols-2 gap-4">
                        <Card>
                            <div className="font-bold mb-4 text-sm">{t("video_page.rating.views")}</div>
                            <div className="font-bold text-xl">{formatNumber(snapshot.views)}</div>
                        </Card>
                        <Card>
                            <div className="font-bold mb-4 text-sm">{t("video_page.rating.vs")}</div>
                            <div className="font-bold text-xl">{formatNumber(snapshot.vs, true)}</div>
                        </Card>
                        <Card>
                            <div className="font-bold mb-4 text-sm">{t("video_page.rating.erv")}</div>
                            <div className="font-bold text-xl">{formatNumber(snapshot.erv, true)}</div>
                        </Card>
                    </div>
                    <Link to={"/video/" + snapshot.video_id} className="block text-blue-500 text-sm text-center mt-4 cursor-pointer">
                        {t("channel_page.view_stats")}
                    </Link>
                </div>
            </div>
        </WideCard>
    );
}

export default LatestVideo;