import React, {useMemo, useState} from 'react';
import {Channel} from "../../../models/Channel";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import Modal from "../../../ui/Modal";
import Card from "../../../ui/Card";
import ChannelAddCard from "./ChannelAddCard";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {Button, Callout} from "@tremor/react";
import {Topic} from "../../../models/Topic";
import {deleteChannelFromTopicRecommendations, topicInfo} from "../http";
import * as Sentry from "@sentry/react";
import {useShortsStore} from "../../../store/shorts";

type RecommendedChannelsProps = {
    channels: Channel[]
    topic: Topic
    setTopic: Function
    hidePreview?: boolean
}

function RecommendedChannels({channels, topic, setTopic, hidePreview}: RecommendedChannelsProps) {
    const {t} = useTranslation();

    const {shorts} = useShortsStore();

    const [modalShown, setModalShown] = useState(false);
    const [deleteModalShown, setDeleteModalShown] = useState(false);
    const [channelToDelete, setChannelToDelete] = useState<Channel>();

    const teaserChannels = useMemo(() => {
        return channels.map(value => ({value, sort: Math.random()}))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value).slice(0, 5);
    }, []);

    function handleDeleteChannel() {
        if (!channelToDelete) return;
        setTopic({
            ...topic,
            recommended_channels: topic.recommended_channels!.filter((el) => el.id !== channelToDelete.id)
        });
        deleteChannelFromTopicRecommendations(topic.id, channelToDelete.id).catch((e) => {
            Sentry.captureException(e);
        });
        setChannelToDelete(undefined);
        setDeleteModalShown(false);
    }

    async function handleModalClose() {
        setModalShown(false);

        try {
            console.log("modal close");
            const updatedTopic = await topicInfo(topic.id, shorts);
            setTopic(updatedTopic.data);
            console.log(updatedTopic);
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    if (hidePreview) {
        return <>
            <div className="text-center text-lg mb-8 font-bold">
                {t("topic_page.recommended_channels")}
            </div>
                <Callout
                    title={t("topic_page.recommended_channels_callout")}
                    color="green"
                    icon={InformationCircleIcon}
                    className="mb-8"
                >
                    {t("topic_page.recommended_channels_callout_text")}
                </Callout>
                <div className="flex gap-4 gap-y-8 flex-wrap">
                    {channels.map((channel) => {
                        return <ChannelAddCard
                            key={channel.id}
                            channel={channel}
                            topicId={topic.id}
                            onCrossClick={() => {
                                setDeleteModalShown(true);
                                setChannelToDelete(channel);
                            }}
                        />;
                    })}
                </div>
            <Modal widthClass="max-w-2xl" shown={deleteModalShown} close={() => {
                setDeleteModalShown(false)
            }}>
                <div className="text-center text-lg mb-8 font-bold">
                    {t("topic_page.recommended_channels_delete", {name: channelToDelete?.title})}
                </div>
                <Card>
                    <img src={channelToDelete?.avatar} onError={({currentTarget}) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/person.svg";
                    }} alt="avatar" className="block w-32 rounded-full mx-auto"/>
                    <div className="text-center text-sm mt-2 text-slate-700 mb-4">{channelToDelete?.title}</div>
                    <div className="text-center text-sm">{t("topic_page.recommended_are_you_sure")}</div>
                    <div className="mt-8 text-center flex items-center justify-center">
                        <Button onClick={() => {
                            setDeleteModalShown(false)
                        }} className="mr-2">{t("main_page.cancel")}</Button>
                        <Button onClick={handleDeleteChannel} color="red">{t("main_page.yes_delete")}</Button>
                    </div>
                </Card>
            </Modal>
        </>;
    }

    return (
        <WideCard>
            <div className="text-center text-lg mb-8 font-bold">{t("topic_page.recommended_channels")} </div>
            {/*<Badge color="purple" size="xs">new</Badge>*/}
            <Card className="mt-8 mb-8">
                {/*<div className="font-bold mb-8">{t("topic_page.recommended_channels_sub")}</div>*/}
                <div className="flex gap-8">
                    {teaserChannels.map((channel) => {
                        return <ChannelAddCard
                            key={channel.id}
                            channel={channel}
                            topicId={topic.id}
                            onClick={() => setModalShown(true)}
                            hideCross={true}
                            hideLink={true}
                            onCrossClick={() => {
                            }}
                        />;
                    })}
                </div>
                <div className="text-blue-500 text-sm text-center mt-8 cursor-pointer" onClick={() => {
                    setModalShown(true)
                }}>
                    {t("topic_page.view_all_list")}
                </div>
                <Modal shown={modalShown} close={handleModalClose} widthClass="max-w-6xl">
                    <div className="text-center text-lg mb-8 font-bold">
                        {t("topic_page.recommended_channels")}
                    </div>
                    {modalShown ?
                        <>
                            <Card style={{boxShadow: 'none', border: 'none', paddingTop: 0}}>
                                <Callout
                                    title={t("topic_page.recommended_channels_callout")}
                                    color="green"
                                    icon={InformationCircleIcon}
                                    className="mb-8"
                                >
                                    {t("topic_page.recommended_channels_callout_text")}
                                </Callout>
                                <div className="flex gap-4 gap-y-8 flex-wrap">
                                    {channels.map((channel) => {
                                        return <ChannelAddCard
                                            key={channel.id}
                                            channel={channel}
                                            topicId={topic.id}
                                            onCrossClick={() => {
                                                setDeleteModalShown(true);
                                                setChannelToDelete(channel);
                                            }}
                                        />;
                                    })}
                                </div>
                            </Card>
                        </>
                        : <></>
                    }
                </Modal>
                <Modal widthClass="max-w-2xl" shown={deleteModalShown} close={() => {
                    setDeleteModalShown(false)
                }}>
                    <div className="text-center text-lg mb-8 font-bold">
                        {t("topic_page.recommended_channels_delete", {name: channelToDelete?.title})}
                    </div>
                    <Card>
                        <img src={channelToDelete?.avatar} onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/person.svg";
                        }} alt="avatar" className="block w-32 rounded-full mx-auto"/>
                        <div className="text-center text-sm mt-2 text-slate-700 mb-4">{channelToDelete?.title}</div>
                        <div className="text-center text-sm">{t("topic_page.recommended_are_you_sure")}</div>
                        <div className="mt-8 text-center flex items-center justify-center">
                            <Button onClick={() => {
                                setDeleteModalShown(false)
                            }} className="mr-2">{t("main_page.cancel")}</Button>
                            <Button onClick={handleDeleteChannel} color="red">{t("main_page.yes_delete")}</Button>
                        </div>
                    </Card>
                </Modal>
            </Card>
        </WideCard>
    );
}

export default RecommendedChannels;