import React, {useEffect, useState} from 'react';
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {getBaseMetrics} from "../http";
import * as Sentry from "@sentry/react";
import {BaseMetricsResponse} from "../../../models/BaseMetricsResponse";
import {formatNumber} from "../../../utils/numbers";

type BaseMetricsFields = "total_accounts";

function BaseMetrics() {
    const {t} = useTranslation();

    const [metrics, setMetrics] = useState<BaseMetricsResponse | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        const result = await getBaseMetrics();
        setMetrics(result.data);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        fetchData().catch((e) => {
            setLoading(false);
            Sentry.captureException(e);
        })
    }, [])
    return (
        <Card className={"mb-8 loadable" + (loading ? " loadable_active" : "")}>
            <div className="text-lg font-bold text-center mb-8">{t("stats_page.base_metrics")}</div>
            <div className="grid grid-cols-4 gap-2">
                {!!metrics && Object.keys(metrics).map((k) => {
                    return <Card className="p-3" style={{padding: 16}}>
                        <div className="text-xs mb-1">{t("stats_page.metrics." + k)}</div>
                        <div className="text-xl font-bold">{formatNumber(metrics[k as BaseMetricsFields])}</div>
                    </Card>
                })}
            </div>
        </Card>
    );
}

export default BaseMetrics;