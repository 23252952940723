import React, {MouseEventHandler, useMemo, useState} from 'react';
import {Topic} from "../../../models/Topic";
import {Button, Icon} from "@tremor/react";
import {useTranslation} from "react-i18next";
import {deleteTopic} from "../http";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import Status from "../../../ui/Status";
import {useTopicsStore} from "../store";
import Card from "../../../ui/Card";

type DeleteTopicProps = {
    topic: Topic
    close: MouseEventHandler
}

function DeleteTopic({topic, close}: DeleteTopicProps) {
    const {t} = useTranslation();

    const {topics, setTopics} = useTopicsStore();

    const [loading, setLoading] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();

    function handleDeleteTopic() {
        setLoading(true);
        const removeTopic = async () => {
            const res = await deleteTopic(topic.id);
            setResponseCode(res.status);
            setLoading(false);
            setTopics([...topics.filter((t) => t.id != topic.id)]);
            let channelsUsed = parseInt(localStorage.getItem("channels_used")!);
            localStorage.setItem("channels_used", (channelsUsed - topic.channels.length).toString());
        }

        removeTopic().catch((e) => {
            setLoading(false);
            setResponseCode(e.response.status);
        });
    }

    const content = useMemo(() => {
        if (!responseCode) {
            return <>
                <div className="text-center text-sm">{t("main_page.are_you_sure")}</div>
                <div className="mt-8 text-center flex items-center justify-center">
                    <Button onClick={close} className="mr-2">{t("main_page.cancel")}</Button>
                    <Button onClick={handleDeleteTopic} loading={loading} color="red">{t("main_page.yes_delete")}</Button>
                </div>
            </>;
        } else if (responseCode === 200) {
            return <div className="text-center">
                <Status
                    icon={<Icon icon={CheckCircleIcon} size="xl" color="green" />}
                    headline={t("main_page.done")}
                    text={t("main_page.delete_topic_success")}
                />
                <Button onClick={close} className="mt-4">{t("main_page.close")}</Button>
            </div>;
        }

        return <Status
            icon={<Icon icon={XCircleIcon} size="xl" color="red" />}
            headline={t("errors.error")}
            text={t("errors.something_went_wrong")}
        />;
    }, [responseCode, loading, close]);

    return (
        <Card>
            {content}
        </Card>
    );
}

export default DeleteTopic;