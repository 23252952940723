import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Video} from "../../../models/Video";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {
    Badge,
    Icon, Tab, TabGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow, TabList
} from "@tremor/react";
import Card from "../../../ui/Card";
import {secondsToTimeString, stringToDateString} from "../../../utils/date";
import {formatNumber} from "../../../utils/numbers";
import {BarsArrowDownIcon, BarsArrowUpIcon, ClockIcon} from "@heroicons/react/20/solid";
import Chart, {DataPoint} from "./Chart";
import {Snapshot} from "../../../models/Snapshot";
import {vi} from "date-fns/locale";

type VideoStatsProps = {
    video: Video
};

type SortKey = "created_at" | "views" | "comments" | "likes" | "vs" | "erv" | "score"

type ChartColumn = "views" | "comments" | "likes" | "vs" | "erv" | "score" | "vg" | "lg" | "cg"

function VideoStats({video}: VideoStatsProps) {
    const columns = [
        "created_at",
        "views",
        "vs",
        "comments",
        "likes",
        "erv"
    ];

    const chartColumns: ChartColumn[] = [
        "vg",
        "views",
        "comments",
        "likes",
        "vs",
        "erv",
        "lg",
        "cg"
    ];

    const {t} = useTranslation();

    const cardRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);

    const [secondsToNextSnapshot, setSecondsToNextSnapshot] = useState(0);

    const [tooltipShown, setTooltipShown] =  useState(false);
    const [daily, setDaily] = useState(false);
    const [sortKey, setSortKey] = useState<SortKey>("created_at");
    const [sortDesc, setSortDesc] = useState(true);
    const [showAllSnaps, setShowAllSnaps] = useState(false);

    const hasDaily = useMemo(() => {
        return video.snapshots!.some((snap) => snap.serial_number % 24 === 0 && snap.serial_number != 0)
    }, [video])

    const hasHourly = useMemo(() => {
        return video.snapshots!.some((snap) => snap.serial_number % 24 != 0 || snap.serial_number == 0)
    }, [video])

    const filteredSnapshots = useMemo(() => {
        if (!video.snapshots) return [];

        let result = video.snapshots!.filter((snap) => {
            if (daily) {
                return snap.serial_number % 24 === 0 && snap.serial_number != 0;
            }

            return snap.serial_number <= 48;
        });

        // recalculation of derived parameters for first two daily snapshots
        if (daily) {
            let firstDay = result.find((snap) => snap.serial_number === 24);
            let secondDay = result.find((snap) => snap.serial_number === 48);

            if (!!firstDay && !!secondDay) {
                result = [
                    {
                        ...firstDay,
                        vg: firstDay.views,
                        lg: firstDay.likes,
                        cg: firstDay.comments,
                        vgr: 0,
                        lgr: 0,
                        cgr: 0
                    } as Snapshot,
                    {
                        ...secondDay,
                        vg: secondDay.views - firstDay.views,
                        lg: secondDay.likes - firstDay.likes,
                        cg: secondDay.comments - firstDay.comments,
                        vgr: (secondDay.views - firstDay.views) / firstDay.views * 100,
                        lgr: (secondDay.likes - firstDay.likes) / firstDay.likes * 100,
                        cgr: (secondDay.comments - firstDay.comments) / firstDay.comments * 100,
                    } as Snapshot,
                    ...result.filter((snap) => snap.serial_number !== 24 && snap.serial_number !== 48)
                ];
            } else {
                let firstSnap = result[result.length - 1];
                result = [
                    {
                        ...firstSnap,
                        vg: null,
                        lg: null,
                        cg: null,
                        vgr: null,
                        lgr: null,
                        cgr: null,
                    } as unknown as Snapshot,
                    ...result.filter((snap) => snap.id !== firstSnap.id)
                ];
            }
        }

        return result ? result : [];
    }, [video, daily]);

    const sortedValues = useMemo(() => {
        const result = filteredSnapshots.sort((a, b) => {
            if (sortKey === "created_at") {
                return (new Date(a[sortKey])).getTime() - (new Date(b[sortKey])).getTime();
            }

            return (a[sortKey] as number) - (b[sortKey] as number);
        });

        if (showAllSnaps) {
            if (sortDesc) return result.reverse();
            return result;
        }

        if (sortDesc) return result.reverse().slice(0, 3);
        return result.slice(0, 3);
    }, [filteredSnapshots, sortKey, sortDesc, daily, showAllSnaps]);

    const chartData = useMemo(() => {
        return filteredSnapshots.map((snap) => {
            let point: DataPoint = {};

            point["serial_number"] = snap.serial_number;

            if (daily) {
                point["created_at"] = (new Date(snap.created_at)).toLocaleDateString();
            } else {
                point["created_at"] = (new Date(snap.created_at)).toLocaleTimeString().split(":").slice(0, 2).join(":");
            }

            for (let coulmn of chartColumns) {
                point[coulmn] = snap[coulmn];
            }

            return point;
        }).sort((a, b) => (a["serial_number"] as number) - (b["serial_number"] as number));
    }, [filteredSnapshots, daily]);

    const shouldOverflow = useMemo(() => {
        if (!cardRef.current || !tableRef.current) return true;
        return Math.abs((cardRef.current!.offsetWidth - 32 * 2) - tableRef.current!.offsetWidth) < 7;
    }, [cardRef, tableRef]);

    const dailySnapsCount = useMemo(() => {
        return video.snapshots!.reduce((dailySnaps, snap) => {
            if (snap.serial_number % 24 === 0 && snap.serial_number !== 0) {
                dailySnaps += 1;
            }

            return dailySnaps;
        }, 0)
    }, [video]);

    const defaultToggleValue = useMemo(() => {
        if (!hasHourly) return 1;

        if (dailySnapsCount > 2) {
            return 1;
        }

        return 0;
    }, [hasHourly, dailySnapsCount]);

    const decrementTimer = () => {
        setSecondsToNextSnapshot(prevSeconds => prevSeconds - 1);
    }

    useEffect(() => {
        if (!filteredSnapshots.length) return;
        const latestSnap = filteredSnapshots[0];
        const snapDate = new Date(latestSnap.created_at);
        let nextSnapDate;

        if (!daily) {
            nextSnapDate = new Date(snapDate.getTime() + (3600 * 1000));
        } else {
            nextSnapDate = new Date(snapDate.getTime() + (24 * 3600 * 1000));
        }

        setSecondsToNextSnapshot((nextSnapDate.getTime() - (new Date()).getTime()) / 1000);

        const intervalId = setInterval(() => {
            decrementTimer();
        }, 1000)

        return () => {
            clearInterval(intervalId);
        }
    }, [filteredSnapshots, daily]);

    useEffect(() => {
        if (!hasHourly) {
            setDaily(true);
        }

        if (dailySnapsCount > 2) {
            setDaily(true);
        }
    }, [hasDaily, hasHourly, dailySnapsCount])

    function handleSortChange(key: SortKey) {
        if (sortKey === key) {
            setSortDesc(!sortDesc);
            return;
        }

        setSortDesc(true);
        setSortKey(key);

        return;
    }

    return (
        <WideCard className="mt-8">
            <div className="text-center text-lg mb-8 font-bold">{t("video_page.stats.title")}</div>
            <>
                {!video.shorts && <Card className="mb-8">
                    <TabGroup
                        defaultIndex={defaultToggleValue}
                        onIndexChange={(index) => {
                            if (hasHourly && index === 0) setDaily(false);
                            if (hasDaily && index === 1) setDaily(true);
                        }}
                    >
                        <TabList variant="solid">
                            <><Tab className={hasHourly ? "" : "cursor-not-allowed"} disabled={!hasHourly} value="0">{t("video_page.stats.by_hour")}</Tab></>
                            <><Tab className={hasDaily ? "" : "cursor-not-allowed"} disabled={!hasDaily} value="1">{t("video_page.stats.by_day")}</Tab></>
                        </TabList>
                    </TabGroup>
                </Card>
                }
            </>
            <Card>
                <Chart
                    chartData={chartData}
                    columns={chartColumns}
                    percentageColumns={["vs", "erv"]}
                    defaultColumn="vg"
                    daily={daily}
                />
            </Card>
            <Card className="mt-8" divRef={cardRef}>
                <Table ref={tableRef} className={shouldOverflow ? "overflow-x-hidden" : ""}>
                    <TableHead>
                        <TableRow>
                            {columns.map((el, i) => {
                                return <TableHeaderCell
                                    className="cursor-pointer text-slate-700"
                                    onClick={handleSortChange.bind(null, el as SortKey)}
                                    key={i}>
                                    <div className="flex items-bottom">
                                        {t("video_page.stats.table." + el)}
                                        <Icon
                                            style={{
                                                opacity: sortKey === el ? 1 : 0,
                                                visibility: sortKey === el ? "visible" : "hidden",
                                            }}
                                            size="xs"
                                            color="slate"
                                            icon={sortDesc ? BarsArrowDownIcon : BarsArrowUpIcon}
                                        />
                                    </div>
                                </TableHeaderCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {((daily && !sortedValues.find(el => el.serial_number === 720))
                                || (!daily && !sortedValues.find(el => el.serial_number === 48)))
                            && <TableRow>
                                <TableCell>
                                    {secondsToNextSnapshot && secondsToNextSnapshot > 0
                                        ? <div className="relative">
                                            <div
                                                style={{marginTop: -5}}
                                                className={"absolute text-center border border-slate-200 transition-all px-4 py-1 text-sm rounded-md bg-slate-100 -translate-y-1/2 translate-x-full z-50 top-1/2 -right-4 " + (tooltipShown ? "" : "opacity-0 invisible")}
                                            >
                                                {t("video_page.time_to_next")}
                                            </div>
                                            <Badge icon={ClockIcon} color="slate" size="xs"
                                                   className="mb-2.5 cursor-pointer" onMouseOver={() => {
                                                setTooltipShown(true)
                                            }} onMouseLeave={() => {
                                                setTooltipShown(false)
                                            }}>
                                                {secondsToTimeString(secondsToNextSnapshot)}
                                            </Badge>
                                        </div>
                                        : <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                    }
                                </TableCell>
                                <TableCell>
                                    <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                </TableCell>
                                <TableCell>
                                    <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                </TableCell>
                                <TableCell>
                                    <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                </TableCell>
                                <TableCell>
                                    <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                </TableCell>
                                <TableCell>
                                    <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                </TableCell>
                                <TableCell>
                                    <div className="animate-pulse h-2 bg-slate-200 rounded-full mb-2.5"></div>
                                </TableCell>
                            </TableRow>
                        }


                        {sortedValues.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell className="text-slate-700 align-top">
                                    {item.serial_number === 0
                                        ? <div className="mb-1">{t("video_page.stats.start")}</div>
                                        : <div
                                            className="mb-1">{daily ? item.serial_number / 24 : item.serial_number} {t("video_page.stats." + (daily ? "day_observation" : "hour_observation"))}</div>
                                    }
                                    <div style={{
                                        maxWidth: 50,
                                        whiteSpace: "break-spaces"
                                    }}>{stringToDateString(item.created_at)}</div>
                                </TableCell>
                                <TableCell className="text-slate-700 align-top">
                                    {formatNumber(item.views)}
                                    {item.vg ?
                                        <div
                                            className="text-slate-400 text-xxs">+{formatNumber(item.vg)} {item.vgr ? <>(+{formatNumber(item.vgr, true)})</> : <></>}</div>
                                        : ""
                                    }
                                </TableCell>
                                <TableCell
                                    className="text-slate-700 align-top">{formatNumber(item.vs, true)}</TableCell>
                                <TableCell className="text-slate-700 align-top">
                                    {formatNumber(item.comments)}
                                    {(item.cg && item.cg != 0) ?
                                        <div
                                            className="text-slate-400 text-xxs">{item.cg > 0 ? "+" : ""}{formatNumber(item.cg)} {item.cgr ? <>({item.cgr > 0 ? "+" : ""}{formatNumber(item.cgr, true)})</> : <></>}</div>
                                        : ""
                                    }
                                </TableCell>
                                <TableCell className="text-slate-700 align-top">
                                    {formatNumber(item.likes)}
                                    {(item.lg && item.lg != 0) ?
                                        <div
                                            className="text-slate-400 text-xxs">{item.lg > 0 ? "+" : ""}{formatNumber(item.lg)} {item.lgr ? <>({item.lgr > 0 ? "+" : ""}{formatNumber(item.lgr, true)})</> : <></>}</div>
                                        : ""
                                    }
                                </TableCell>
                                <TableCell
                                    className="text-slate-700 align-top">{formatNumber(item.erv, true)}</TableCell>
                                {/*<TableCell className="text-slate-700 align-top">{formatNumber(item.score)}</TableCell>*/}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <>
                    {!showAllSnaps && <div className="text-center text-sm text-blue-500 cursor-pointer" onClick={() => {setShowAllSnaps(true)}}>{t("video_page.show_all")}</div>}
                </>
            </Card>
        </WideCard>
    );
}

export default VideoStats;