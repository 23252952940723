import React, {SyntheticEvent, useState} from 'react';
import {Button, Icon, TextInput} from "@tremor/react";
import {useTranslation} from "react-i18next";
import {CheckCircleIcon, PlusIcon} from "@heroicons/react/20/solid";
import {createTopic} from "../http";
import {Topic} from "../../../models/Topic";
import Status from "../../../ui/Status";
import {useTopicsStore} from "../store";
import Card from "../../../ui/Card";

type CreateTopicProps = {
    setMode: Function
    setTopic: Function
    topic: Topic | undefined
}

function CreateTopic({topic, setTopic, setMode}: CreateTopicProps) {
    const {t} = useTranslation();

    const {topics, setTopics} = useTopicsStore();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');

    async function handleTopicCreate(event: SyntheticEvent) {
        event.preventDefault();

        if (title.length < 1) {
            setError(t("main_page.errors.empty_title").toString())
            return;
        } else {
            setError('');
        }

        setLoading(true);

        const res = await createTopic(title);
        if (res.status === 200) {
            setTopic(res.data);
            if (!!topics) {
                setTopics([res.data, ...topics]);
            } else {
                setTopics([res.data]);
            }
        } else {
            setError(t("errors.something_went_wrong").toString());
        }

        setLoading(false);
    }

    return (
        <Card>
            {!topic
                ? <form onSubmit={handleTopicCreate} className="max-w-md mx-auto flex items-start">
                    <div className="grow">
                        <TextInput
                            placeholder={t("main_page.topic_name").toString()}
                            onChange={(event) => setTitle(event.target.value)}
                            error={error != ''}
                            errorMessage={error}
                        />
                    </div>
                    <Button className="ml-4" loading={loading}>{t("main_page.done")}</Button>
                </form>
                : <div className="text-center">
                    <Status
                        icon={<Icon icon={CheckCircleIcon} color="green" size="xl"></Icon>}
                        headline={t("main_page.done")}
                        text={t("main_page.create_topic_success")}
                    />
                    <Button onClick={() => {setMode("edit")}} icon={PlusIcon} className="mt-4">{t("main_page.add_channels")}</Button>
                </div>
            }
        </Card>
    );
}

export default CreateTopic;