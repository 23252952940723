import React, {useState} from 'react';
import Card from "../../../ui/Card";
import Status from "../../../ui/Status";
import {Button, Icon} from "@tremor/react";
import {EnvelopeIcon} from "@heroicons/react/24/solid";
import {ArrowPathIcon} from "@heroicons/react/20/solid";
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import {resendEmailConfirmation} from "../http";
import {AxiosResponse} from "axios/index";

function EmailNotVerified() {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<AxiosResponse>();

    function handleResend() {
        setLoading(true);

        const resend = async () => {
            const response = await resendEmailConfirmation();
            setLoading(false);
            setResponse(response);
        }

        resend().catch((e) => {
            setLoading(false);
            setResponse(e.response);
        })
    }

    return (
        <WideCard className="mt-8">
            <Card className="flex items-center justify-center text-center flex-col gap-4">
                <Status
                    icon={<Icon icon={EnvelopeIcon} color="slate" size="xl" />}
                    headline={t("main_page.verify_email")}
                    text={t("main_page.verify_email_text", {email: JSON.parse(localStorage.getItem("user")!).email})}
                />

                {!response
                    ? <Button icon={ArrowPathIcon} loading={loading} onClick={handleResend}>{t("main_page.send_again")}</Button>
                    : (response.status === 200
                        ? <div className="text-sm text-slate-500">{t("main_page.email_send_success")}</div>
                        : <div className="text-sm text-slate-500">{t("main_page.email_send_fail")}</div>
                    )
                }
            </Card>
        </WideCard>
    );
}

export default EmailNotVerified;