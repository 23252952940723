import {LoaderFunctionArgs, redirect} from "react-router-dom";
import {uuidValid} from "../utils/regexp";
import {channelInfo} from "../modules/channel/http";

export async function channelLoader({params}: LoaderFunctionArgs) {
    if (!localStorage.getItem("access_token")) {
        return redirect("/auth/login");
    }

    if (!params.channelId || !uuidValid(params.channelId!)) {
        throw new Response("channel_not_found", { status: 404 });
    }

    try {
        const shorts = localStorage.getItem("shorts") == "true";
        const channel = await channelInfo(params.channelId, shorts);
        return channel.data
    } catch (e: any) {
        if (e.response.status === 402) return redirect("/");

        if (e.response.status == 403) {
            return e.response.data;
        } else if (e.response.status == 400) {
            throw new Response("channel_not_found", { status: 400 });
        } else {
            throw new Response("channel_not_found", { status: 404 });
        }
    }
}
