import React, {useState} from 'react';
import {CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Badge, Color} from "@tremor/react";
import ChartLegend from "./ChartLegend";
import {useTranslation} from "react-i18next";
import {
    ArrowTrendingUpIcon,
    ChatBubbleBottomCenterTextIcon,
    EyeIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon, ReceiptPercentIcon
} from "@heroicons/react/20/solid";
import {formatTick, formatTickPercentage} from "../../../utils/numbers";
import ChartTooltip from "./ChartTooltip";
import {BaseColors, hexColors, themeColorRange} from "../../../constants";

type ChartProps = {
    columns: string[]
    percentageColumns: string[]
    chartData: object[]
    defaultColumn: string
    daily: boolean
};


export type DataPoint = {
    [key: string]: number | string | null | undefined;
};

function Chart({chartData, columns, defaultColumn, percentageColumns, daily}: ChartProps) {
    const {t} = useTranslation();

    const [activeColumns, setActiveColumns] = useState([defaultColumn]);
    const [legendHeight, setLegendHeight] = useState(60);

    const categoryColors = constructCategoryColors(activeColumns, themeColorRange);

    return (
        <div>
            <div className="h-96 w-full">
                <ResponsiveContainer height="100%" width="100%">
                    <LineChart data={chartData} margin={{bottom: 20, left: 10}}>
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                            dataKey="created_at"
                            interval="preserveStartEnd"
                            tick={{ transform: "translate(0, 6)" }}
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter; Helvetica",
                            }}
                            tickLine={false}
                            axisLine={false}
                            padding={{ left: 10, right: 10 }}
                            minTickGap={5}
                            tickFormatter={(value, index) => {
                                // @ts-ignore
                                if (daily) return ((chartData[index]["serial_number"] as number) / 24).toString();
                                // @ts-ignore
                                return (chartData[index]["serial_number"] as number).toString();
                            }}
                        >
                            <Label value={daily ? t("channel_page.days").toString() : t("channel_page.hours").toString()} offset={0} position="bottom" className="text-xs" />
                        </XAxis>

                        <Legend
                            verticalAlign="top"
                            height={legendHeight}
                            content={({ payload }) => ChartLegend({ payload }, categoryColors, setLegendHeight)}
                        />

                        <YAxis
                            yAxisId="left"
                            width={62}
                            tick={{ transform: "translate(-3, 0)" }}
                            axisLine={false}
                            tickLine={false}
                            type="number"
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter; Helvetica",
                            }}
                            tickFormatter={formatTick}
                        />

                        <YAxis
                            hide={!activeColumns.some(e => percentageColumns.includes(e))}
                            yAxisId="right"
                            width={44}
                            orientation="right"
                            tick={{ transform: "translate(-3, 0)" }}
                            axisLine={false}
                            tickLine={false}
                            type="number"
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter; Helvetica",
                            }}
                            tickFormatter={formatTickPercentage}
                        />


                        {activeColumns.map((column) => (
                            <Line
                                yAxisId={(percentageColumns.includes(column)) ? "right" : "left"}
                                key={column}
                                name={column}
                                type="linear"
                                dataKey={column}
                                stroke={hexColors[categoryColors.get(column) ?? BaseColors.Gray]}
                                strokeWidth={2}
                                dot={true}
                                isAnimationActive={true}
                                connectNulls={true}
                            />
                        ))}

                        <Tooltip
                            wrapperStyle={{outline: "none"}}
                            content={({ active, payload, label }) => (
                                <ChartTooltip
                                    active={active}
                                    payload={payload}
                                    label={label}
                                    categoryColors={categoryColors}
                                />
                            )}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="mt-4 flex flex-wrap gap-4">
                {columns.map((el, i) => {
                    return <Badge
                        key={i}
                        className="cursor-pointer"
                        color={activeColumns.includes(el) ? "blue" : "neutral"}
                        icon={columnsIcons[el]}
                        onClick={() => {
                            if (activeColumns.includes(el)) {
                                setActiveColumns(activeColumns.filter(e => e !== el));
                                return;
                            }
                            setActiveColumns([...activeColumns, (el)]);
                        }}
                        size="md">
                        {t("video_page.stats.table." + el)}
                    </Badge>;
                })}
            </div>
        </div>
    );
}

const columnsIcons: {[key: string]: React.ElementType} = {
    "views": EyeIcon,
    "comments": ChatBubbleBottomCenterTextIcon,
    "likes": HandThumbUpIcon,
    "vs": ReceiptPercentIcon,
    "erv": FaceSmileIcon,
    "score": FireIcon,
    "vg": ArrowTrendingUpIcon,
    "lg": ArrowTrendingUpIcon,
    "cg": ArrowTrendingUpIcon,
}
const constructCategoryColors = (
    categories: string[],
    colors: Color[],
): Map<string, Color> => {
    const categoryColors = new Map<string, Color>();
    categories.forEach((category, idx) => {
        categoryColors.set(category, colors[idx]);
    });
    return categoryColors;
};
export default Chart;