import React, {useMemo} from 'react';
import {Link, Outlet, ScrollRestoration, useNavigation} from "react-router-dom";
import {Header} from "../modules/header"
import {useTranslation} from "react-i18next";
import {Subscription} from "../models/Subscription";
import WideCard from "../ui/WideCard";
import Card from "../ui/Card";
import {SubscribeModal} from "../modules/subscription";
import {Badge, Callout, Icon} from "@tremor/react";
import {stringToDateString} from "../utils/date";
import {ChartBarIcon, Cog6ToothIcon, CreditCardIcon, InformationCircleIcon} from "@heroicons/react/20/solid";
import {useModalStore} from "../store/modal";
import {User} from "../models/User";
import support from "/support.svg";

function AdminLayout() {
    const {t} = useTranslation();

    const navigation = useNavigation();

    const showLoading = useMemo(() => {
        return navigation.state === "loading";
    }, [navigation.state]);
    return (
        <>
            <main className="main min-h-screen flex flex-col">
                <Header/>
                <div className="container grow flex flex-col pt-8 pb-8">
                    <div className="flex">
                        <div className="basis-1/5">
                            <div className="divide-y overflow-y-auto bg-white border-gray-200 divide-gray-200 mb-1 rounded-md border transition-all shadow-sm mr-8">
                                <Link to="/admin/stats" className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">
                                    <Icon icon={ChartBarIcon} size="sm" color="gray" className="mr-3 p-0" />
                                    <p className="text-sm whitespace-nowrap truncate">{t("admin.menu.stats")}</p>
                                    <div className="p-1 py-0.5 ml-2 text-xs bg-blue-400 rounded-md text-white">new</div>
                                </Link>
                                <Link to="/admin/manage-subscriptions" className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">
                                    <Icon icon={CreditCardIcon} size="sm" color="gray" className="mr-3 p-0" />
                                    <p className="text-sm whitespace-nowrap truncate">{t("admin.menu.subscriptions")}</p>
                                </Link>
                            </div>
                        </div>
                        <div className="basis-4/5">
                            <Outlet/>
                        </div>
                    </div>
                </div>
                <ScrollRestoration/>
                <div
                    className={"fixed h-screen transition-all w-screen top-0 left-0 bg-slate-50 loadable loadable_fixed " + (!showLoading ? "opacity-0 invisible" : "loadable_active")}
                    style={{zIndex: 99999}}
                >
                </div>
                <footer className="py-5 border-t border-gray-200 mt-8">
                    <div className="container flex justify-between">
                        <div className="text-sm">
                            <p>ИП Коновалов Денис Юрьевич</p>
                            <p className="mt-2">ИНН 434582744451 </p>
                            <p className="mt-2">ОГРНИП: 316435000050879</p>
                        </div>
                        <div className="flex flex-col justify-center">
                            <Link target="_blank" className="text-sm text-blue-500" to="/user-agreement">{t("footer.public_offer")}</Link>
                            <Link target="_blank" className="text-sm text-blue-500 mt-2" to="/privacy-policy">{t("footer.privacy_policy")}</Link>
                        </div>
                        <div className="flex flex-col justify-center">
                            <a className="text-blue-500 text-sm flex items-center" href="https://www.youtube.com/@WiseWatcher_official/" target="_blank">Канал на Youtube
                                <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 461.001 461.001">
                                    <g>
                                        <path style={{fill:"#F61C0D"}} d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728   c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137   C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607   c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/>
                                    </g>
                                </svg>
                            </a>
                            <a className="text-blue-500 text-sm mt-2 flex items-center" href="https://t.me/wisewatcher_official" target="_blank">Канал в Telegram
                                <svg className="ml-2 w-4" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Livello_1" data-name="Livello 1" viewBox="0 0 240 240"><defs><linearGradient id="linear-gradient" x1="120" y1="240" x2="120" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#1d93d2"/><stop offset="1" stopColor="#38b0e3"/></linearGradient></defs><title>Telegram_logo</title><circle cx="120" cy="120" r="120" fill="url(#linear-gradient)"/><path d="M81.229,128.772l14.237,39.406s1.78,3.687,3.686,3.687,30.255-29.492,30.255-29.492l31.525-60.89L81.737,118.6Z" fill="#c8daea"/><path d="M100.106,138.878l-2.733,29.046s-1.144,8.9,7.754,0,17.415-15.763,17.415-15.763" fill="#a9c6d8"/><path d="M81.486,130.178,52.2,120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229,2.1-2.2,6.489-4.523,120.106-45.36,120.106-45.36s3.208-1.081,5.1-.362a2.766,2.766,0,0,1,1.885,2.055,9.357,9.357,0,0,1,.254,2.585c-.009.752-.1,1.449-.169,2.542-.692,11.165-21.4,94.493-21.4,94.493s-1.239,4.876-5.678,5.043A8.13,8.13,0,0,1,146.1,172.5c-8.711-7.493-38.819-27.727-45.472-32.177a1.27,1.27,0,0,1-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6,53.821-51.492c.108-.379-.3-.566-.848-.4-3.482,1.281-63.844,39.4-70.506,43.607A3.21,3.21,0,0,1,81.486,130.178Z" fill="#fff"/></svg>
                            </a>
                        </div>
                        <div className="flex flex-col justify-center items-start">
                            <Link target="_blank" className="text-sm text-blue-500 flex" to="https://t.me/wisewatcher_support">{t("footer.telegram_support")}
                                <svg className="ml-2 w-4" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Livello_1" data-name="Livello 1" viewBox="0 0 240 240"><defs><linearGradient id="linear-gradient" x1="120" y1="240" x2="120" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#1d93d2"/><stop offset="1" stopColor="#38b0e3"/></linearGradient></defs><title>Telegram_logo</title><circle cx="120" cy="120" r="120" fill="url(#linear-gradient)"/><path d="M81.229,128.772l14.237,39.406s1.78,3.687,3.686,3.687,30.255-29.492,30.255-29.492l31.525-60.89L81.737,118.6Z" fill="#c8daea"/><path d="M100.106,138.878l-2.733,29.046s-1.144,8.9,7.754,0,17.415-15.763,17.415-15.763" fill="#a9c6d8"/><path d="M81.486,130.178,52.2,120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229,2.1-2.2,6.489-4.523,120.106-45.36,120.106-45.36s3.208-1.081,5.1-.362a2.766,2.766,0,0,1,1.885,2.055,9.357,9.357,0,0,1,.254,2.585c-.009.752-.1,1.449-.169,2.542-.692,11.165-21.4,94.493-21.4,94.493s-1.239,4.876-5.678,5.043A8.13,8.13,0,0,1,146.1,172.5c-8.711-7.493-38.819-27.727-45.472-32.177a1.27,1.27,0,0,1-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6,53.821-51.492c.108-.379-.3-.566-.848-.4-3.482,1.281-63.844,39.4-70.506,43.607A3.21,3.21,0,0,1,81.486,130.178Z" fill="#fff"/></svg>
                            </Link>
                            <p className="text-sm mt-2">
                                Email: <a href="mailto:support@wisewatcher.ru" className="text-blue-500">support@wisewatcher.ru</a>
                            </p>
                        </div>
                    </div>
                </footer>
                <a href="https://t.me/wisewatcher_support" target="_blank" className="fixed flex flex-col items-center right-3 bottom-3">
                    <img src={support} alt="support" className="block"/>
                    <span className="text-xs">{t("header.menu.support")}</span>
                </a>
            </main>
        </>
    );
}

export default AdminLayout;