import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {useEffect, useState} from "react";
import Status from "./ui/Status";
import {Icon} from "@tremor/react";
import {ComputerDesktopIcon} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";

function App() {
    const {t} = useTranslation();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    }, []); // Empty dependency array ensures this runs on mount and not on updates

    if (isMobile) {
        return (
            <div className="bg-gray-50 h-screen w-screen p-8 flex items-center justify-center">
                <Status
                    icon={<Icon icon={ComputerDesktopIcon} color="slate" size="xl" />}
                    headline={t("mobile_page.heading")}
                    text={t("mobile_page.text")}
                />
            </div>
        );
    }

    return <RouterProvider router={router}></RouterProvider>
}

export default App

