import {LoaderFunctionArgs, redirect} from "react-router-dom";
import {uuidValid} from "../utils/regexp";
import {topicInfo} from "../modules/topic/http";
import {subscriptionInfo} from "../modules/subscription";

export async function topicLoader({params}: LoaderFunctionArgs) {
    if (!localStorage.getItem("access_token")) {
        return redirect("/auth/login");
    }

    if (!params.topicId || !uuidValid(params.topicId!)) {
        throw new Response("topic_not_found", { status: 404 });
    }

    try {
        const subscription = await subscriptionInfo();
        localStorage.setItem("subscription", JSON.stringify(subscription!.data.subscription))
        localStorage.setItem("plan", JSON.stringify(subscription!.data.plan))
        localStorage.setItem("channels_used", JSON.stringify(subscription!.data.channels_used))

        const shorts = localStorage.getItem("shorts") == "true";
        const topic = await topicInfo(params.topicId, shorts);
        return topic.data
    } catch (e: any) {
        if (e.response.status === 402) return redirect("/");

        if (e.response.status == 403) {
            throw new Response("topic_forbidden", { status: 403 });
        } else if (e.response.status == 400) {
            throw new Response("topic_not_found", { status: 400 });
        } else {
            throw new Response("topic_not_found", { status: 404 });
        }
    }
}
