import React, {MouseEventHandler, useMemo} from 'react';
import {Channel} from "../models/Channel";
import {Link} from "react-router-dom";
import {Icon} from "@tremor/react";
import {ArchiveBoxXMarkIcon, NoSymbolIcon} from "@heroicons/react/20/solid";

type ChannelCardProps = {
    channel: Channel
    bottomText?: JSX.Element | string
    onClick?: MouseEventHandler<HTMLAnchorElement>
    to?: string
}

function ChannelCard({channel, bottomText, onClick, to}: ChannelCardProps) {
    if (!channel.title) {
        return (
            <div className="block w-60 shrink-0 flex flex-col items-center text-center">
                <div className="animate-pulse w-32 h-32 bg-slate-200 rounded-full mb-4"></div>
                <div className="animate-pulse h-2 w-28 bg-slate-200 rounded-full"></div>
            </div>
        );
    }

    const avatar =  useMemo(() => {
        if (channel.deleted || channel.blocked) {
            return <>
                <div className="w-32 h-32 rounded-full bg-red-100 flex justify-center items-center">
                    <Icon icon={channel.blocked ? NoSymbolIcon : ArchiveBoxXMarkIcon} color="red" className="text-red-700" size="xl" />
                </div>
            </>;
        }

        if (!!channel.avatar) {
            return <img src={channel.avatar} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/person.svg";
            }} alt="avatar" className="block w-32 shrink-0 rounded-full"/>;
        }

        return <></>;
    }, [channel]);

    function defaultOnclick() {}

    return (
        <Link to={to ?? ("/channel/" + channel.id)} onClick={onClick ?? defaultOnclick} className="block w-60 shrink-0 flex flex-col items-center text-center">
            {avatar}
            <div className="text-sm mt-2 text-slate-700">{channel.title}</div>
            <div className="text-sm mt-1 text-slate-500">{bottomText}</div>
        </Link>
    );
}

export default ChannelCard;