import {redirect} from "react-router-dom";
import {subscriptionInfo} from "../modules/subscription";

export async function settingsLoader() {
    if (!localStorage.getItem("access_token")) {
        return redirect("/auth/login");
    }

    try {
        const subscription = await subscriptionInfo();
        localStorage.setItem("subscription", JSON.stringify(subscription!.data.subscription))
        localStorage.setItem("plan", JSON.stringify(subscription!.data.plan))
        localStorage.setItem("channels_used", JSON.stringify(subscription!.data.channels_used))
        return subscription.data
    } catch (e: any) {
        if (e.response.status === 402) return redirect("/settings");
        throw new Response("something_went_wrong", { status: 500 });
    }
}