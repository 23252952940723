import React, {useMemo, useState} from 'react';
import {Change} from "../models/Change";
import {stringToDateString} from "../utils/date";
import {useTranslation} from "react-i18next";
import Modal from "../ui/Modal";
import {Badge} from "@tremor/react";
import Card from "../ui/Card";
import {Link} from "react-router-dom";

type ChangeProps = {
    change: Change
    hideLink?: boolean
};

function ChangeCard({change, hideLink}: ChangeProps) {
    const {t} = useTranslation();

    const [shown, setShown] = useState(false);

    const modalContent = useMemo<JSX.Element>(() => {
        if (change.key === "preview") {
            return(
                <div className="grid grid-cols-2 gap-8">
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.old_value')}</div>
                        <img src={import.meta.env.VITE_BACKEND_URL + change.old_value[0]} alt=""/>
                    </Card>
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.new_value')}</div>
                        <img src={import.meta.env.VITE_BACKEND_URL + change.new_value[0]} alt=""/>
                    </Card>
                </div>
            );
        } else if (change.key === "tags") {
            return(
                <div className="grid grid-cols-2 gap-8">
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.old_value')}</div>
                        <div className="flex flex-wrap gap-2">
                            {change.old_value?.filter((el) => el).map((el, i) => {
                                return <div><Badge size="xs" key={i}>{el}</Badge></div>;
                            })}
                        </div>
                    </Card>
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.new_value')}</div>
                        <div className="flex flex-wrap gap-2">
                            {change.new_value?.filter((el) => el).map((el, i) => {
                                return <div><Badge size="xs" key={i}>{el}</Badge></div>;
                            })}
                        </div>
                    </Card>
                </div>
            );
        } else if (change.key === "description" || change.key === "title") {
            return(
                <div className="grid grid-cols-2 gap-8">
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.old_value')}</div>
                        <div>
                            {change.old_value?.map((el, i) => {
                                return <div key={i}>{el}</div>;
                            })}
                        </div>
                    </Card>
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.new_value')}</div>
                        <div>
                            {change.new_value?.map((el, i) => {
                                return <div key={i}>{el}</div>;
                            })}
                        </div>
                    </Card>
                </div>
            );
        } else if (change.key === "block" || change.key === "unblock") {
            return(
                <div className="grid grid-cols-2 gap-8">
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.old_value')}</div>
                        <div>
                            {change.key === "unblock" ? t("video_page.changes_names.block") : ""}
                        </div>
                    </Card>
                    <Card>
                        <div className="font-bold mb-4">{t('video_page.new_value')}</div>
                        <div>
                            {t("video_page.changes_names." + change.key)}
                        </div>
                    </Card>
                </div>
            );
        }


        return <div></div>;
    }, [change]);

    function handleClick() {
        setShown(true);
    }

    return (
        <div className="flex items-center mb-2">
            <div className="text-slate-500 text-sm"><pre>{stringToDateString(change.created_at)}</pre></div>
            {change.snapshot
                ? <div className="ml-2 mr-2 text-slate-500 text-sm">—</div>
                : <></>
            }
            {change.snapshot
                ? <div className="text-slate-500 text-sm block w-[32rem] truncate">
                    {change.snapshot.title}
                  </div>
                : <></>
            }
            <div className="ml-2 mr-2 text-slate-500 text-sm">—</div>
            <div className="text-blue-500 cursor-pointer text-sm" onClick={handleClick}>{t("video_page.changes_names." + change.key)}</div>
            <Modal shown={shown} close={() => setShown(false)}>
                <div className="font-bold mb-4">{t('video_page.changes_names.' + change.key)}</div>
                {modalContent}
                <>
                    {!hideLink &&
                        <Link to={"/video/" + change.video_id} className="block cursor-pointer text-center text-blue-500 text-sm mt-8">
                            {t("channel_page.go_to_video")}
                        </Link>
                    }
                </>
            </Modal>
        </div>
    );
}

export default ChangeCard;