import React, {useMemo, useState} from 'react';
import {Snapshot} from "../../../models/Snapshot";
import {Video} from "../../../models/Video";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {BadgeDelta, Callout, Icon} from "@tremor/react";
import {
    ArrowLongRightIcon,
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon, ClockIcon, LockClosedIcon, QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import CategoryBar, {MarkerColor} from "../../../components/CategoryBar";
import {formatNumber, median} from "../../../utils/numbers";
import Modal from "../../../ui/Modal";
import VideosTable from "../../../components/VideosTable";
import Status from "../../../ui/Status";
import {useModalStore} from "../../../store/modal";

type Field = "views" | "vs" | "erv" | "score";

type VideoRatingCardProps = {
    rating: Snapshot[] | null
    video: Video
    field: Field
    overall: boolean
    blocked?: boolean
}

function VideoRatingCard({rating, video, field, overall, blocked}: VideoRatingCardProps) {
    const {t} = useTranslation();

    const [tooltipShown, setTooltipShown] = useState(false);
    const [modalShown, setModalShown] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    const currentValue = useMemo(() => {
        return video.latest_snap![field];
    }, [video]);

    const values = useMemo(() => {
        if (!rating) {
            return [];
        }

        return rating.map((el) => el[field]);
    }, [rating]);

    const sortedValues = useMemo(() => {
        return values.sort((a, b) => a - b).filter(Number);
    }, [values]);

    const currentValueIndex = useMemo(() => {
        return sortedValues.findIndex((el) => el === currentValue);
    }, [currentValue, sortedValues]);

    const valuesMedian = useMemo(() => {
        return median(sortedValues);
    }, [sortedValues]);

    const markerPosition = useMemo(() => {
        return currentValueIndex / ((sortedValues.length - 1) / 100);
    }, [sortedValues, currentValue]);

    const stripeValues = useMemo(() => {
        if (!valuesMedian || !sortedValues || !sortedValues.length) {
            return [33.33, 33.33, 33.33];
        }

        let red = [];
        let yellow = [];
        let green = [];

        for (let el of sortedValues) {
            if (el < (valuesMedian - (valuesMedian / 100 * 5))) {
                red.push(el);
            } else if (el > (valuesMedian + (valuesMedian / 100 * 5))) {
                green.push(el);
            } else {
                yellow.push(el);
            }
        }

        return [red.length, yellow.length, green.length];
    }, [valuesMedian, sortedValues])

    const stripeWidths = useMemo(() => {
        return [
            (stripeValues[0] / sortedValues.length) * 100,
            (stripeValues[1] / sortedValues.length) * 100,
            (stripeValues[2] / sortedValues.length) * 100
        ];
    }, [stripeValues, sortedValues]);

    const markerColor = useMemo<MarkerColor>(() => {
        if (markerPosition == 0 && stripeWidths[0] == 0) return "yellow";
        // + 0.00001 to fix when stripeWidths are 33.33333 and 66.66666
        if (markerPosition < stripeWidths[0]) {
            return "rose";
        } else if (markerPosition > (stripeWidths[0] + stripeWidths[1] + 0.00001)) {
            return "emerald";
        }
        return "yellow";
    }, [stripeWidths, markerPosition]);

    const relativeToMedian = useMemo(() => {
        if (!valuesMedian) return 0;
        return currentValue / valuesMedian * 100 - 100;
    }, [valuesMedian, currentValue]);

    const badgeColor = useMemo(() => {
        if (markerColor === "emerald") {
            return "moderateIncrease";
        } else if (markerColor === "rose") {
            return "moderateDecrease";
        }

        return "unchanged";
    }, [markerColor]);

    const calloutTitleText = useMemo(() => {
        if (markerColor === "emerald") {
            return "video_page.rating.great";
        } else if (markerColor === "rose") {
            return "video_page.rating.awful";
        }

        return "video_page.rating.ok";
    }, [markerColor]);

    const calloutText = useMemo(() => {
        if (!video.latest_snap) {
            return "";
        }

        if (video.latest_snap?.serial_number % 24 === 0 && video.latest_snap?.serial_number != 0) {
            if (markerColor === "emerald") {
                return "video_page.rating.day." + field + ".great_text";
            } else if (markerColor === "rose") {
                return "video_page.rating.day." + field + ".awful_text";
            }

            return "video_page.rating.day." + field + ".ok_text";
        }

        if (markerColor === "emerald") {
            return "video_page.rating.hour." + field + ".great_text";
        } else if (markerColor === "rose") {
            return "video_page.rating.hour." + field + ".awful_text";
        }

        return "video_page.rating.hour." + field + ".ok_text";
    }, [video, field, markerColor]);

    const calloutSerialNumber = useMemo(() => {
        if (!video.latest_snap) return 0;

        return video.latest_snap!.serial_number % 24 === 0 ? video.latest_snap!.serial_number / 24 : video.latest_snap!.serial_number;
    }, [video]);

    const daily = useMemo(() => {
        if (!video.latest_snap) return false;

        return (video.latest_snap!.serial_number % 24 === 0 && video.latest_snap!.serial_number != 0)
    }, [video])

    const calloutIcon = useMemo(() => {
        if (markerColor === "emerald") {
            return ArrowTrendingUpIcon;
        } else if (markerColor === "rose") {
            return ArrowTrendingDownIcon;
        }

        return ArrowLongRightIcon;
    }, [markerColor]);

    if (blocked) {
        return <Card className="overflow-visible flex flex-col justify-center">
                <div className="text-slate-500 flex items-center text-left">
                    {t("video_page.rating." + field)}
                    <div className="relative">
                        <Icon
                            icon={QuestionMarkCircleIcon}
                            size="sm"
                            color="slate"
                            className="cursor-pointer"
                            onMouseLeave={() => {setTooltipShown(false)}}
                            onMouseOver={() => {setTooltipShown(true)}}
                        />
                        <div
                            className={"absolute drop-shadow-md border border-slate-200 text-center transition-all px-4 w-96 py-1 text-sm rounded-md bg-slate-100 -translate-x-1/2 " + (tooltipShown ? "" : "opacity-0 invisible")}
                            style={{left: "50%", bottom: "100%"}}
                        >
                            {t("video_page.rating.tooltips." + field)}
                        </div>
                    </div>

                </div>
            <div className="mb-16 mt-16 text-center cursor-pointer" onClick={() => {setSubscribeModalOpen(true, {limit: false, trial: true})}}>
                <Icon icon={LockClosedIcon} color="gray" size="xl"/>
            </div>
        </Card>;
    }

    if (!rating) {
        return (
            <Card className="loadable loadable_active flex flex-col">
                <Status
                    icon={<Icon icon={ClockIcon} color="slate" size="xl" />}
                    headline={t("channel_page.no_rating")}
                    text={t("channel_page.no_rating_text")}
                />
            </Card>
        );
    }

    return (
        <Card className="overflow-visible flex flex-col">
            <div className="text-slate-700 text-2xl font-bold text-center">
                {sortedValues.length - currentValueIndex} {t("video_page." + (overall ? "place_overall" : "place_on_this_channel"))}
            </div>
            <div className="text-blue-500 text-sm text-center mb-8 mt-4 cursor-pointer" onClick={() => {setModalShown(true)}}>
                {t("video_page.show_rating")}
            </div>
           <div className="flex justify-between mb-2">
               <div className="text-slate-500 flex items-center">
                   {t("video_page.rating." + field)}
                   <div className="relative">
                       <Icon
                           icon={QuestionMarkCircleIcon}
                           size="sm"
                           color="slate"
                           className="cursor-pointer"
                           onMouseLeave={() => {setTooltipShown(false)}}
                           onMouseOver={() => {setTooltipShown(true)}}
                       />
                       <div
                           className={"absolute drop-shadow-md border border-slate-200 text-center transition-all px-4 w-96 py-1 text-sm rounded-md bg-slate-100 -translate-x-1/2 " + (tooltipShown ? "" : "opacity-0 invisible")}
                           style={{left: "50%", bottom: "100%"}}
                       >
                           {t("video_page.rating.tooltips." + field)}
                       </div>
                   </div>
               </div>
               {/*{(!!video.latest_snap![field]) && <BadgeDelta size="xl" deltaType={badgeColor}>{relativeToMedian > 0 ? "+" : ""}{relativeToMedian.toFixed(0)}%</BadgeDelta>}*/}
           </div>
            <div className={(!video.latest_snap![field]) ? "flex grow flex-col justify-center" : ""}>
                {(!video.latest_snap![field]) && <Status
                    icon={<Icon icon={ClockIcon} color="slate" size="xl" />}
                    headline={t("channel_page.no_rating")}
                    text={t("channel_page.no_rating_text")}
                />}
                {(!!video.latest_snap![field]) && <>
                    <div className="text-4xl font-extrabold">{formatNumber((field === "views" || field === "score") ? Math.ceil(video.latest_snap![field]) : video.latest_snap![field], field === "vs" || field === "erv")}</div>
                    <Callout
                        className="mt-4 mb-16"
                        title={t(calloutTitleText)}
                        icon={calloutIcon}
                        color={markerColor}
                    >
                        {
                            t(calloutText, {postProcess: 'interval', percent: Math.abs(relativeToMedian).toFixed(0), count: calloutSerialNumber, serial: calloutSerialNumber}) +
                            t("video_page.rating." + (overall ? "on_other_channels" : "on_this_channel"))
                        }
                    </Callout>

                    <CategoryBar
                        sortedValues={sortedValues}
                        markerPosition={markerPosition}
                        markerColor={markerColor}
                        stripeValues={stripeValues}
                        tooltipText="category_bar.videos"
                        stripeWidths={stripeWidths}
                        currentValue={(field === "views" || field === "score") ? Math.ceil(currentValue) : currentValue}
                        medianValue={(field === "views" || field === "score") ? Math.ceil(valuesMedian!) : valuesMedian!}
                    />

                    <Modal shown={modalShown} close={() => {setModalShown(false)}}>
                        <div className="text-center text-lg mb-8 font-bold">
                            {t("video_page.modal.heading_" + field, {serial: calloutSerialNumber, day_hour: daily ? t("channel_page.day").toString() : t("channel_page.hour").toString()})}
                        </div>
                        {modalShown ?
                            <VideosTable
                                snapshots={rating!}
                                initialSortingKey={field}
                                tableColumns={["preview", "title", "views", "vs", "erv", "score"]}
                                highlightId={video.latest_snap!.id}
                            /> : <></>
                        }
                    </Modal>
                </>}
            </div>
        </Card>
    );
}

export default VideoRatingCard;