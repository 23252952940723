import React, {SyntheticEvent, useState} from 'react';
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {Button, Icon, TextInput} from "@tremor/react";
import {KeyIcon} from "@heroicons/react/24/solid";
import {passwordValid} from "../../../utils/regexp";
import {changePassword} from "../http";
import Status from "../../../ui/Status";
import {CheckCircleIcon} from "@heroicons/react/20/solid";

function ChangePassword() {
    const {t} = useTranslation();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordAgain, setNewPasswordAgain] = useState('');
    const [error, setError] = useState('');
    const [incorrectError, setIncorrectError] = useState('');
    const [matchError, setMatchError] = useState('');
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        setLoading(true);

        if (newPassword != newPasswordAgain) {
            setMatchError(t("settings_page.errors.mismatch").toString());
            return;
        } else {
            setMatchError('');
        }

        if (!passwordValid(newPassword)) {
            setIncorrectError(t("settings_page.errors.incorrect_password").toString());
            return;
        } else {
            setIncorrectError('');
        }

        const change = async () => {
            await changePassword(oldPassword, newPassword);
            setLoading(false);
            setDone(true);
        };

        change().catch((e) => {
            setError(t("settings_page.errors.invalid_password").toString());
            setLoading(false);
        });
    }

    return (
        <div>
            <div className="text-lg font-bold text-center mb-8">{t("settings_page.change_password")}</div>
            <>
                {
                    done
                    ? <Status
                            icon={<Icon icon={CheckCircleIcon} size="xl" color="green" />}
                            headline={t("settings_page.done")}
                            text={t("settings_page.change_password_success")}
                      />
                    : <form className="max-w-md mx-auto flex flex-col items-center text-left" onSubmit={handleSubmit}>
                            <TextInput
                                onChange={(event) => setOldPassword(event.target.value)}
                                icon={KeyIcon}
                                type="password"
                                className="mb-2"
                                error={error != ''}
                                errorMessage={error}
                                placeholder={t("settings_page.old_password").toString()}
                            />
                            <TextInput
                                onChange={(event) => setNewPassword(event.target.value)}
                                icon={KeyIcon}
                                type="password"
                                className="mt-2 mb-2"
                                error={incorrectError != ''}
                                errorMessage={incorrectError}
                                placeholder={t("settings_page.new_password").toString()}
                            />
                            <TextInput
                                onChange={(event) => setNewPasswordAgain(event.target.value)}
                                icon={KeyIcon}
                                type="password"
                                error={matchError != ''}
                                errorMessage={matchError}
                                className="mt-2 mb-2"
                                placeholder={t("settings_page.new_password_again").toString()}
                            />

                            <Button loading={loading} className="mt-2">{t("settings_page.done")}</Button>
                    </form>
                }
            </>
        </div>
    );
}

export default ChangePassword;