import React, {useState} from 'react';
import {Icon} from "@tremor/react";
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";

type TopicCopyProps = {
    topic: {
        title: string;
        youtube_urls: string;
    }
}
function TopicCopy({topic}: TopicCopyProps) {
    const {t} = useTranslation();

    const [channelsCopied, setChannelsCopied] = useState(false);

    return (
        <div className="flex justify-between items-center">
            <div className="">{topic.title}</div>
            <div className="text-slate-500 cursor-pointer flex items-center" onClick={() => {
                navigator.clipboard.writeText(topic.youtube_urls).then(() => {setChannelsCopied(true);});
            }}>
                <Icon
                    icon={channelsCopied ? ClipboardDocumentCheckIcon : ClipboardDocumentIcon}
                    color="slate"
                />
                <span className="text-xs">{t(channelsCopied ? "subscription_manager_page.channels_copied" : "subscription_manager_page.copy_channels")}</span>
            </div>
        </div>
    );
}

export default TopicCopy;