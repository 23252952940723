import {Color} from "@tremor/react";

export const BaseColors: { [key: string]: Color } = {
    Slate: "slate",
    Gray: "gray",
    Zinc: "zinc",
    Neutral: "neutral",
    Stone: "stone",
    Red: "red",
    Orange: "orange",
    Amber: "amber",
    Yellow: "yellow",
    Lime: "lime",
    Green: "green",
    Emerald: "emerald",
    Teal: "teal",
    Cyan: "cyan",
    Sky: "sky",
    Blue: "blue",
    Indigo: "indigo",
    Violet: "violet",
    Purple: "purple",
    Fuchsia: "fuchsia",
    Pink: "pink",
    Rose: "rose",
};

export const themeColorRange: Color[] = [
    BaseColors.Cyan,
    BaseColors.Sky,
    BaseColors.Blue,
    BaseColors.Indigo,
    BaseColors.Violet,
    BaseColors.Purple,
    BaseColors.Fuchsia,
    BaseColors.Slate,
    BaseColors.Gray,
    BaseColors.Zinc,
    BaseColors.Neutral,
    BaseColors.Stone,
    BaseColors.Red,
    BaseColors.Orange,
    BaseColors.Amber,
    BaseColors.Yellow,
    BaseColors.Lime,
    BaseColors.Green,
    BaseColors.Emerald,
    BaseColors.Teal,
    BaseColors.Pink,
    BaseColors.Rose,
];

export const hexColors: { [color: string]: string } = {
    [BaseColors.Slate]: "#64748b",
    [BaseColors.Gray]: "#6b7280",
    [BaseColors.Zinc]: "#71717a",
    [BaseColors.Neutral]: "#737373",
    [BaseColors.Stone]: "#78716c",
    [BaseColors.Red]: "#ef4444",
    [BaseColors.Orange]: "#f97316",
    [BaseColors.Amber]: "#f59e0b",
    [BaseColors.Yellow]: "#eab308",
    [BaseColors.Lime]: "#84cc16",
    [BaseColors.Green]: "#22c55e",
    [BaseColors.Emerald]: "#10b981",
    [BaseColors.Teal]: "#14b8a6",
    [BaseColors.Cyan]: "#06b6d4",
    [BaseColors.Sky]: "#0ea5e9",
    [BaseColors.Blue]: "#3b82f6",
    [BaseColors.Indigo]: "#6366f1",
    [BaseColors.Violet]: "#8b5cf6",
    [BaseColors.Purple]: "#a855f7",
    [BaseColors.Fuchsia]: "#d946ef",
    [BaseColors.Pink]: "#ec4899",
    [BaseColors.Rose]: "#f43f5e",
    [BaseColors.Black]: "#000000",
    [BaseColors.White]: "#ffffff",
};
