import React from 'react';
import {ResetForm} from "../modules/authentication";

function ResetPage() {
    return (
        <div>
            <ResetForm/>
        </div>
    );
}

export default ResetPage;