import { create } from 'zustand';

type State = {
    shorts: boolean
}

type Action = {
    setShorts:  (shorts: boolean) => void
}

export const useShortsStore = create<State & Action>((set) => ({
    shorts: localStorage.getItem("shorts") === "true",
    setShorts: (shorts: boolean) => {
        localStorage.setItem("shorts", String(shorts));
        set(() => ({shorts: shorts}));
    },
}))