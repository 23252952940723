import React, {useEffect, useMemo, useState} from 'react';
import WideCard from "../../../ui/WideCard";
import {useTranslation} from "react-i18next";
import Card from "../../../ui/Card";
import {Partner} from "../../../models/Partner";
import {createUserRefAccount, getUserRefAccount, getUserReferralSubscriptions} from "../../../http/referral";
import {Button, Icon, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow} from "@tremor/react";
import {ClipboardDocumentCheckIcon, ClipboardDocumentIcon, ClipboardIcon} from "@heroicons/react/20/solid";
import Modal from "../../../ui/Modal";
import {ReferralSubscription} from "../../../models/ReferralSubscription";
import {stringToDateString} from "../../../utils/date";

function Referral() {
    const {t} = useTranslation();

    const [partner, setPartner] = useState<Partner>();
    const [changesHistory, setChangesHistory] = useState<ReferralSubscription[]>([]);
    const [linkCopied, setLinkCopied] = useState(false);
    const [modalShown, setModalShown] = useState(false);

    useEffect(() => {
        const fetchPartner = async () => {
            const result = await getUserRefAccount();
            setPartner(result.data);
            if (!!result.data) {
                const result = await getUserReferralSubscriptions();
                setChangesHistory(result.data);
            }
        }

        fetchPartner().catch((e) => {
            //
        })
    }, []);

    function createPartner() {
        const createRef = async () => {
            const result = await createUserRefAccount();
            setPartner(result.data);
        }

        createRef().catch((e) => {
            //
        })
    }

    function handleLinkCopy() {
        if (!!partner) {
            navigator.clipboard.writeText(`https://app.wisewatcher.net/auth/register?partner_id=${partner.id}`).then(() => {
                setLinkCopied(true);
                console.log(123);
                setTimeout(() => {
                    setLinkCopied(false);
                }, 2000);
            });
        }
    }

    const content = useMemo(() => {
        if (!!partner) {
            return <div className="grid grid-cols-2">
                <div>
                    <div className="font-bold mb-4">{t("settings_page.referral.current_balance", {balance: partner.balance})}</div>
                    <div className="text-sm text-slate-500 mb-2">{t("settings_page.referral.users_registered", {count: partner.user_count})}</div>
                    <div className="text-sm text-slate-500 mb-2">{t("settings_page.referral.paid_subscriptions", {count: partner.subscription_count})}</div>
                    {(!!changesHistory && changesHistory.length != 0) && <div className="text-xs text-blue-500 cursor-pointer" onClick={() => {setModalShown(true)}}>{t("settings_page.referral.changes_history")}</div>}
                </div>
                <div className="flex justify-center items-center">
                    <div className="border border-slate-200 rounded-md flex items-center">
                        <div className="text-sm text-slate-500 max-w-xs overflow-x-hidden p-2 pl-4">
                        <span style={{// @ts-ignore
                            textWrap: "nowrap"}}>https://app.wisewatcher.net/auth/register?partner_id={partner.id}</span>
                        </div>
                        <div className="h-[50px] w-[1px] bg-slate-200"></div>
                        <div className="divide-x-2 h-full pl-2 pr-2 flex-shrink-0 cursor-pointer">
                            <Icon icon={linkCopied ? ClipboardDocumentCheckIcon: ClipboardDocumentIcon} color="slate" onClick={handleLinkCopy}/>
                        </div>
                    </div>
                </div>
                <Modal widthClass="max-w-4xl" shown={modalShown} close={() => {setModalShown(false)}}>
                    <div className="text-lg font-bold text-center mb-8">{t("settings_page.referral.changes_history")}</div>
                    <Table className="mt-5">
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>{t("settings_page.referral.table.date")}</TableHeaderCell>
                                <TableHeaderCell>{t("settings_page.referral.table.email")}</TableHeaderCell>
                                <TableHeaderCell>{t("settings_page.referral.table.price")}</TableHeaderCell>
                                <TableHeaderCell>{t("settings_page.referral.table.bonuses")}</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!changesHistory ? changesHistory.map((el, i) => (
                                <TableRow key={i}>
                                    <TableCell>{stringToDateString(el.paid_at)}</TableCell>
                                    <TableCell>{el.email}</TableCell>
                                    <TableCell>{el.price}₽</TableCell>
                                    <TableCell><div className="text-green-500">+{el.bonuses}</div></TableCell>
                                </TableRow>
                            )) : <></>}
                        </TableBody>
                    </Table>
                </Modal>
            </div>
        }


        return <div className="grid grid-cols-2">
            <div>
                <div className="font-bold mb-4">{t("settings_page.referral.current_balance", {balance: 0})}</div>
                <div className="text-sm text-slate-500 mb-2">{t("settings_page.referral.users_registered", {count: 0})}</div>
                <div className="text-sm text-slate-500 mb-2">{t("settings_page.referral.paid_subscriptions", {count: 0})}</div>
            </div>
            <div className="flex justify-center items-center">
                <Button onClick={createPartner}>{t("settings_page.referral.generate_link")}</Button>
            </div>
        </div>
    }, [partner, linkCopied, modalShown, changesHistory])

    return (
        <WideCard className="mt-8 mb-8">
            <div className="text-lg font-bold text-center mb-8">{t("settings_page.referral.heading")}</div>
            <Card>
                <div className="font-bold mb-4">{t("settings_page.referral.how_it_works")}</div>
                <div className="text-sm text-slate-500">{t("settings_page.referral.how_it_works_text")}</div>
            </Card>
            <Card className="mt-8">
                {content}
            </Card>
        </WideCard>
    );
}

export default Referral;