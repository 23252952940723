import React, {useMemo} from 'react';
import {Outlet, useNavigation} from "react-router-dom";
import classes from "./AuthLayout.module.scss";
import {Card, Flex} from "@tremor/react";
import bigLogo from "/big_logo.svg";
import {useTranslation} from "react-i18next";

function AuthLayout() {
    const {t} = useTranslation();
    const navigation = useNavigation();

    const showLoading = useMemo(() => {
        return navigation.state === "loading";
    }, [navigation.state]);

    return (
        <div className={classes.authPage + " bg-slate-50"}>
            <div className="container">
                <Card className="h-[400px] flex items-center">
                    <Flex>
                        <div className="banner flex justify-center basis-6/12 flex-col items-center text-center">
                            <img src={bigLogo} alt="big logo" className="block w-60 mb-4"/>
                            <div className="text-xl font-bold text-slate-700 mb-8 uppercase">{t('auth.analytics')}</div>
                            <div className="text-md text-slate-500 uppercase">{t('auth.catch_phrase')}</div>
                        </div>
                        <div className="content basis-5/12">
                            <Outlet/>
                        </div>
                    </Flex>
                </Card>
            </div>
            <div
                className={"fixed h-screen transition-all w-screen top-0 left-0 bg-slate-50 loadable loadable_fixed " + (!showLoading ? "opacity-0 invisible" : "loadable_active")}
                style={{zIndex: 99999}}
            >
            </div>
        </div>
    );
}

export default AuthLayout;