import React from 'react';
import {BaseMetrics, PlanFactGraph, SubscriptionDistribution} from "../modules/admin";

function AdminStats() {
    return (
        <>
            <BaseMetrics/>
            <PlanFactGraph/>
            <SubscriptionDistribution/>
        </>
    );
}

export default AdminStats;