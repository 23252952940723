import React, {useMemo} from 'react';
import {Channel} from "../../../models/Channel";
import WideCard from "../../../ui/WideCard";
import VideosTable from "../../../components/VideosTable";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import Status from "../../../ui/Status";
import {ClockIcon} from "@heroicons/react/20/solid";
import {Icon} from "@tremor/react";
import {useShortsStore} from "../../../store/shorts";

type ChannelVideosProps = {
    channel: Channel
}

function ChannelVideos({channel}: ChannelVideosProps) {
    const {t} = useTranslation();

    const { shorts } = useShortsStore();

    const videoSnaps = useMemo(() => {
        if (!channel.videos) return [];
        return channel.videos!.map((el) => el.latest_snap!)
    }, [channel])

    if (!channel.videos || (channel.videos && !channel.videos.length)) {
        return (
            <WideCard className="mt-8">
                <Card>
                    <Status
                        icon={<Icon icon={ClockIcon} color="slate" size="xl" />}
                        headline={t("channel_page.no_videos")}
                        text={t(shorts ? "channel_page.no_shorts_text" : "channel_page.no_videos_text")}
                    />
                </Card>
            </WideCard>
        )
    }

    return (
        <WideCard className="mt-8">
            <div className="text-center text-lg mb-8 font-bold">{t("channel_page.observing_videos_heading")}</div>
            <Card>
                <VideosTable
                    snapshots={videoSnaps}
                    tableColumns={["preview", "title", "views", "vs", "erv"]}
                    periodUnderTitle={true}
                />
            </Card>
        </WideCard>
    );
}

export default ChannelVideos;