import {AxiosResponse} from 'axios'
import $api from "../../../http";
import {GetUserSubscriptionsResponse} from "../../../models/GetUserSubscriptionsResponse";
import {SubscriptionDistributionResponse} from "../../../models/SubscriptionDistributionResponse";
import {BaseMetricsResponse} from "../../../models/BaseMetricsResponse";
import {PlanFactGraphResponse} from "../../../models/PlanFactGraphResponse";

export function getUserSubscriptions(email: string): Promise<AxiosResponse<GetUserSubscriptionsResponse>> {
    return $api.post<GetUserSubscriptionsResponse>('admin/subscriptions/get', {
        email: email
    })
}

export function giveSubscription(userID: string, planID: string, startDate: string, endDate: string, price: number): Promise<AxiosResponse<GetUserSubscriptionsResponse>> {
    return $api.post<GetUserSubscriptionsResponse>('admin/subscriptions/give', {
        user_id: userID,
        plan_id: planID,
        start_date: startDate,
        end_date: endDate,
        price: price,
    })
}

export function resetSubscription(userID: string): Promise<AxiosResponse> {
    return $api.post('admin/subscriptions/reset', {
        user_id: userID,
    })
}

export function getSubscriptionDistribution(): Promise<AxiosResponse<SubscriptionDistributionResponse[]>> {
    return $api.get<SubscriptionDistributionResponse[]>('admin/stats/distribution')
}

export function getBaseMetrics(): Promise<AxiosResponse<BaseMetricsResponse>> {
    return $api.get<BaseMetricsResponse>('admin/stats/base')
}

export function getPlanFactGraph(from: string, to: string): Promise<AxiosResponse<PlanFactGraphResponse[]>> {
    return $api.get<PlanFactGraphResponse[]>(`admin/stats/plan?from=${from}&to=${to}`)
}