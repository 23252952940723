export const stringToDateString = (dateString: string) :string => {
    let date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", {day: '2-digit', month: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
}

export const secondsToTimeString = (seconds: number) :string => {
    if (seconds > 3600) {
        return new Date(seconds * 1000).toISOString().slice(11, 19);
    }

    return new Date(seconds * 1000).toISOString().slice(14, 19);
}

export function getLastDayOfMonth(year: number, month: number) {
    return new Date(year, month + 1, 0);
}

export function getFirstDayOfMonth(year: number, month: number) {
    return new Date(year, month, 1);
}

export function dateObjectToDateString(date: Date) {
    return `${date.getFullYear().toString()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}