import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Icon} from "@tremor/react";
import {
    ArrowLeftOnRectangleIcon, BookmarkIcon, ChartPieIcon, CheckIcon, ChevronDownIcon,
    Cog6ToothIcon,
    CreditCardIcon, LanguageIcon,
    LifebuoyIcon,
    UserCircleIcon
} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {Plan} from "../../../models/Plan";
import {stringToDateString} from "../../../utils/date";
import {Subscription} from "../../../models/Subscription";
import {User} from "../../../models/User";
import {SubscribeModal} from "../../subscription";
import {useLanguageStore} from "../../../store/language";

function HeaderMenu() {
    const {t} = useTranslation();

    const {language, setLanguage} = useLanguageStore();

    const navigate = useNavigate();

    const lngSwitcherRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    const [lngSwitcherShown, setLngSwitcherShown] = useState(false);
    const [menuShown, setMenuShown] = useState(false);
    const [modalShown, setModalShown] = useState(false);

    function close() {
        setModalShown(false);
    }

    const subscriptionColor = useMemo(() => {
        if (Boolean(!!localStorage.getItem("subscription") && JSON.parse(localStorage.getItem("subscription")!))) {
            let currentSubscription = JSON.parse(localStorage.getItem("subscription")!) as Subscription;
            let currentSubscriptionExpires = new Date(currentSubscription.expires_at);
            let currentTime = new Date();

            let currentPlanDaysLeft = Math.max(0, (currentSubscriptionExpires.getTime() - currentTime.getTime()) / (1000 * 60 * 60 * 24));

            if (currentPlanDaysLeft <= 1) {
                return "text-red-500";
            } else if (currentPlanDaysLeft <= 3) {
                return "text-yellow-500";
            }
        }

        return "text-green-500";
    }, []);

    const hasSubscription = useMemo(() => {
        if (Boolean(!!localStorage.getItem("subscription") && JSON.parse(localStorage.getItem("subscription")!))) return true;
        if (!!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).role) return true;
        return false;
    }, []);


    function handleClick(event: MouseEvent) {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuShown(false);
        }
        if (lngSwitcherRef.current && !lngSwitcherRef.current.contains(event.target as Node)) {
            setLngSwitcherShown(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    function handleLogout() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("user");
        localStorage.removeItem("subscription");
        localStorage.removeItem("plan");
        localStorage.removeItem("channels_used");
        localStorage.removeItem("shorts");
        try {
            navigate("/auth/login");
        } catch (e) {
            window.location.reload();
        }
    }

    return (
        <div className="relative flex items-center w-full justify-end">
            {/*{(!!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) && (JSON.parse(localStorage.getItem("user")!) as User).email_verified && hasSubscription) &&*/}
            {/*    <Link to="/favourites" className="flex items-center"><Icon icon={BookmarkIcon} size="lg" color="slate" className="p-0 mr-4" /></Link>*/}
            {/*}*/}

            <div className="relative">
                <div
                    className="text-md text-slate-600 cursor-pointer mr-4 flex items-center"
                    onClick={() => {setLngSwitcherShown(!lngSwitcherShown);}}
                    ref={lngSwitcherRef}
                >
                    {language == 'ru' ? "RU" : "EN"}
                    <Icon icon={ChevronDownIcon} size="xs" color="slate" style={{padding: 0, marginBottom: -3}}/>
                </div>
                <div className={"absolute z-10 divide-y overflow-y-auto w-64 top-full left-0 bg-white border-gray-200 divide-gray-200 mt-1 mb-1 rounded-md border transition-all shadow-lg " + (lngSwitcherShown ? "visible opacity-100" : "invisible opacity-0")} >
                    <div onClick={() => {setLanguage('en')}} className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700 cursor-pointer">
                        {language == 'en' && <Icon icon={CheckIcon} size="sm" color="gray" className="mr-3 p-0" />}
                        English
                    </div>
                    <div onClick={() => {setLanguage('ru')}} className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700 cursor-pointer">
                        {language == 'ru' && <Icon icon={CheckIcon} size="sm" color="gray" className="mr-3 p-0" />}
                        Русский
                    </div>
                </div>
            </div>

            {(!!localStorage.getItem("plan") && !!localStorage.getItem("subscription") && !!JSON.parse(localStorage.getItem("plan")!) && !!JSON.parse(localStorage.getItem("subscription")!)) && <div onClick={() => {setModalShown(true);}} className="text-xs text-slate-500 mr-8 cursor-pointer flex align-center">
                {/*<Icon icon={CreditCardIcon} size="lg" color="slate" className="p-0 mr-4" />*/}
                <div className="">
                    <div className="mb-1">{t("settings_page.subscription_channels", {count: (JSON.parse(localStorage.getItem("plan")!) as Plan)!.channel_count, channelCount: (localStorage.getItem("channels_used") ? localStorage.getItem("channels_used") : '0') })}</div>
                    <div className={subscriptionColor}>{t("settings_page.subscription_expires_short")} {stringToDateString((JSON.parse(localStorage.getItem("subscription")!) as Subscription)!.expires_at).split(" ")[0]}</div>
                </div>

            </div>}
            {(!!localStorage.getItem("plan") && !!localStorage.getItem("subscription") && !!JSON.parse(localStorage.getItem("plan")!) && !!JSON.parse(localStorage.getItem("subscription")!)) &&
                <SubscribeModal shown={modalShown} close={close} />
            }
            {!!localStorage.getItem("user") && !!JSON.parse(localStorage.getItem("user")!).role &&
                <div className="text-green-500 font-bold mr-8">VIP</div>
            }

            <Link to="/favourites" className="mt-1 p-0" style={{padding: 0}}>
                <Icon icon={BookmarkIcon} size="sm" color="gray" className="mr-2 p-0" />
            </Link>

            <div ref={menuRef} className="text-sm flex items-center cursor-pointer select-none" onClick={() => {setMenuShown(!menuShown)}}>
                <Icon icon={UserCircleIcon} size="xl" className="p-0" color="slate" />
            </div>

            <div className={"absolute z-10 divide-y overflow-y-auto w-64 top-full right-0 bg-white border-gray-200 divide-gray-200 mt-1 mb-1 rounded-md border transition-all shadow-lg " + (menuShown ? "visible opacity-100" : "invisible opacity-0")} >
                {!!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) && (JSON.parse(localStorage.getItem("user")!) as User).email_verified &&
                    <Link to="/settings" className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">
                        <Icon icon={Cog6ToothIcon} size="sm" color="gray" className="mr-3 p-0" />
                        <p className="text-sm whitespace-nowrap truncate">{t("header.menu.settings")}</p>
                    </Link>
                }
                {/*{(!!localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) && (JSON.parse(localStorage.getItem("user")!) as User).email_verified && hasSubscription) &&*/}
                {/*    <Link to="/favourites" className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">*/}
                {/*        <Icon icon={BookmarkIcon} size="sm" color="gray" className="mr-3 p-0" />*/}
                {/*        <p className="text-sm whitespace-nowrap truncate">{t("header.menu.favourites")}</p>*/}
                {/*    </Link>*/}
                {/*}*/}
                {(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")!) && (JSON.parse(localStorage.getItem("user")!) as User).role == 2) &&
                    <Link to="/admin/stats" className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">
                        <Icon icon={ChartPieIcon} size="sm" color="gray" className="mr-3 p-0" />
                        <p className="text-sm whitespace-nowrap truncate">{t("header.menu.dashboard")}</p>
                    </Link>
                }
                <Link to="https://t.me/wisewatcher_support" target="_blank" className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">
                    <Icon icon={LifebuoyIcon} size="sm" color="gray" className="mr-3 p-0" />
                    <p className="text-sm whitespace-nowrap truncate">{t("header.menu.support")}</p>
                </Link>
                <button type="button" onClick={handleLogout} className="flex items-center justify-start w-full truncate px-4 py-2.5 text-sm hover:bg-gray-50 text-gray-700">
                    <Icon icon={ArrowLeftOnRectangleIcon} size="sm" color="red" className="mr-3 p-0" />
                    <p className="text-sm text-red-500 whitespace-nowrap truncate">{t("auth.logout")}</p>
                </button>
            </div>
        </div>
    );
}

export default HeaderMenu;