import {AxiosResponse} from "axios";
import {Video} from "../../../models/Video";
import $api from "../../../http/index";
import {ChannelVideosRating} from "../../../models/ChannelVideosRating";
import {Change} from "../../../models/Change";

export function videoInfo(videoId: string): Promise<AxiosResponse<Video>> {
    return $api.get<Video>(`video/${videoId}`)
}

export function videoChanges(videoId: string): Promise<AxiosResponse<Change[]>> {
    return $api.get<Change[]>(`changes/video/${videoId}`)
}