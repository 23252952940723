import React, { useRef } from "react";

import useOnWindowResize from "../../../hooks/useOnWindowResize";

import { Color, Legend } from "@tremor/react";
import {useTranslation} from "react-i18next";

const ChartLegend = (
    { payload }: any,
    categoryColors: Map<string, Color>,
    setLegendHeight: React.Dispatch<React.SetStateAction<number>>,
) => {
    const {t} = useTranslation();
    const legendRef = useRef<HTMLDivElement>(null);

    useOnWindowResize(() => {
        const calculateHeight = (height: number | undefined) =>
            height
                ? Number(height) + 20 // 20px extra padding
                : 60; // default height
        setLegendHeight(calculateHeight(legendRef.current?.clientHeight));
    });

    return (
        <div ref={legendRef} className="flex items-center justify-end">
            <Legend
                categories={payload.map((entry: any) => t("video_page.stats.table." + entry.value))}
                colors={payload.map((entry: any) => categoryColors.get(entry.value))}
            />
        </div>
    );
};

export default ChartLegend;