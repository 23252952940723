import React, {useEffect} from 'react';
import Status from "../ui/Status";
import {Icon} from "@tremor/react";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getCookie, setCookie} from "../utils/cookie";

function SubscriptionSuccessPage() {
    const {t} = useTranslation();

    useEffect(() => {
        if (!getCookie("subscription_payment")) {
            // @ts-ignore
            window['ym'](94300532,'reachGoal','subscription_payment');
            setCookie("subscription_payment", "true", 1);
        }
    }, []);

    return (
        <div className="h-screen w-screen flex items-center justify-center">
             <div className="text-center">
                 <Status
                     icon={<Icon icon={CheckCircleIcon} size="xl" color="green" />}
                     headline={t("subscription_page.success")}
                     text={t("subscription_page.success_text")}
                 />
                 <Link to="/" className="text-sm text-blue-500">{t("subscription_page.home")}</Link>
             </div>
        </div>
    );
}

export default SubscriptionSuccessPage;