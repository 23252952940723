import React, {useEffect, useMemo, useState} from 'react';
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {Badge, Button, Callout, Icon, TextInput} from "@tremor/react";
import {getTelegramToken, linkTelegramBot} from "../http";
import Status from "../../../ui/Status";
import {
    ArrowPathIcon,
    CheckCircleIcon,
    InformationCircleIcon,
    LockClosedIcon,
    XCircleIcon
} from "@heroicons/react/20/solid";
import {KeyIcon} from "@heroicons/react/24/solid";
import {Plan} from "../../../models/Plan";
import {useModalStore} from "../../../store/modal";

function TelegramBot() {
    const {t} = useTranslation();

    const [error, setError] = useState('');
    const [errorText, setErrorText] = useState('');
    const [isLinked, setIsLinked] = useState(false);
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    useEffect(() => {
        const fetchToken = async () => {
            const res = await getTelegramToken();
            if (!!res && !!res.data && !!res.data.token) {
                setIsLinked(true);
            }
        }

        fetchToken().catch((e) => {
            setError("something_went_wrong");
            setError("something_went_wrong_text");
        })
    }, [])

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();

        setLoading(true);

        const linkTelegram = async (tok: string) => {
            const res = await linkTelegramBot(tok);
            if (!!res && !!res.status && res.status == 200) {
                setIsLinked(true);
            }
            setLoading(false);
        }

        linkTelegram(token).catch((e) => {
            setError("wrong_token");
            setErrorText("wrong_token_text");
            setLoading(false);
        })
    }

    const isPlanTrial = useMemo(() => {
        if (!localStorage.getItem("plan")) return false;
        const plan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        if (!plan) return false;
        return plan.is_trial && plan.channel_count == 25;
    }, [])

    const content = useMemo(() => {
        if (isPlanTrial) {
            return <Card className="flex justify-center">
                <div className="cursor-pointer mt-2 mb-2" onClick={() => {setSubscribeModalOpen(true, {limit: false, trial: true})}}>
                    <Icon icon={LockClosedIcon} color="gray" size="lg"/>
                </div>
            </Card>;
        }

        if (isLinked) {
            return (
                <>
                    <Card>
                        <Status
                            icon={<Icon icon={CheckCircleIcon} size="xl" color="green" />}
                            headline={t("settings_page.telegram_bot.success")}
                            text={t("settings_page.telegram_bot.success_text")}
                        />
                    </Card>
                </>
            );
        }

        if (error != '') {
            return (
                <>
                    <Card className="flex flex-col items-center">
                        <Status
                            icon={<Icon icon={XCircleIcon} size="xl" color="red" />}
                            headline={t("settings_page.telegram_bot." + error)}
                            text={t("settings_page.telegram_bot." + errorText)}
                        />
                        <>
                            {error === "wrong_token" && <Button className="mt-4" icon={ArrowPathIcon} onClick={() => {
                                setError('');
                                setErrorText('');
                            }}>{t("settings_page.telegram_bot.try_again")}</Button>}
                        </>
                    </Card>
                </>
            );
        }

            return (
                <Card>
                    <Callout
                        title={t("settings_page.telegram_bot.instruction")}
                        color="green"
                        icon={InformationCircleIcon}
                        className="mb-8"
                    >
                        {t("settings_page.telegram_bot.instruction_text")}
                    </Callout>
                    <div className="text-center mb-4">
                        <a href="https://t.me/wisewatcher_bot" target="_blank" className="text-blue-500 text-sm">{t("settings_page.telegram_bot.get_key")}</a>
                    </div>
                    <form className="max-w-md mx-auto flex flex-col items-center text-left" onSubmit={handleSubmit}>
                        <TextInput
                            onChange={(event) => setToken(event.target.value)}
                            icon={KeyIcon}
                            className="mb-4"
                            error={error != ''}
                            errorMessage={error}
                            placeholder={t("settings_page.telegram_bot.token").toString()}
                        />
                        <Button type="submit" loading={loading}>{t("settings_page.telegram_bot.i_have_token")}</Button>
                    </form>
                </Card>
            );

    }, [isLinked, error, errorText, loading, token, isPlanTrial]);

    return (
        <WideCard className="mt-8 mb-8">
            <div id="telegram" className="text-lg font-bold text-center mb-8">{t("settings_page.telegram_bot.heading")}</div>
            {content}
        </WideCard>
    );
}

export default TelegramBot;