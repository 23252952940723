import React from 'react';
import {Topic} from "../../../models/Topic";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {stringToDateString} from "../../../utils/date";
import WideCard from "../../../ui/WideCard";
import {formatNumber} from "../../../utils/numbers";

type TopicInfoProps = {
    topic: Topic
}

function TopicInfo({topic}: TopicInfoProps) {
    const {t} = useTranslation();
    return (
        <WideCard className="mt-8 mb-8">
            <Card>
                <div className="font-bold mb-2">{t("topic_page.topic")}: {topic.title}</div>
                <div className="text-slate-400">{t("channel_page.start_of_observation")}: {stringToDateString(topic.created_at)}</div>
                <div className="text-slate-400 mt-2">{t("topic_page.channels_count")}: {formatNumber(topic.channels_count!)}</div>
                <div className="text-slate-400 mt-2">{t("topic_page.videos_count")}: {formatNumber(topic.videos_count!)}</div>
            </Card>
        </WideCard>
    );
}

export default TopicInfo;