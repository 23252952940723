import React, {SyntheticEvent, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Icon, TextInput} from "@tremor/react";
import {KeyIcon} from "@heroicons/react/24/solid";
import classes from "./AuthForm.module.scss";
import {Link} from "react-router-dom";
import Status from "../../../ui/Status";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {resetPassword} from "../http";
import {passwordValid} from "../../../utils/regexp";

function ResetForm() {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [matchError, setMatchError] = useState('');

    const status = useMemo(() => {
        if (!responseCode) {
            return <></>;
        } else if (responseCode === 200) {
            return <>
                <Status
                    icon={<Icon icon={CheckCircleIcon} color="green" size="xl" />}
                    headline={t("auth.done")}
                    text={t("auth.reset_success")}
                />
                <div className="mt-2 text-center text-sm">
                    {t("auth.back_to_login")}&nbsp;
                    <Link to="/auth/login" className="link">{t("auth.login")}.</Link>
                </div>
            </>;
        } else if (responseCode === 401) {
            return <>
                <Status
                    icon={<Icon icon={XCircleIcon} color="red" size="xl" />}
                    headline={t("errors.error")}
                    text={t("auth.errors.reset_link_expired")}
                />
                <div className="mt-2 text-center text-sm">
                    <Link to="/auth/forgot" className="link">{t("auth.try_again")}.</Link>
                </div>
            </>;
        } else {
            return <>
                <Status
                    icon={<Icon icon={XCircleIcon} color="red" size="xl" />}
                    headline={t("errors.error")}
                    text={t("errors.something_went_wrong")}
                />
                <div className="mt-2 text-center text-sm">
                    <Link to="/auth/login" className="link">{t("auth.back_to_login")}.</Link>
                </div>
            </>;
        }
    }, [responseCode])

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        if (password !== passwordAgain) {
            setMatchError(t("auth.errors.do_not_match").toString());
            return;
        }

        if (!passwordValid(password)) {
            setPasswordError(t("auth.errors.incorrect_password").toString());
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        setLoading(true);

        const reset = async () => {
            const res = await resetPassword(password, token!);
            setLoading(false);
            setResponseCode(res.status);
        };

        reset().catch((e) => {
            setLoading(false);
            setResponseCode(e.response.status);
        })
    }

    return (
        <div>
            {!responseCode && <>
                <h2 className="text-2xl font-bold leading-7 text-center text-gray-900 mb-4">{t("auth.reset_password")}</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    <TextInput onChange={(event) => setPassword(event.target.value)} icon={KeyIcon} placeholder={t("auth.new_password").toString()}
                               className="" type="password" error={passwordError != ''}
                               errorMessage={passwordError}/>

                    <TextInput onChange={(event) => setPasswordAgain(event.target.value)} icon={KeyIcon} placeholder={t("auth.new_password_again").toString()}
                               className="mt-4" type="password" error={matchError != ''}
                               errorMessage={matchError}/>

                    <Button size="lg" variant="primary" className="w-full mt-4 mb-4" color="blue" loading={loading}>
                        {t("auth.done")}
                    </Button>
                </form>
                <div className={classes["auth-option"]}>
                    {t("auth.back_to_login")}&nbsp;
                    <Link to="/auth/login" className="link">{t("auth.login")}.</Link>
                </div>
            </>}

            {status}
        </div>
    );
}

export default ResetForm;