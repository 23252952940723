import React, {useState} from 'react';
import {Channel} from "../../../models/Channel";
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import {InformationCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {Button, Callout, Icon, Select, SelectItem} from "@tremor/react";
import {useTranslation} from "react-i18next";
import ChannelCard from "../../../components/ChannelCard";
import {addChannelToTopic} from "../../topic/http";
import {AxiosError} from "axios";
import {useModalStore} from "../../../store/modal";
import Status from "../../../ui/Status";

type AddChannelToTopicProps = {
    channel: Channel
}

function AddChannelToTopic({channel}: AddChannelToTopicProps) {
    const {t} = useTranslation();

    const [topicId, setTopicId] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const {setSubscribeModalOpen} = useModalStore();

    async function handleAddChannel() {
        if (!topicId) return;
        setLoading(true);

        try {
            await addChannelToTopic(topicId, channel.id);
            window.location.reload();
            // @ts-ignore
        } catch (e: AxiosError) {
            if (!!e.response && e.response.status === 409) {
                setSubscribeModalOpen(true, {limit: false, trial: false});
            } else if (!!e.response && e.response.status === 402) {
                setSubscribeModalOpen(true, {limit: false, trial: false, replacementLimit: true});
            } else {
                setError(true);
            }
        }

        setLoading(false);
    }

    if (error) {
        return <WideCard>
            <Card>
                <Status
                    icon={<Icon icon={XCircleIcon} size="xl" color="red" />}
                    headline={t("errors.error")}
                    text={t("errors.something_went_wrong")}
                />
            </Card>
        </WideCard>;
    }

    return (
        <WideCard className="overflow-visible mt-8">
            <Card className="flex flex-col items-center overflow-visible">
                <Callout
                    title={t("channel_page.not_added")}
                    icon={InformationCircleIcon}
                    color="yellow"
                    className="w-full mb-8"
                >
                    {t("channel_page.not_added_text")}
                </Callout>
                <div className="mb-8">
                    <ChannelCard
                        channel={channel}
                        bottomText={channel.subscriptions + " " + t("channel_page.subscribers")}
                    />
                </div>
                <div className="w-80 mb-4">
                    <Select
                        onValueChange={(value) => setTopicId(value)}
                        placeholder={t("channel_page.choose_topic")!}
                    >
                        {channel.topics!.map((el, i) => {
                            return <SelectItem key={i} value={el.id}>{el.title}</SelectItem>;
                        })}
                    </Select>
                </div>
                <Button disabled={!topicId} onClick={handleAddChannel} loading={loading}>{t("channel_page.add")}</Button>
            </Card>
        </WideCard>
    );
}

export default AddChannelToTopic;