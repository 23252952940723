import React from 'react';
import {ForgotForm} from "../modules/authentication";

function ForgotPage() {
    return (
        <div>
            <ForgotForm/>
        </div>
    );
}

export default ForgotPage;