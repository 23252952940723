import React, {useEffect} from 'react';
import {Link, useLoaderData} from "react-router-dom";
import Status from "../ui/Status";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {Icon} from "@tremor/react";
import {useTranslation} from "react-i18next";
import {getCookie, setCookie} from "../utils/cookie";

function VerifyPage() {
    const data = useLoaderData() as number;

    const {t} = useTranslation();

    useEffect(() => {
        if (data === 200) {
            if (!getCookie("account_verified")) {
                // @ts-ignore
                window['ym'](94300532,'reachGoal','account_verified');
                setCookie("account_verified", "true", 1);
            }
        }
    }, [data]);

    return (
        <div className="h-screen w-screen flex items-center justify-center">
            {
                data === 200
                ? <div className="text-center">
                    <Status
                        icon={<Icon icon={CheckCircleIcon} size="xl" color="green" />}
                        headline={t("auth.verify.done")}
                        text={t("auth.verify.done_text")}
                    />
                    <Link to="/" className="text-sm text-blue-500">{t("auth.verify.home")}</Link>
                </div>
                : <Status
                        icon={<Icon icon={XCircleIcon} size="xl" color="red" />}
                        headline={t("auth.verify.error")}
                        text={t("auth.verify.error_text")}
                  />
            }
        </div>
    );
}

export default VerifyPage;