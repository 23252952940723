import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Snapshot} from "../../../models/Snapshot";
import {favouriteVideos} from "../http";
import WideCard from "../../../ui/WideCard";
import Card from "../../../ui/Card";
import SnapshotCard from "../../../components/SnapshotCard";
import Modal from "../../../ui/Modal";
import VideosTable from "../../../components/VideosTable";
import Status from "../../../ui/Status";
import {Icon} from "@tremor/react";
import {ClockIcon, XCircleIcon} from "@heroicons/react/20/solid";

function FavouriteVideosList() {
    const {t} = useTranslation();

    const shouldFetch = useRef(true);
    const observerTarget = useRef(null);

    const [end, setEnd] = useState(false);
    const [error, setError] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snaps, setSnaps] = useState<Snapshot[]>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchData = async (page: number) => {
        const results = await favouriteVideos(page);

        if (!results.status || results.status != 200) {
            setLoading(false);
            setError(true);
            return;
        }

        if (results.data.length < 15) {
            setEnd(true);
        }

        setSnaps([...snaps, ...results.data]);
        setLoading(false);
        setCurrentPage(page + 1);
    };

    function removeVideoFromTable(videoId: string) {
        setSnaps([...snaps.filter((el) => el.video_id != videoId)]);
    }

    useEffect(() => {
        setInitialLoading(true);

        if (shouldFetch.current) {
            shouldFetch.current = false;
            fetchData(currentPage).catch((e) => {
                setInitialLoading(false);
                setError(true);
            });
        }

        setInitialLoading(false);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && !end) {
                    setLoading(true);
                    fetchData(currentPage).catch((e) => {
                        setLoading(false);
                        setError(true);
                    });
                }
            },
            { threshold: 1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, end, currentPage]);

    if (!snaps.length) {
        return (
            <WideCard className="mt-8">
                <Card>
                    <Status
                        icon={<Icon icon={ClockIcon} color="slate" size="xl" />}
                        headline={t("favourites_page.no_videos")}
                        text={t("favourites_page.no_videos_text")}
                    />
                </Card>
            </WideCard>
        )
    }

    return (
        <WideCard>
            <div className="text-center text-lg mb-8 font-bold">{t("favourites_page.favourite_videos")}</div>
            <Card className={"loadable " + (initialLoading ? "loadable_active" : "")} >
                <>
                    {!snaps.length && error && <Status
                        icon={<Icon icon={XCircleIcon} color="red" size="xl"  />}
                        text={t("errors.something_went_wrong")}
                        headline={t("errors.error")}
                    />}
                </>
                <VideosTable
                    snapshots={snaps}
                    favouritesPage={true}
                    favouritesRemoveCallback={removeVideoFromTable}
                    tableColumns={["preview", "title", "views", "vs", "erv", "score"]}
                />
                <div ref={observerTarget} className={"py-4 loadable " + (loading ? "loadable_active": "")}></div>
            </Card>
        </WideCard>
    );
}

export default FavouriteVideosList;