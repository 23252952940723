import React, {useEffect, useMemo, useState} from 'react';
import Modal from "../../../ui/Modal";
import {Plan} from "../../../models/Plan";
import {availablePlans, downgradeTopics, getPaymentLink} from "../http";
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {Button, Callout, Switch} from "@tremor/react";
import {Subscription} from "../../../models/Subscription";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {stringToDateString} from "../../../utils/date";
import {Topic} from "../../../models/Topic";
import DeleteTopicChannels from "./DeleteTopicChannels";
import {useTopicsStore} from "../../topic";
import {useLanguageStore} from "../../../store/language";
import {Partner} from "../../../models/Partner";
import {getUserRefAccount} from "../../../http/referral";
type SubscribeModalProps = {
    shown: boolean
    close: Function
    trial?: boolean
    limit?: boolean
    replacementLimit?: boolean
}

type PaymentMethod = "stripe" | "robokassa"

function SubscribeModal({shown, close, trial, limit, replacementLimit}: SubscribeModalProps) {
    const {t} = useTranslation();

    const {topics, setTopics} = useTopicsStore();
    const {isRussian} = useLanguageStore();

    const [loading, setLoading] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);
    const [modalShown, setModalShown] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("robokassa");
    const [plans, setPlans] = useState<Plan[]>([]);
    const [chosenPlan, setChosenPlan] = useState<Plan>();
    const [daysToAdd, setDaysToAdd] = useState(0);
    const [partner, setPartner] = useState<Partner>();
    const [topicToEdit, setTopicToEdit] = useState<Topic>();

    const [useBonuses, setUseBonuses] = useState(false);

    const [tooltipShown, setTooltipShown] = useState(false);

    let currentSubscription = JSON.parse(localStorage.getItem("subscription")!) as Subscription;

    const advantages = {
        25: [
            { text: "subscription_page.advantages.without_shorts", cross: true },
            { text: "subscription_page.advantages.limit", new: false },
            { text: "subscription_page.advantages.telegram_bot", new: false },
            { text: "subscription_page.advantages.partner_program", new: false }
        ],
        50: [
            { text: "subscription_page.advantages.shorts", new: true },
            { text: "subscription_page.advantages.increased_limit", new: true },
            { text: "subscription_page.advantages.telegram_bot", new: false },
            { text: "subscription_page.advantages.partner_program", new: false }
        ],
        100: [
            { text: "subscription_page.advantages.shorts", new: true },
            { text: "subscription_page.advantages.increased_limit", new: true },
            { text: "subscription_page.advantages.telegram_bot", new: false },
            { text: "subscription_page.advantages.partner_program", new: false }
        ]
    };

    useEffect(() => {
        // if (!isRussian) {
        //     setPaymentMethod('stripe');
        // }
    }, [isRussian]);

    useEffect(() => {
        setLoading(true);
        const fetchPlans = async () => {
            const res = await availablePlans();
            setLoading(false);
            setPlans(res.data);
            const channelsUsed = parseInt(localStorage.getItem("channels_used")!);
            const sortedPlans = [...res.data.sort((a, b) => a.channel_count - b.channel_count)];
            for (let i = 1; i < sortedPlans.length; i++) {
                if (sortedPlans[i - 1].channel_count <= channelsUsed && channelsUsed <= sortedPlans[i].channel_count) {
                    setChosenPlan(sortedPlans[i]);
                    setButtonActive(true);
                }
            }
        }

        const fetchPartner = async () => {
            const result = await getUserRefAccount();
            setPartner(result.data);
        }

        fetchPartner().catch((e) => {
            // TODO handle an error
        })

        fetchPlans().catch((e) => {
            // TODO handle an error
        })
    }, []);

    useEffect(() => {
        if (!!chosenPlan) {
            setButtonActive(true);
        }

        let currentPlan = JSON.parse(localStorage.getItem("plan")!) as Plan;
        let currentSubscription = JSON.parse(localStorage.getItem("subscription")!) as Subscription;
        let channelsUsed = parseInt(localStorage.getItem("channels_used")!);

        if (!!currentSubscription && !!chosenPlan && currentPlan.id != chosenPlan.id) {
            let currentPlanDailyCost = currentPlan.price / 30;
            let newPlanDailyCost = chosenPlan.price / 30;

            let currentSubscriptionExpires = new Date(currentSubscription.expires_at);
            let currentTime = new Date();

            let currentPlanDaysLeft = Math.max(0, (currentSubscriptionExpires.getTime() - currentTime.getTime()) / (1000 * 60 * 60 * 24));

            let result = Math.max(0, Math.round((currentPlanDaysLeft * currentPlanDailyCost) / newPlanDailyCost));

            setDaysToAdd(result);
        } else {
            setDaysToAdd(0);
        }

        if (!!chosenPlan && chosenPlan.channel_count < channelsUsed) {
            setButtonActive(false);
            const fetchTopics = async () => {
                const res = await downgradeTopics();
                setTopics(res.data);
            }

            fetchTopics();
        }
    }, [chosenPlan]);

    const showTopics = useMemo(() => {
        let channelsUsed = parseInt(localStorage.getItem("channels_used")!);
        return !!chosenPlan && chosenPlan.channel_count < channelsUsed;
    }, [chosenPlan])

    const payForSubscription = async () => {
        if (!chosenPlan) return;
        setLoading(true);
        const paymentLink = await getPaymentLink(chosenPlan.id, paymentMethod == "stripe", useBonuses);
        if (paymentLink.data) window.location.replace(paymentLink.data);
    }

    return (
        <Modal
            shown={shown}
            close={close}
            className={"text-center loadable " + (loading ? "loadable_active" : "")}
            widthClass="sm:max-w-6xl"
            // modalStyle={{
            //     overflowY: "visible !important",
            //     maxHeight: "calc(100vh - 32px)",
            // }}
            zIndex={10005}
        >
            <div className="text-lg font-bold text-center mb-8">{t("settings_page.plan_choice")}</div>
            <>
                {trial && <Callout
                        title={t("subscription_page.trial")}
                        color="green"
                        icon={InformationCircleIcon}
                        className="text-left mb-8"
                    >
                        {t("subscription_page.trial_text")}
                    </Callout>
                }
            </>
            <>
                {limit && <Callout
                    title={t("subscription_page.limit")}
                    color="yellow"
                    icon={InformationCircleIcon}
                    className="text-left mb-8"
                >
                    {t("subscription_page.limit_text")}
                </Callout>
                }
            </>
            <>
                {replacementLimit && <Callout
                    title={t("subscription_page.replacement_limit")}
                    color="yellow"
                    icon={InformationCircleIcon}
                    className="text-left mb-8"
                >
                    <span className="blue-links" dangerouslySetInnerHTML={{__html: t("subscription_page.replacement_limit_text", {count: (JSON.parse(localStorage.getItem("plan")!) as Plan).channel_count * 3})!}}></span>
                </Callout>
                }
            </>
            <>
                {(!!chosenPlan?.discount_price && chosenPlan?.discount_price !== 0) && <Callout
                    title={t("subscription_page.discount")}
                    color="green"
                    icon={InformationCircleIcon}
                    className="text-left mb-8"
                >
                    {t("subscription_page.discount_text")}
                </Callout>
                }
            </>
            {
                (daysToAdd !== 0 && !showTopics)
                ? <Callout
                    title={t("subscription_page.changing_plan")}
                    color="green"
                    icon={InformationCircleIcon}
                    className="text-left mb-8"
                >
                    {t("subscription_page.changing_plan_text", {currentExpires: stringToDateString(currentSubscription.expires_at), count: daysToAdd})}
                </Callout> : <></>
            }
            {
                showTopics
                    ? <Callout
                        title={t("subscription_page.too_many_channels")}
                        color="yellow"
                        icon={InformationCircleIcon}
                        className="text-left mb-8"
                    >
                        {t("subscription_page.too_many_channels_text")}
                        &nbsp;
                        {topics.map((el, i) => {
                            return <React.Fragment key={i}>
                                <span
                                    className="text-blue-500 cursor-pointer mb-2"
                                    onClick={() => {
                                        setTopicToEdit(el);
                                        setModalShown(true);
                                    }}
                                >
                                    {el.title}{i === topics.length - 1 ? "" : ", "}
                                </span>
                            </React.Fragment>
                        })}
                        {topicToEdit ? <DeleteTopicChannels
                            shown={modalShown}
                            close={() => {setModalShown(false);}}
                            topic={topicToEdit}
                            topics={topics}
                            setTopics={setTopics}
                            setTopic={setTopicToEdit}
                        /> : <></>}
                    </Callout> : <></>
            }
            <div className="grid grid-cols-3 gap-8 max-w-4xl mx-auto">
                {plans?.map((el, i) => {
                    // @ts-ignore
                    return <div onClick={() => {setChosenPlan(el)}} key={i}>
                        <Card
                            className={"relative text-left cursor-pointer" + (chosenPlan?.id === el.id ? " card_green" : "")}
                            style={{padding: 24, overflow: "visible"}}
                        >
                            {el.channel_count == 50 ? <div className="absolute left-1/2 top-0 px-3 py-2 rounded-md" style={{transform: 'translate(-50%, -50%)', background: '#FFFBEB', color: '#B45309'}}>
                                <div className="text-xs">{t("subscription_page.popular")}</div>
                            </div> : <></>}
                            <>
                                {isRussian
                                    ? <div className="text-sm text-slate-500 mb-2">
                                        {(!!el.discount_price && el.discount_price !== 0)
                                            ? <><s>{el.price}₽</s>  <b>{el.discount_price}₽</b> {`(-${100 - Math.round(((el.discount_price / el.price) * 100))}%)`} {t("settings_page.subscription_price")}</>
                                            : <>{el.price}₽ {t("settings_page.subscription_price")}</>
                                        }
                                    </div>
                                    : <div className="text-sm text-slate-500 mb-2">
                                        {(!!el.discount_price_usd && el.discount_price_usd !== 0)
                                            ? <><s>{el.price_usd}€</s>  <b>{el.discount_price_usd}€</b> {`(-${100 - Math.round(((el.discount_price_usd / el.price_usd) * 100))}%)`} {t("settings_page.subscription_price")}</>
                                            : <>{el.price_usd}€ {t("settings_page.subscription_price")}</>
                                        }
                                    </div>
                                }
                            </>
                            <div className="text-xl font-bold">{t("settings_page.subscription_channels_count", {count: el.channel_count})}</div>
                            <div className="vantages">
                                {
                                    //@ts-ignore
                                    [25, 50, 100].includes(el.channel_count) && advantages[el.channel_count].map((ad) => {
                                    return <div className="mt-4 flex align-baseline">
                                        <div className="w-6 shrink-0 mr-2" style={{marginBottom: -100}}>
                                            {
                                                ad.cross ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         viewBox="0 0 16 16" fill="none">
                                                        <g clip-path="url(#clip0_43_4)">
                                                            <path
                                                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                                                fill="#FFE4E6"/>
                                                            <path
                                                                d="M10.2426 10.2426L8.12132 8.12132M8.12132 8.12132L6 6M8.12132 8.12132L6 10.2426M8.12132 8.12132L10.2426 6"
                                                                stroke="#BE123C" stroke-width="2" stroke-linecap="round"
                                                                stroke-linejoin="round"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_43_4">
                                                                <rect width="16" height="16" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg> : (
                                                        ad.new ? <svg className="w-4 h-4" viewBox="0 0 16 16" fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="8" cy="8" r="8" fill="#D1FAE5"/>
                                                            <path d="M8 11V8M8 5V8M8 8H5M8 8H11" stroke="#08976B"
                                                                  stroke-width="2" stroke-linecap="round"
                                                                  stroke-linejoin="round"/>
                                                        </svg> : <svg className="w-4 h-4" viewBox="0 0 16 16" fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="8" cy="8" r="8" fill="#FFFBEB"/>
                                                        <path d="M5 8.4L7.30769 10L11 6" stroke="#B45309" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                )
                                            }

                                        </div>
                                        <div className={"text-sm text-slate-500" + (ad.cross ? " line-through" : "")} style={{marginTop: -2.5}}>{t(ad.text, {count: el.channel_count * 3})}</div>
                                    </div>;
                                })}
                            </div>
                        </Card>
                    </div>;
                })}
                {/*<a href="https://t.me/wisewatcher_support" target="_blank" className="h-full">*/}
                {/*    <Card*/}
                {/*        className={"text-left cursor-pointer h-full"}*/}
                {/*    >*/}
                {/*        <div className="text-sm text-slate-500 mb-4">{t("settings_page.subscription_individual")}</div>*/}
                {/*        <div className="text-2xl font-bold">{t("settings_page.subscription_individual_count")}</div>*/}
                {/*    </Card>*/}
                {/*</a>*/}
            </div>

            <>
                {(buttonActive && false) && <>
                    <div className="w-full h-px bg-gray-200 mt-12 mb-8"></div>
                    <div className="text-lg font-bold text-center mb-8">{t("settings_page.payment_choice")}</div>
                    <div className="grid grid-cols-2 gap-8 max-w-3xl mx-auto">
                        <div onClick={() => {setPaymentMethod("robokassa")}}>
                            <Card
                                className={"text-left cursor-pointer" + (paymentMethod === "robokassa" ? " card_green" : "")}
                            >
                                <div className="text-sm text-slate-500 mb-2">{t("subscription_page.ruble_payment")}</div>
                                <div className="text-lg font-bold flex items-center">
                                    {t("subscription_page.russian_card")}
                                </div>
                            </Card>
                        </div>
                        <div onClick={() => { setPaymentMethod("stripe"); }}>
                            <Card
                                className={"text-left cursor-pointer " + (paymentMethod === "stripe" ? " card_green" : "")}
                            >
                                <div className="text-sm text-slate-500 mb-2">{t("subscription_page.eur_payment")}</div>
                                <div className="text-lg font-bold flex items-center">
                                    {t("subscription_page.foreign_card")}
                                </div>
                            </Card>
                        </div>
                    </div>
                </>}
            </>

            {/*<Callout*/}
            {/*    title={t("subscription_page.foreign_payment")}*/}
            {/*    color="yellow"*/}
            {/*    icon={InformationCircleIcon}*/}
            {/*    className="text-left mt-8"*/}
            {/*>*/}
            {/*    <div className="text-xs">*/}
            {/*        {t("subscription_page.foreign_payment_text")}*/}
            {/*    </div>*/}
            {/*</Callout>*/}

            <>
                <div className="flex justify-center">
                    {(buttonActive && !!partner && !!partner.balance) && <div className="mt-8 text-slate-700 text-sm shadow-sm border px-8 py-4 rounded-md flex items-center cursor-pointer" onClick={() => {setUseBonuses(!useBonuses);}}>
                        <Switch color="emerald" checked={useBonuses} className="mr-4 flex items-center"/>
                        <div>
                            <div className="text-left">{t("settings_page.referral.spend_bonuses")}</div>
                            <span className="text-sm text-slate-500">{t("settings_page.referral.current_balance", {balance: partner.balance})}</span>
                        </div>
                    </div>}
                </div>
            </>

            <Button disabled={!buttonActive} onClick={payForSubscription} className="mt-8">{t("settings_page.pay")}</Button>
        </Modal>
    );
}

export default SubscribeModal;