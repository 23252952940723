import React, {useEffect, useMemo, useState} from 'react';
import Card from "../../../ui/Card";
import {useTranslation} from "react-i18next";
import {SubscriptionDistributionResponse} from "../../../models/SubscriptionDistributionResponse";
import {getSubscriptionDistribution} from "../http";
import * as Sentry from "@sentry/react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    Legend,
    Rectangle,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {themeColorRange} from "../../../constants";
import {Color} from "@tremor/react";
import {formatNumber} from "../../../utils/numbers";

function SubscriptionDistribution() {
    const {t} = useTranslation();

    const [subscriptionDistribution, setSubscriptionDistribution] = useState<SubscriptionDistributionResponse[] | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        const result = await getSubscriptionDistribution();
        setSubscriptionDistribution(result.data);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        fetchData().catch((e) => {
            setLoading(false);
            Sentry.captureException(e);
        })
    }, [])

    const categoryColors = constructCategoryColors(["count"], themeColorRange);

    return (
        <Card className={"loadable" + (loading ? " loadable_active" : "")}>
            <div className="text-lg font-bold text-center mb-8">{t("stats_page.heading")}</div>
            <div className="h-96 w-full">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={subscriptionDistribution ?? []}
                        margin={{bottom: 20}}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="title"
                            tick={{ transform: "translate(0, 6)" }}
                            style={{
                                fontSize: "14px",
                                fontFamily: "Inter; Helvetica",
                            }}
                            tickLine={false}
                            axisLine={false}
                            padding={{ left: 10, right: 10 }}
                            minTickGap={5}
                        />
                        <YAxis
                            width={62}
                            tick={{ transform: "translate(-3, 0)" }}
                            axisLine={false}
                            tickLine={false}
                            type="number"
                            style={{
                                fontSize: "12px",
                                fontFamily: "Inter; Helvetica",
                            }}
                        />
                        <Tooltip
                            wrapperStyle={{outline: "none"}}
                            content={({ active, payload, label }) => (
                                <ChartTooltip
                                    active={active}
                                    payload={payload}
                                    label={label}
                                    categoryColors={categoryColors}
                                />
                            )}
                        />
                        <Bar dataKey="count" fill="#0ea5e9"/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );
}
const constructCategoryColors = (
    categories: string[],
    colors: Color[],
): Map<string, Color> => {
    const categoryColors = new Map<string, Color>();
    categories.forEach((category, idx) => {
        categoryColors.set(category, colors[idx]);
    });
    return categoryColors;
};

type ChartTooltipProps = {
    active: boolean | undefined;
    payload: any;
    label: string;
    categoryColors: Map<string, any>;
}
function ChartTooltip({payload, label, categoryColors}: ChartTooltipProps) {
    const {t} = useTranslation();
    return (
        <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top border-none"
             style={{outline: "none"}}>
            <div className="bg-white text-sm rounded-md shadow-lg border border-1 border-slate-100">
                <div className="border-gray-200 px-4 py-2 border-b">
                    <p className="text-gray-700 font-medium">{label}</p>
                </div>
                <div className="px-4 py-2 space-y-1">
                    {payload?.map(({ value, name }: { value: number; name: string; }, idx: number) => (
                        <div className="flex items-center justify-between space-x-8">
                            <div className="flex items-center space-x-2">
                                <span className={"shrink-0 border-white h-3 w-3 rounded-full border-2 shadow bg-" + categoryColors.get(name) + "-500"}></span>
                                <p className="font-medium tabular-nums text-right whitespace-nowrap text-gray-700">
                                    {t("stats_page.users", {count: value})}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SubscriptionDistribution;