import React, {SyntheticEvent, useState} from 'react';
import {Button, TextInput} from "@tremor/react";
import {EnvelopeIcon, KeyIcon} from "@heroicons/react/24/solid";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {emailValid, passwordValid} from "../../../utils/regexp";
import {useTranslation} from "react-i18next";
import classes from './AuthForm.module.scss'
import AuthService from "../service";
import {LockClosedIcon} from "@heroicons/react/20/solid";
import {useLanguageStore} from "../../../store/language";

export interface AuthFormProps {
    isLogin: boolean;
}

function AuthForm({ isLogin }: AuthFormProps) {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const {language} = useLanguageStore();

    const [searchParams, setSearchParams] = useSearchParams();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [privacyPolicy, setPrivacyPolicy] = useState(true);
    const [userAgreement, setUserAgreement] = useState(true);

    const [requiresTwoFactor, setRequiresTwoFactor] = useState(false);

    async function submitHandle(event: SyntheticEvent) {
        event.preventDefault();

        setEmail(email.trim());

        let emailMatches = emailValid(email);
        let passwordMatches = passwordValid(password);

        if (emailMatches && passwordMatches) {
            setButtonLoading(true);
        }

        if (!emailMatches) {
            setEmailError(t("auth.errors.incorrect_email").toString());
            return;
        } else {
            setEmailError('');
        }

        if (!passwordMatches) {
            setPasswordError(t("auth.errors.incorrect_password").toString())
            return;
        } else {
            setPasswordError('');
        }

        if (isLogin) {
            let result = await AuthService.login(email, password, twoFactorCode);

            console.log("result: " + result)

            if (result === 200) {
                navigate("/")
            } else if(result === 401) {
                setEmailError(t("auth.errors.invalid_email_or_password").toString());
            } else if(result === 422) {
                setEmailError(t("auth.errors.incorrect_email").toString());
            } else if(result === 204) {
                setRequiresTwoFactor(true);
            } else {
                setEmailError(t("errors.something_went_wrong").toString());
            }
        } else {
            localStorage.setItem("new", "1");
            let result = await AuthService.register(email, password, language || "ru", searchParams.get("partner_id") ?? "");

            if (result === 200) {
                navigate("/");
            } else if (result === 422) {
                setEmailError(t("auth.errors.email_occupied").toString());
            } else {
                setEmailError(t("errors.something_went_wrong").toString());
            }
        }

        setButtonLoading(false);
    }

    return (
        <>
            <h2 className="text-2xl font-bold leading-7 text-center text-gray-900 mb-8">{isLogin ? t("auth.login") : t("auth.register")}</h2>
            {/*<Button size="lg" icon={AiOutlineGoogle} variant="secondary" className="w-full" color="blue">*/}
            {/*    {isLogin ? t("auth.login_with_google") : t("auth.register_with_google")}*/}
            {/*</Button>*/}
            {/*<div className={classes["auth-hr"]}><span>{isLogin ? t("auth.or_login_by_email") : t("auth.or_register_by_email")}</span></div>*/}
            <form onSubmit={submitHandle} className="auth-form">
                <TextInput onChange={(event) => {
                    if (isLogin) {
                        setEmail(event.target.value);
                    } else {
                        setEmail(event.target.value.toLowerCase().trim());
                    }
                }} icon={EnvelopeIcon} value={email} placeholder={t("auth.email").toString()}
                           error={emailError != ''} errorMessage={emailError} className="mb-2"/>
                <TextInput onChange={(event) => setPassword(event.target.value)} icon={KeyIcon} placeholder={t("auth.password").toString()}
                           className="mt-2 mb-2" type="password" error={passwordError != ''}
                           errorMessage={passwordError}/>

                <>
                    {requiresTwoFactor && <TextInput onChange={(event) => setTwoFactorCode(event.target.value)} icon={LockClosedIcon} placeholder={t("auth.code").toString()}
                                                     className="mt-2 mb-2"/>}
                </>

                { isLogin &&
                    <div className={classes["auth-forgot"]}>
                        <Link to="/auth/forgot" className="link">{t("auth.forgot_password")}</Link>
                    </div>
                }

                {!isLogin &&
                    <>
                        <div className="flex items-center mt-4 cursor-pointer">
                            <input checked={privacyPolicy} onChange={() => {setPrivacyPolicy(!privacyPolicy)}} id="privacy" type="checkbox" className={classes["checkbox"] + " text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"}/>
                            <label htmlFor="privacy" className={classes["checkbox__label"] + " text-sm"}>
                                {t("auth.i_agree_with")} <Link to="/privacy-policy" className="text-blue-500" target="_blank">{t("auth.privacy_policy")}</Link>
                            </label>
                        </div>
                        <div className="flex items-center mt-2 cursor-pointer">
                            <input checked={userAgreement} onChange={() => {setUserAgreement(!userAgreement)}} id="agreement" type="checkbox" className={classes["checkbox"] + " text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"}/>
                            <label htmlFor="agreement" className={classes["checkbox__label"] + " text-sm"}>
                                {t("auth.i_agree_with")} <Link to="/user-agreement" className="text-blue-500" target="_blank">{t("auth.user_agreement")}</Link>
                            </label>
                        </div>
                    </>
                }

                <Button disabled={!isLogin && (!privacyPolicy || !userAgreement)} size="lg" variant="primary" className="w-full mt-4 mb-4" color="blue" loading={buttonLoading}>
                    {isLogin ? t("auth.login") : t("auth.register")}
                </Button>
            </form>
            <div className={classes["auth-option"]}>
                {isLogin ? t("auth.do_not_have_an_account") : t("auth.have_an_account")}&nbsp;
                <Link to={isLogin ? "/auth/register" : "/auth/login"} className="link">{isLogin ? t("auth.register") : t("auth.login")}.</Link>
            </div>
        </>
    );
}

export default AuthForm;