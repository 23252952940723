import React from 'react';
import Breadcrumbs from "../components/Breadcrumbs";
import {useTranslation} from "react-i18next";
import {FavouriteVideosList} from "../modules/favourites";

function FavouritesPage() {
    const {t} = useTranslation();
    return (
        <div className="pt-8">
            <Breadcrumbs pages={[
                {
                    title: t("breadcrumbs.favourites"),
                    link: ""
                }
            ]} />
            <FavouriteVideosList />
        </div>
    );
}

export default FavouritesPage;