import React, {useMemo, useState} from 'react';
import {Channel} from "../models/Channel";
import {useTranslation} from "react-i18next";
import {
    Icon, Tab,
    TabGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    TabList
} from "@tremor/react";
import {ArchiveBoxXMarkIcon, BarsArrowDownIcon, BarsArrowUpIcon, NoSymbolIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {formatNumber} from "../utils/numbers";
import {secondsToTimeString} from "../utils/date";
import {useShortsStore} from "../store/shorts";
import {User} from "../models/User";
import {Plan} from "../models/Plan";

type SortKey = "avg_views" | "sum_views" | "median_vs" | "median_erv" | "median_score" | "current_videos" | "subscriptions" | "median_length" | "avg_views_shorts" | "sum_views_shorts" | "median_vs_shorts" | "median_erv_shorts" | "median_score_shorts" | "current_videos_shorts" | "median_length_shorts"

type ChannelsTableProps = {
    channels: Channel[]
    highlightId?: string
    initialSortingKey: SortKey | null
    shorts: boolean
}

function ChannelsTable({channels, initialSortingKey, highlightId}: ChannelsTableProps) {
    const {shorts, setShorts} = useShortsStore();

    const tableColumns = useMemo(() => {
        if (shorts) {
            return [
                "sum_views_shorts",
                "median_vs_shorts",
                "median_erv_shorts",
                "current_videos_shorts",
                "avg_views_shorts",
            ];
        }

        return [
            "sum_views",
            "median_vs",
            "median_erv",
            "current_videos",
            "avg_views",
        ];
    }, [shorts]);

    const {t} = useTranslation();

    const [sortKey, setSortKey] = useState(initialSortingKey);
    const [sortDesc, setSortDesc] = useState(true);

    const sortedChannels = useMemo(() => {
        if (!sortKey) return channels;
        if (!channels) return [];

        const result = [...channels].sort((a, b) => {
            return (a[sortKey!] as number) - (b[sortKey!] as number);
        });

        if (sortDesc) return result.reverse();

        return result;
    }, [channels, sortKey, sortDesc])

    function handleSortChange(key: SortKey) {
        if (sortKey === key) {
            setSortDesc(!sortDesc);
            return;
        }

        setSortDesc(true);
        setSortKey(key);

        return;
    }

    function handleIndexChange(value: number) {
        if (value == 0) {
            setShorts(false);
        } else {
            setShorts(true);
        }
    }


    const canToggle = useMemo(() => {
        const storageUser = JSON.parse(localStorage.getItem("user")!) as User;
        const storagePlan = JSON.parse(localStorage.getItem("plan")!) as Plan;

        if (storageUser && storageUser.role > 0) return true;
        if (storagePlan) {
            return storagePlan.channel_count == 50 || storagePlan.channel_count == 100;
        }

        return false;
    }, []);

    return (
        <div className="relative">
            <div className="absolute flex items-center p-4 py-2 z-50">
                <TabGroup
                    color="zinc"
                    index={shorts ? 1 : 0}
                    defaultIndex={shorts ? 1 : 0}
                    onIndexChange={handleIndexChange}
                    className="relative"
                >
                    <TabList variant="solid">
                        <Tab>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.94 122.88" style={{width: 16, height: 16, marginRight: 4}}>
                                    <svg viewBox="0 0 28.57 20" preserveAspectRatio="xMidYMid meet">
                                        <g>
                                            <path d="M27.9727 3.12324C27.6435 1.89323 26.6768 0.926623 25.4468 0.597366C23.2197 2.24288e-07 14.285 0 14.285 0C14.285 0 5.35042 2.24288e-07 3.12323 0.597366C1.89323 0.926623 0.926623 1.89323 0.597366 3.12324C2.24288e-07 5.35042 0 10 0 10C0 10 2.24288e-07 14.6496 0.597366 16.8768C0.926623 18.1068 1.89323 19.0734 3.12323 19.4026C5.35042 20 14.285 20 14.285 20C14.285 20 23.2197 20 25.4468 19.4026C26.6768 19.0734 27.6435 18.1068 27.9727 16.8768C28.5701 14.6496 28.5701 10 28.5701 10C28.5701 10 28.5677 5.35042 27.9727 3.12324Z" fill="#FF0000"/>
                                            <path d="M11.4253 14.2854L18.8477 10.0004L11.4253 5.71533V14.2854Z" fill="white"/>
                                        </g>
                                    </svg>
                                </svg>
                                {t('topic_page.long_videos')}
                            </div>
                        </Tab>
                        <Tab disabled={!canToggle}>
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.94 122.88" style={{width: 12, height: 12, marginRight: 4}}>
                                    <path d="M63.49 2.71c11.59-6.04 25.94-1.64 32.04 9.83 6.1 11.47 1.65 25.66-9.94 31.7l-9.53 5.01c8.21.3 16.04 4.81 20.14 12.52 6.1 11.47 1.66 25.66-9.94 31.7l-50.82 26.7c-11.59 6.04-25.94 1.64-32.04-9.83-6.1-11.47-1.65-25.66 9.94-31.7l9.53-5.01c-8.21-.3-16.04-4.81-20.14-12.52-6.1-11.47-1.65-25.66 9.94-31.7l50.82-26.7zM36.06 42.53l30.76 18.99-30.76 18.9V42.53z" fill="#f40407"/>
                                    <path d="M36.06,42.53 V 80.42 L 66.82,61.52Z" fill="#fff"/>
                                </svg>
                                {t('topic_page.shorts')}
                            </div>
                        </Tab>
                    </TabList>
                </TabGroup>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                        {tableColumns.map((el, i) => {
                            return <TableHeaderCell
                                className="cursor-pointer text-slate-700"
                                onClick={handleSortChange.bind(null, el as SortKey)}
                                key={i}>
                                <div className="flex items-bottom">
                                    {
                                        t("channel_page.rating." + el.replace("_shorts", "")) === ("channel_page.rating." + el.replace("_shorts", ""))
                                            ? ""
                                            : el.replace("_shorts", "") == "avg_views" ? t("channel_page.rating." + el.replace("_shorts", "") + "_short") : t("channel_page.rating." + el.replace("_shorts", ""))
                                    }
                                    <Icon
                                        style={{
                                            opacity: sortKey === el ? 1 : 0,
                                            visibility: sortKey === el ? "visible" : "hidden",
                                        }}
                                        size="xs"
                                        color="slate"
                                        icon={ sortDesc ? BarsArrowDownIcon : BarsArrowUpIcon }
                                    />
                                </div>
                            </TableHeaderCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedChannels.map((item) => (
                        <TableRow key={item.id} className={initialSortingKey && item.id === highlightId ? "bg-lime-200" : ""}>
                            <TableCell>
                                <Link target="_blank" to={"/channel/" + item.id}>
                                    {(item.deleted || item.blocked)
                                        ? <div className="w-24 h-24 rounded-full bg-red-100 flex justify-center items-center">
                                            <Icon icon={item.blocked ? NoSymbolIcon : ArchiveBoxXMarkIcon} color="red" className="text-red-700" size="sm" />
                                        </div>
                                        : <img src={item.avatar} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src="/person.svg";
                                        }} alt="avatar" className="rounded-full w-24"/>
                                    }
                                </Link>
                            </TableCell>
                            <TableCell className="text-slate-700 text-sm">
                                <Link target="_blank" to={"/channel/" + item.id}>
                                    <div className="whitespace-break-spaces text-blue-500">{item.title}</div>
                                </Link>
                            </TableCell>
                            <>
                                {shorts ? <>
                                        <TableCell className="text-slate-700">{formatNumber(item.sum_views_shorts)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_vs_shorts, true)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_erv_shorts, true)}</TableCell>
                                        {/*<TableCell className="text-slate-700">{formatNumber(Math.ceil(item.median_score_shorts))}</TableCell>*/}
                                        <TableCell className="text-slate-700">{formatNumber(item.current_videos_shorts)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.avg_views_shorts)}</TableCell>
                                    </>
                                    : <>
                                        <TableCell className="text-slate-700">{formatNumber(item.sum_views)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_vs, true)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.median_erv, true)}</TableCell>
                                        {/*<TableCell className="text-slate-700">{formatNumber(Math.ceil(item.median_score))}</TableCell>*/}
                                        <TableCell className="text-slate-700">{formatNumber(item.current_videos)}</TableCell>
                                        <TableCell className="text-slate-700">{formatNumber(item.avg_views)}</TableCell>
                                    </>
                                }
                            </>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default ChannelsTable;