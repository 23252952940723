import React, {SyntheticEvent, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Icon, TextInput} from "@tremor/react";
import {EnvelopeIcon} from "@heroicons/react/24/solid";
import classes from "./AuthForm.module.scss";
import {Link} from "react-router-dom";
import {requestReset} from "../http";
import {emailValid} from "../../../utils/regexp";
import Status from "../../../ui/Status";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";

function ForgotForm() {
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();

    const status = useMemo(() => {
        if (!responseCode) {
            return <></>;
        } else if (responseCode === 200) {
            return <>
                <Status
                    icon={<Icon icon={CheckCircleIcon} color="green" size="xl" />}
                    headline={t("auth.done")}
                    text={t("auth.forgot_success")}
                />
                <div className="mt-2 text-center text-sm">
                    {t("auth.back_to_login")}&nbsp;
                    <Link to="/auth/login" className="link">{t("auth.login")}.</Link>
                </div>
            </>;
        } else if (responseCode === 404) {
            return <>
                <Status
                    icon={<Icon icon={XCircleIcon} color="red" size="xl" />}
                    headline={t("errors.error")}
                    text={t("auth.errors.user_not_found")}
                />
                <div className="mt-2 text-center text-sm">
                    <Link to="/auth/login" className="link">{t("auth.try_again")}.</Link>
                </div>
            </>;
        } else {
            return <>
                <Status
                    icon={<Icon icon={XCircleIcon} color="red" size="xl" />}
                    headline={t("errors.error")}
                    text={t("errors.something_went_wrong")}
                />
                <div className="mt-2 text-center text-sm">
                    <Link to="/auth/login" className="link">{t("auth.try_again")}.</Link>
                </div>
            </>;
        }
    }, [responseCode])

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();
        if (!emailValid(email)) {
            setEmailError(t("auth.errors.incorrect_email").toString());
            return;
        }

        setLoading(true);

        const reset = async () => {
            const res = await requestReset(email);
            setLoading(false);
            setResponseCode(res.status);
        };

        reset().catch((e) => {
            setLoading(false);
            setResponseCode(e.response.status);
        });
    }

    return (
        <div>
            {!responseCode && <>
                <h2 className="text-2xl font-bold leading-7 text-center text-gray-900 mb-4">{t("auth.forgot_password")}</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    <TextInput onChange={(event) => setEmail(event.target.value)} icon={EnvelopeIcon} placeholder={t("auth.email").toString()}
                               error={emailError != ''} errorMessage={emailError}/>

                    <Button size="lg" variant="primary" className="w-full mt-4 mb-4" color="blue" loading={loading}>
                        {t("auth.done")}
                    </Button>
                </form>
                <div className={classes["auth-option"]}>
                    {t("auth.back_to_login")}&nbsp;
                    <Link to="/auth/login" className="link">{t("auth.login")}.</Link>
                </div>
            </>}

            {status}
        </div>
    );
}

export default ForgotForm;